textarea.form-control {
    min-height: 7.5rem;
}

.form-control, .form-select {
    padding: 12px;
}

.form-control, .form-select {
    &::placeholder {
        color: gray;
        font-size: 15px;
    }

    &:focus {
            box-shadow: 0 0 0 0.1rem $primary-purple;
    }
}

.form-select option[value=''] {
    color: $gray-1;
    display: none;
}

// Basic honyepot styling, needs better implementation in Dummy
label[for='dynamic_form1_name'],
input#dynamic_form1_name {
    display: none;
}

.col-12:has(input#dynamic_form1_name) {
    margin: 0;
}

.col-12:has(input[type="email"].form-control:not([id*="email"])) {
    display: none;
    margin: 0;
}


label[for=data-privacy] {
    @include generate-body-styles('medium', 'regular');
    color: $primary-gray;
}

.data-privacy {
    padding-left: 1.5rem;
    position: relative;

    input[type=checkbox] {
        position: absolute;
        top: 0.5rem;
        left: 0;
    }
}

.submit {
    margin-top: 1rem;
}

