* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    font-feature-settings: 'clig' off, 'liga' off;

    main {
        flex-grow: 1;
    }
}

p:last-child,
p:only-child {
    margin-bottom: 0;
}

a {
    text-decoration: none;
}

.sr-only {
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    clip: rect(0, 0, 0, 0);
    border-width: 0;
    white-space: nowrap;
}
