// ||--------------------------------------------------------------------------------||
// ||                          Bootstrap Variable Overrides                          ||
// ||--------------------------------------------------------------------------------||

// Base Colors
$gray-100                                 : $gray-1;
$gray-200                                 : $gray-2;
$gray-300                                 : $gray-3;
$gray-400                                 : $gray-4;
$gray-500                                 : $gray-5;
$gray-600                                 : $gray-6;

$primary                                  : $primary-black;
$secondary                                : $primary-purple;
$white                                    : $primary-white;
$warning                                  : $state-warning;
$danger                                   : $state-error;

$body-color                               : $primary;
$body-bg                                  : $primary;

$input-focus-color                        : $state-focus;

$theme-colors                             : (
  "primary"                               : $primary,
  "secondary"                             : $secondary,
  "purple"                                : $primary-purple,
  "dark-purple"                           : $primary-dark-purple,
  "red"                                   : $primary-red,
  "gray"                                  : $primary-gray,
  "pink"                                  : $primary-pink,
  "rose"                                  : $primary-rose,
  "blue"                                  : $primary-blue,
  "green"                                 : $primary-green,
  "yellow"                                : $primary-yellow,
  "white"                                 : $primary-white
);

// Image Position Values
$imagePositions                           : (
    left                                  : left,
    leftTop                               : left top,
    leftBottom                            : left bottom,
    right                                 : right,
    rightTop                              : right top,
    rightBottom                           : right bottom,
    top                                   : top,
    middle                                : center,
    bottom                                : bottom,
);

$grid-breakpoints: (
  xs: 0,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl,
);

// Container Max Widths
$container-max-widths                     : (
    sm                                    : 540px,
    md                                    : 720px,
    lg                                    : 960px,
    xl                                    : 1152px,
);

// Spacers
$spacer                                   : 1rem;
$spacers                                  : (
  0                                       : 0,
  1                                       : $spacer * .25,
  2                                       : $spacer * .5,
  3                                       : $spacer,
  4                                       : $spacer * 1.5,
  5                                       : $spacer * 3,
  6                                       : $spacer * 6,
  7                                       : $spacer * 12,
);

$enable-negative-margins                  : true;

// CSS Grid
$enable-cssgrid                           : true;

// Body
$body-color                               : $primary-dark-purple;

// Typographie
$rfs-breakpoint                           : 1400px;
$rfs-factor                               : 16;

// Buttons
$btn-color                                : $white;
$btn-hover-color                          : $white;
$input-focus-border-color                 : transparent;
$input-btn-padding-x                      : $spacer * 1.5;
$input-btn-padding-x                      : $spacer * 1.5;

// Links
$link-color                               : $body-color;
$nav-link-hover-color                     : $secondary;
$nav-link-font-size                       : 15px;
$nav-link-padding-y                       : 1.25rem;
$nav-link-font-weight                     : 500;
$nav-link-color                           : $secondary;

// Navbar
$navbar-padding-y                         : 0;
$navbar-padding-x                         : 0;

$navbar-nav-link-padding-x                : 0;

$navbar-toggler-padding-y                 : 0;
$navbar-toggler-padding-x                 : 0;
$navbar-toggler-focus-width               : 0;
$navbar-light-color                       : $body-color;
$navbar-light-hover-color                 : $primary;
$navbar-light-active-color                : $primary;
$navbar-brand-margin-end                  : $spacer * 4;
$navbar-brand-padding-y                   : 0;

// Dropdowns
$dropdown-border-width                    : 0;

$dropdown-padding-y                       : 0;
$dropdown-font-size                       : 15px;
$dropdown-color                           : #4C4D6B;
$dropdown-bg                              : $primary-white;


$dropdown-item-padding-y                  : 12px;
$dropdown-item-padding-x                  : 16px;


// Border Radius
$border-radius                            : 0;
$border-radius-sm                         : 1.25rem;
$border-radius-lg                         : 2.5rem;
$border-radius-xl                         : 5rem;
$border-radius-xxl                        : 10rem;

// Font Family
$font-family-sans-serif                   : "Gilory", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Font Size
$h1-font-size                             : 2.75rem;
$h2-font-size                             : 2.25rem;
$h3-font-size                             : 2rem;
$h4-font-size                             : 1.75rem;
$h5-font-size                             : 1.5rem;
$h6-font-size                             : 1.25rem;

$rfs-base-value                           : 1rem;
$font-size-base                           : 1rem;
$line-height-base                         : 1.75;


// Font Weight
$headings-font-weight                     : 400;

// Input
$input-bg                                 : $white;
$input-border-color                       : $white;
$input-placeholder-color                  : $primary;
$kk_accordion-button-focus-border-color   : transaperent;
$accordion-button-focus-box-shadow        : transaperent;


// Accordion
$accordion-button-active-bg               : transparent;
$accordion-bg:                            transparent;
$accordion-button-active-color            : inherit;
$accordion-border-color                   : $gray-200;
$accordion-button-icon:         url($icon-chevron-down);
$accordion-button-active-icon:  url($icon-chevron-down);
$accordion-icon-color:                    $primary-pink;
$accordion-icon-active-color:              $primary-pink;











$accordion-color:                         inherit;
$accordion-bg:                            transparent;
// $accordion-border-color:                  var(--#{$prefix}border-color) !default;
$accordion-button-color:                  inherit;
$accordion-button-bg:                     transparent;
$accordion-button-active-bg:              transparent;
$accordion-button-active-color:           inherit;

// $accordion-button-focus-border-color:     $input-focus-border-color !default;
// $accordion-button-focus-box-shadow:       $btn-focus-box-shadow !default;

// $accordion-icon-color:                    $body-color !default;
// $accordion-icon-active-color:             $primary-text-emphasis !default;






// Transitions
$transition-length                        : 0.5s;
$transition-length-md                     : 0.75s;
$transition-length-lg                     : 1s;
$transition-length-xl                     : 2s;
