.cta-project {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include rfs(4rem, gap);

    &__cta {
        grid-column: 1;
        display: flex;
        flex-direction: column;
        @include rfs(1.5rem, gap);
        align-self: center;

        @include headline(lg);
        .headline-content {
            margin-bottom: 0;
        }

        &__buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }
    }

    &__item {
        grid-column: 2;
        box-shadow: 0px 48px 8px -48px rgba(0, 0, 0, 0.06), 0px 48px 80px 0px rgba(17, 12, 46, 0.12);
        background-color: $primary-white;

        .image img {
            width: 100%;
            height: 100%;
            object-fit: cover
        }

        &__categories-container {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            transform: translateY(calc(-12px - #{map-get($map: $spacers, $key: 3)}));
        }


        &__category {
            padding: 2px 6px;
            @include generate-body-styles('small', 'bold');
            color: $gray-5;
            background-color: $primary-white;

            @each $value, $color in $theme-colors {
                &--#{$value} {
                    border-left: solid 3px $color;
                }
            }
        }

        &__content {
            padding: map-get($map: $spacers, $key: 3);

            .headline {
                &__title {
                    @include generate-body-styles('medium', 'regular');
                }

                &__content {
                    @include generate-display-styles('medium', 'extrabold');
                }

                grid-template: 1 / -1;
            }
        }
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;       

        &__item {
            grid-column: 1;
            grid-row: 2;
        }
    }
}