.cta-text-with-link {
    @include rfs(#{$section-spacing-md}, padding-block);
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        mask-image: url($icon-schnuppe);
        mask-size: 55px 64px;
        mask-repeat: repeat;
        background-color: $primary-pink;
        background-size: 60px 40px;
        opacity: 0.1;
    }

    &__card {
        background-color: $white;
        @include rfs(6rem, padding);
        position: relative;
        z-index: 2;

        .headline {
            &__title {
                @include generate-display-styles('xxsmall', 'medium');
                text-transform: uppercase;
            }

            &__content {
                @include generate-display-styles('large', 'extrabold');
            }
        }

        &__links {
            display: flex;
            flex-wrap: wrap;
            gap: 1.5rem;    
            padding-left: 0;
            margin-bottom: 0;
            margin-top: map-get($map: $spacers, $key: 4);

            li {

                // TODO: create mixin/util
                @each $value, $color in $theme-colors {
                    &.#{$value} a::before {
                        background-color: $color;
                    }
                }

                a {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.2);    
                    padding-left: map-get($map: $spacers, $key: 4);
                    padding-right: map-get($map: $spacers, $key: 3);
                    padding-top: map-get($map: $spacers, $key: 2);
                    padding-bottom: map-get($map: $spacers, $key: 2);
                    position: relative;

                    @include link();

                    &:hover {
                        gap: $link-arrow-gap;
                    }

                    &:before {
                        content: '';
                        @include add-shape(
                            $width: 20px,
                            $height: 20px,
                            $image: $icon-brand-star,
                            $position: absolute,
                            $left: 0,
                            $x: -50%
                        );
                    }
                }
            }
        }

        .text-block {
            color: $body-color;

            @include generate-body-styles('large', 'regular');
        }

        .headline {
            &__title {
                color: $primary-purple;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__card {
            padding-inline: 24px; 
            padding-block: 40px;
        }
    }
}