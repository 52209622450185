.lp-project {
    display: grid;
    grid-template-columns: minmax(360px, 622px) 1fr;
    @include rfs(64px, gap);

    &__teaser {
        display: flex;
        flex-direction: column;
        @include rfs(24px, gap);
    }

    &__image {
        img {
            max-width: 100%;
        }
    }

    &__headline {
        .headline__content {
            margin-bottom: 0;
            @include generate-display-styles('large', 'heavy');
        }
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        column-gap: 42px;
        row-gap: 8px;
        @include rfs(36px, padding-top);
        @include rfs(12px, margin-top);

        @include icon-divider(
            $variant: top,
            $color: $primary-rose,
            $icon: $icon-brand-star
        );

        &__item {
            background-color: $primary-dark-purple;
            display: flex;
            flex-direction: column;
            padding: 8px 12px;
            min-width: 138px;
            position: relative;

            &::after {
                @include add-icon(
                    $display: block,
                    $position: absolute,
                    $right: 0,
                    $top: 0,
                    $x: 50%,
                    $height: 100%,
                    $widht: auto,
                    $color: $primary-dark-purple
                ) {
                    aspect-ratio: 1/1
                };
            }

            &__title {
                text-transform: uppercase;
                color: $primary-rose;
            }

            &__name {
                color: $primary-white;
                @include generate-display-styles('xsmall', 'extrabold');
            }
        }

    }

    &__content {
        strong {
            @include generate-display-styles('medium', 'extrabold')
        }
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: minmax(343px, 360px) 1fr;

    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;       
    }
}