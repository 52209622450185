.magazin {
    &__info {
        &__date {
            color: $gray-4;
            @include generate-label-styles('medium', 'bold');
        }
    }

    &__headline {
        font-family: "Gilory";
        @include font-size(42px);
        font-style: normal;
        font-weight: 800;
        line-height: 52px;
        @include rfs(24px, margin-bottom);
    }

    &__teaser-text {
        @include generate-body-styles('large', 'regular');
        @include rfs(32px, margin-bottom);
    }

    &__teaser-image {
    }

    &__table-of-contents {

    }

    &__content {

    }

    &__contact {

    }

    &__related {

    }

    &__next {

    }
}