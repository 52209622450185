// TODO: add correct styling
.content-text-with-image {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include rfs(7.625rem, column-gap);

    // Variants
    &--right {
        .content-text-with-image__content {
            grid-column: 1;
            grid-row: 1;
        }

        .content-text-with-image__image {
            grid-column: 2;
            grid-row: 1;
        }   
    }

    &--left {
        .content-text-with-image__content {
            grid-column: 2;
            grid-row: 1;
        }

        .content-text-with-image__image {
            grid-column: 1;
            grid-row: 1;
        }
    }

    // Base Styles
    &__content {
        position: relative;
        align-self: center;

        .headline {
            position: relative;
            border-bottom: solid 1px $gray-2;
            margin-bottom: map-get($map: $spacers, $key: 3);
            padding-bottom: map-get($map: $spacers, $key: 3);

            &::after {
                @include add-icon(
                    $icon: $icon-brand-star,
                    $pseudo: true,
                    $position: absolute,
                    $bottom: 0,
                    $left: 50%,
                    $color: $gray-2,
                    $height: 16px,
                    $widht: 16px,
                    $x: -50%,
                    $y: 50%
                );
            }

            &__content {
             @include generate-display-styles('large', 'extrabold');
            }
        }

        .text-block {
            @include generate-body-styles('medium', 'regular');
        }

        .url {
            margin-top: map-get($map: $spacers, $key: 3);
        }
    }

     &__image {
        align-self: center;
        aspect-ratio: 1;
        -webkit-box-shadow: 4px 4px 15px 5px rgba(0,0,0,0.37); 
        box-shadow: 4px 4px 15px 5px rgba(0,0,0,0.37);
    }

    &__video {
        align-self: center;
        aspect-ratio: 1;
        -webkit-box-shadow: 4px 4px 15px 5px rgba(0,0,0,0.37); 
        box-shadow: 4px 4px 15px 5px rgba(0,0,0,0.37);

        .video,
        .video video {
            height: 100%
        }

        video {
            object-fit: cover
        }
    }


    @include media-breakpoint-down(md) {
        @include rfs(3.5rem, column-gap);
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        grid-template-rows: min-content;
        @include rfs(3.5rem, row-gap);

        .sticker {
            display: none;
        }


        &--right {
            .content-text-with-image__content {
                grid-column: 1;
                grid-row: 2;
            }

            .content-text-with-image__image {
                grid-column: 1;
                grid-row: 1;
            }   
        }

        &--left {
            .content-text-with-image__content {
                grid-column: 1;
                grid-row: 2;
            }

            .content-text-with-image__image {
                grid-column: 1;
                grid-row: 1;
            }
        }
    }
}