.page-teaser-banner {
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: 1;
    box-shadow: 0px -4px 8px -4px #00000014 inset;

    .image {
        grid-column: 1;
        grid-row: 1;

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .container {
        grid-column: 1;
        grid-row: 1;        
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .card {
            min-width: 378px;
            max-width: 440px;

            &::after {
                @include add-icon(
                    $icon: $icon-brand-star,
                    $pseudo: true,
                    $color: $primary-purple,
                    $height: 16px,
                    $widht: 16px,
                    $x: -50%,
                    $y: -50%,
                    $position: absolute,
                    $top: 0,
                    $left: 0
                );
            }
        }
    }

    @include media-breakpoint-down(md) {
        .container .card {
            min-width: auto;
        }
    }
}