.cta-projects {
    display: grid;
    grid-template-columns: 3fr 4fr;
    @include rfs(4rem, gap);

    &__cta {
        grid-column: 1;
        position: relative;
    }

    &__headline {
        position: sticky;
        top: 70px;
        display: flex;
        flex-direction: column;
        @include rfs(24px, gap);

        @include headline(lg);
        .headline-content {
            margin-bottom: 0;
        }
    }

    &__list {
        grid-column: 2;
        display: flex;
        flex-direction: column;
        @include rfs(24px, gap);
    }

    &__item {
        box-shadow: 0px 48px 8px -48px rgba(0, 0, 0, 0.06), 0px 48px 80px 0px rgba(17, 12, 46, 0.12);
        background-color: $primary-white;

        .image img {
            width: 100%;
            height: 100%;
            object-fit: cover
        }

        &__categories-container {
            display: flex;
            flex-wrap: wrap;
            @include rfs(4px, gap);
            transform: translateY(calc(-12px - #{map-get($map: $spacers, $key: 3)}));
        }


        &__category {
            @include rfs(2px, padding-block);
            @include rfs(6px, padding-inline);
            @include generate-body-styles('small', 'bold');
            color: $gray-5;
            background-color: $primary-white;

            @each $value, $color in $theme-colors {
                &--#{$value} {
                    border-left: solid 3px $color;
                }
            }
        }

        &__content {
            @include rfs(1rem, padding);

            .headline {
                &__title {
                    @include generate-body-styles('medium', 'regular');
                }

                &__content {
                    @include generate-display-styles('medium', 'extrabold');
                }

                grid-template: 1 / -1;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;

        &__cta {
            grid-column: 1;
            grid-row: 1;
        }

        &__list {
            grid-column: 1;
            grid-row: 2;
        }
    }
}