.cta-contact-small {
    background-color: $primary-red;
    position: relative;
    padding-block: map-get($map: $spacers, $key: 4);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $spacer;
    overflow: hidden;

    @include headline(md, $primary-white);

    .headline, .url {
        z-index: 2;
    }

    .headline .headline__content {
        margin-bottom: 0;
    }

    &::before {
        content: '';
        @include add-shape(
            $left: 0,
            $image: $brand-sound-1,
            $height: clamp(200px, 25vw, 700px),
            $color: $primary-purple,
            $rotate: 90deg
        );
    }

    &::after {
        content: '';
        @include add-shape(
            $right: 0,
            $image: $brand-sound-1,
            $height: clamp(200px, 25vw, 700px),
            $color: $primary-purple,
        ) {
            transform: rotate(90deg) scaleY(-1);
        };
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;

        &::before {
            display: none;
        }
    }
}