@mixin add-icon(
    $pseudo: true,
    $icon: $icon-brand-star,
    $color: $primary-red,
    $height: 16px,
    $widht: 16px,
    $x: 0px,
    $y: 0px,
    $position: relative,
    $top: auto,
    $bottom: auto,
    $left: auto,
    $right: auto,
    $display: inline-block
){
    @if $pseudo == true {
        content: '';
    }
    display: $display;
    height: $height;
    width: $widht;
    mask-image: url($icon);
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: $color;
    transform: translate($x, $y);
    position: $position;
    top: $top;
    bottom: $bottom;
    left: $left;
    right: $right;
    @content;
};