.hero--service-detail {
    padding-block: 48px;
    // @include headline(lg, $primary-white);

    .headline__title {
        @include generate-display-styles('xxsmall', 'medium');
    }

    .headline__content {
        @include generate-display-styles('large', 'extrabold');
        color: $primary-white;
    }

    @each $value, $color in $theme-colors {
        &.hero--#{$value}{
            .container::before {
                background-color: $color;
            }

            .headline .headline__accent,
            .headline .headline__title {
                color: $color;
            }
        }
    }
}