.content-seo {
    &__header {
        @include headline(md);
        text-align: center;
        grid-column: 1 / -1;
        @include rfs(1.5rem, margin-bottom);
    }

    &__body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @include rfs(6rem, row-gap);
        @include rfs(1.5rem, column-gap);
    }

    &__item {
        border-top: solid 1px $primary-gray;
        padding-top: map-get($map: $spacers, $key: 3);

        .headline .headline__content {
            @include generate-display-styles('xsmall', 'extrabold');
        }

        .text-block {
            @include generate-body-styles('medium', 'regular');
        }

        &:nth-of-type(3n) {
            text-align: center;
            grid-column: span 2;
            max-width: 80%;
            justify-self: center;
            padding-block: map-get($map: $spacers, $key: 4);
            @include icon-divider($gray-3, top_bottom, $brand-crown-up)
        }
    }

    @include media-breakpoint-down(sm) {
        &__body {
            grid-template-columns: 1fr;
        }

        &__item:nth-of-type(3n) {
            grid-column: 1;
        }
    }
}