//  ||--------------------------------------------------------------------------------------||
//  ||                                Custom Theme Variables                                ||
//  ||--------------------------------------------------------------------------------------||

// ** Container ** //

$container-max-width                          : 1800px;
$container-inner-max-width                    : 1232px;
$container-padding-x                          : 3rem;


$breakpoint-sm                                : 576px;
$breakpoint-md                                : 992px;
$breakpoint-lg                                : 1200px;
$breakpoint-xl                                : 1440px;


// ** Sections **//
$section-spacing-sm                            : 50px;
$section-spacing-md                            : 100px;
$section-spacing-lg                            : 150px;
$section-spacing-xl                            : 200px;


// ** Colors ** //

$primary-black                                : #10002F;
$primary-gray                                 : #7F8095;
$primary-white                                : #FFFFFF;
$primary-dark-purple                          : #23003E;
$primary-purple                               : #5E05A8;
$primary-red                                  : #FF006E;
$primary-pink                                 : #FC00C4;
$primary-rose                                 : #FFBFFF;
$primary-blue                                 : #00EDD9;
$primary-green                                : #57FF99;
$primary-yellow                               : #D9FF00;

$gray-1                                       : #F8F8F9;
$gray-2                                       : #E5E5EA;
$gray-3                                       : #B2B3BF;
$gray-4                                       : #7F8095;
$gray-5                                       : #4C4D6B;
$gray-6                                       : #10002F;

$secondary-black                              : #252525;
$secondary-gray                               : #575757;

$state-focus                                  : #5E05A8;
$state-warning                                : #57FF99;
$state-error                                  : #FF006E;

// ** Typography ** //

//-- Display

//styleName: Display/Hero/Desktop_Heavy-Italic;
$display-hero-heavy-italic-font-family        : 'Gilory';
$display-hero-heavy-italic-font-size          : 88px;
$display-hero-heavy-italic-font-weight        : 900;
$display-hero-heavy-italic-line-height        : 90px;
$display-hero-heavy-italic-letter-spacing     : 0px;


//styleName: Display/Huge/Desktop_Heavy-Italic;
$display-huge-heavy-italic-font-family        : 'Gilory';
$display-huge-heavy-italic-font-size          : 56px;
$display-huge-heavy-italic-font-weight        : 900;
$display-huge-heavy-italic-line-height        : 65px;
$display-huge-heavy-italic-letter-spacing     : 0px;

//styleName: Display/Large/Desktop_Heavy-Italic;
$display-large-heavy-italic-font-family       : 'Gilory';
$display-large-heavy-italic-font-size         : 46px;
$display-large-heavy-italic-font-weight       : 900;
$display-large-heavy-italic-line-height       : 54px;
$display-large-heavy-italic-letter-spacing    : 0px;

//styleName: Display/Large/Desktop_ExtraBold;
$display-large-extrabold-font-family          : 'Gilory';
$display-large-extrabold-font-size            : 36px;
$display-large-extrabold-font-weight          : 800;
$display-large-extrabold-line-height          : 40px;
$display-large-extrabold-letter-spacing       : 0px;

//styleName: Display/Large/Desktop_SemiBold;
$display-large-semibold-font-family           : 'Gilory';
$display-large-semibold-font-size             : 36px;
$display-large-semibold-font-weight           : 600;
$display-large-semibold-line-height           : 48px;
$display-large-semibold-letter-spacing        : 0px;

//styleName: Display/Medium/Desktop_ExtraBold;
$display-medium-extrabold-font-family         : 'Gilory';
$display-medium-extrabold-font-size           : 24px;
$display-medium-extrabold-font-weight         : 800;
$display-medium-extrabold-line-height         : 28px;
$display-medium-extrabold-letter-spacing      : 0px;

//styleName: Display/Medium/Desktop_Medium;
$display-medium-medium-font-family            : 'Gilory';
$display-medium-medium-font-size              : 24px;
$display-medium-medium-font-weight            : 500;
$display-medium-medium-line-height            : 34px;
$display-medium-medium-letter-spacing         : 0px;

//styleName: Display/Small/Desktop_ExtraBold;
$display-small-extrabold-font-family          : 'Gilory';
$display-small-extrabold-font-size            : 18px;
$display-small-extrabold-font-weight          : 700;
$display-small-extrabold-line-height          : 23px;
$display-small-extrabold-letter-spacing       : 0px;

//styleName: Display/Small/Desktop_SemiBold;
$display-small-smeibold-font-family           : 'Gilory';
$display-small-smeibold-font-size             : 18px;
$display-small-smeibold-font-weight           : 600;
$display-small-smeibold-line-height           : 24px;
$display-small-smeibold-letter-spacing        : 0px;

//styleName: Display/XSmall/Desktop_ExtraBold;
$display-xsmall-extrabold-font-family         : 'Gilory';
$display-xsmall-extrabold-font-size           : 14px;
$display-xsmall-extrabold-font-weight         : 800;
$display-xsmall-extrabold-line-height         : 19px;
$display-xsmall-extrabold-letter-spacing      : 0px;

//styleName: Display/XSmall/Desktop_Regular;
$display-xsmall-regular-font-family           : 'Gilory';
$display-xsmall-regular-font-size             : 14px;
$display-xsmall-regular-font-weight           : 400;
$display-xsmall-regular-line-height           : 20px;
$display-xsmall-regular-letter-spacing        : -0.09260998666286469px;

//styleName: Display/XXSmall/Desktop_ExtraBold;
$display-xxsmall-extrabold-font-family        : 'Gilory';
$display-xxsmall-extrabold-font-size          : 15px;
$display-xxsmall-extrabold-font-weight        : 800;
$display-xxsmall-extrabold-line-height        : 22px;
$display-xxsmall-extrabold-letter-spacing     : 2px;

//styleName: Display/XXSmall/Desktop_Medium;
$display-xxsmall-medium-font-family           : 'Gilory';
$display-xxsmall-medium-font-size             : 15px;
$display-xxsmall-medium-font-weight           : 500;
$display-xxsmall-medium-line-height           : 22px;
$display-xxsmall-medium-letter-spacing        : 2px;

//-- Accent

//styleName: Display/Hero/Desktop_Accent;
$accent-hero-font-family                      : 'BelyDisplay';
$accent-hero-font-size                        : 85px;
$accent-hero-font-weight                      : 400;
$accent-hero-line-height                      : 90px;
$accent-hero-letter-spacing                   : 0px;

//styleName: Display/Huge/Desktop_Accent;
$accent-huge-font-family                      : 'BelyDisplay';
$accent-huge-font-size                        : 54px;
$accent-huge-font-weight                      : 400;
$accent-huge-line-height                      : 65px;
$accent-huge-letter-spacing                   : 0em;

//styleName: Display/Large/Desktop_Accent;
$accent-large-font-family                     : 'BelyDisplay';
$accent-large-font-size                       : 44px;
$accent-large-font-weight                     : 400;
$accent-large-line-height                     : 54px;
$accent-large-letter-spacing                  : 0em;

//styleName: Display/Medium/Desktop_Accent;
$accent-medium-font-family                    : 'BelyDisplay';
$accent-medium-font-size                      : 23px;
$accent-medium-font-weight                    : 400;
$accent-medium-line-height                    : 33px;
$accent-medium-letter-spacing                 : 0em;

//styleName: Display/Small/Desktop_Accent;
$accent-small-font-family                     : 'BelyDisplay';
$accent-small-font-size                       : 17px;
$accent-small-font-weight                     : 400;
$accent-small-line-height                     : 26px;
$accent-small-letter-spacing                  : 0em;

//styleName: Display/XSmall/Desktop_Accent;
$accent-xsmall-font-family                    : 'BelyDisplay';
$accent-xsmall-font-size                      : 13px;
$accent-xsmall-font-weight                    : 400;
$accent-xsmall-line-height                    : 20px;
$accent-xsmall-letter-spacing                 : 0em;

//-- Body

//styleName: Body-Text/Large/Bold;
$body-large-bold-font-family                  : 'Gilory';
$body-large-bold-font-size                    : 20px;
$body-large-bold-font-weight                  : 700;
$body-large-bold-line-height                  : 28px;
$body-large-bold-letter-spacing               : 0.02em;

//styleName: Body-Text/Large/Regular;
$body-font-large-regular-family               : 'Gilory';
$body-font-large-regular-font-size            : 20px;
$body-font-large-regular-font-weight          : 400;
$body-font-large-regular-line-height          : 28px;
$body-font-large-regular-letter-spacing       : 0.02em;

//styleName: Body-Text/Medium/Bold;
$body-medium-bold-font-family                 : 'Gilory';
$body-medium-bold-font-size                   : 15px;
$body-medium-bold-font-weight                 : 700;
$body-medium-bold-line-height                 : 24px;
$body-medium-bold-letter-spacing              : 0.02em;

//styleName: Body-Text/Medium/Regular;
$body-medium-regular-font-family              : 'Gilory';
$body-medium-regular-font-size                : 15px;
$body-medium-regular-font-weight              : 400;
$body-medium-regular-line-height              : 24px;
$body-medium-regular-letter-spacing           : 0.02em;

//styleName: Body-Text/Small/Bold;
$body-small-bold-font-family                  : 'Gilory';
$body-small-bold-font-size                    : 13px;
$body-small-bold-font-weight                  : 700;
$body-small-bold-line-height                  : 20px;
$body-small-bold-letter-spacing               : 0.02em;

//styleName: Body-Text/Small/Regular;
$body-small-regular-font-family               : 'Gilory';
$body-small-regular-font-size                 : 13px;
$body-small-regular-font-weight               : 400;
$body-small-regular-line-height               : 20px;
$body-small-regular-letter-spacing            : 0.02em;

//-- Link

//styleName: Text-Link/Large/Bold;
$link-large-bold-font-family                  : 'Gilory';
$link-large-bold-font-size                    : 20px;
$link-large-bold-font-weight                  : 700;
$link-large-bold-line-height                  : 28px;
$link-large-bold-letter-spacing               : 0.02em;

//styleName: Text-Link/Large/Regular;
$link-large-regular-font-family               : 'Gilory';
$link-large-regular-font-size                 : 20px;
$link-large-regular-font-weight               : 500;
$link-large-regular-line-height               : 28px;
$link-large-regular-letter-spacing            : 0.02em;

//styleName: Text-Link/Medium/Bold;
$link-medium-bold-font-family                 : 'Gilory';
$link-medium-bold-font-size                   : 15px;
$link-medium-bold-font-weight                 : 700;
$link-medium-bold-line-height                 : 24px;
$link-medium-bold-letter-spacing              : 0.02em;

//styleName: Text-Link/Medium/Regular;
$link-medium-regular-font-family              : 'Gilory';
$link-medium-regular-font-size                : 15px;
$link-medium-regular-font-weight              : 500;
$link-medium-regular-line-height              : 24px;
$link-medium-regular-letter-spacing           : 0.02em;

//styleName: Text-Link/Small/Bold;
$link-small-bold-font-family                  : 'Gilory';
$link-small-bold-font-size                    : 13px;
$link-small-bold-font-weight                  : 700;
$link-small-bold-line-height                  : 20px;
$link-small-bold-letter-spacing               : 0.02em;

//styleName: Text-Link/Small/Regular;
$link-small-regular-font-family               : 'Gilory';
$link-small-regular-font-size                 : 13px;
$link-small-regular-font-weight               : 500;
$link-small-regular-line-height               : 20px;
$link-small-regular-letter-spacing            : 0.02em;

//-- Label

//styleName: Label/Large/Bold;
$label-large-bold-font-family                 : 'Gilory';
$label-large-bold-font-size                   : 20px;
$label-large-bold-font-weight                 : 700;
$label-large-bold-line-height                 : 28px;
$label-large-bold-letter-spacing              : 0.02em;

//styleName: Label/Large/Regular;
$label-large-regular-font-family              : 'Gilory';
$label-large-regular-font-size                : 20px;
$label-large-regular-font-weight              : 400;
$label-large-regular-line-height              : 28px;
$label-large-regular-letter-spacing           : 0.02em;

//styleName: Label/Medium/Bold;
$label-medium-bold-font-family                : 'Gilory';
$label-medium-bold-font-size                  : 15px;
$label-medium-bold-font-weight                : 700;
$label-medium-bold-line-height                : 24px;
$label-medium-bold-letter-spacing             : 0.02em;

//styleName: Label/Medium/Medium;
$label-medium-medium-font-family              : 'Gilory';
$label-medium-medium-font-size                : 15px;
$label-medium-medium-font-weight              : 500;
$label-medium-medium-line-height              : 24px;
$label-medium-medium-letter-spacing           : 0.02em;

//styleName: Label/Small/Bold;
$label-small-bold-font-family                 : 'Gilory';
$label-small-bold-font-size                   : 13px;
$label-small-bold-font-weight                 : 700;
$label-small-bold-line-height                 : 20px;
$label-small-bold-letter-spacing              : 0.02em;

//styleName: Label/Small/Medium;
$label-small-medium-font-family               : 'Gilory';
$label-small-medium-font-size                 : 13px;
$label-small-medium-font-weight               : 500;
$label-small-medium-line-height               : 20px;
$label-small-medium-letter-spacing            : 0.02em;

//styleName: Label/XSmall/SemiBold;
$label-xsmall-semibold-font-family            : 'Gilory';
$label-xsmall-semibold-font-size              : 10px;
$label-xsmall-semibold-font-weight            : 600;
$label-xsmall-semibold-line-height            : 12px;
$label-xsmall-semibold-letter-spacing         : 0.02em;

//styleName: Label/XSmall/Regular
$label-xsmall-regular-font-family             : 'Gilory';
$label-xsmall-regular-font-size               : 10px;
$label-xsmall-regular-font-weight             : 400;
$label-xsmall-regular-line-height             : 12px;
$label-xsmall-regular-letter-spacing          : 0.02em;

//-- Menu

//styleName: Menu/Desktop_Regular;
$menu-regular-font-family                     : 'Gilory';
$menu-regular-font-size                       : 15px;
$menu-regular-font-weight                     : 500;
$menu-regular-line-height                     : 24px;
$menu-regular-letter-spacing                  : 0.02em;

// ** Logo ** //

$hero-outro-logo-height                       : 112px;

// ** Grafiken ** //

// Brand/Körper&Bewegung
$brand-motion-1                               : '~/public/img/_Icons/_Grafiken/Körper&Bewegung/Grafiken-Körper&Bewegung-1.svg';
$brand-motion-2                               : '~/public/img/_Icons/_Grafiken/Körper&Bewegung/Grafiken-Körper&Bewegung-2.svg';
$brand-motion-3                               : '~/public/img/_Icons/_Grafiken/Körper&Bewegung/Grafiken-Körper&Bewegung-3.svg';
$brand-motion-4                               : '~/public/img/_Icons/_Grafiken/Körper&Bewegung/Grafiken-Körper&Bewegung-4.svg';
$brand-motion-5                               : '~/public/img/_Icons/_Grafiken/Körper&Bewegung/Grafiken-Körper&Bewegung-5.svg';

// Brand/Licht
$brand-light-1                                : '~/public/img/_Icons/_Grafiken/Licht/Grafiken-Licht-1.svg';
$brand-light-2                                : '~/public/img/_Icons/_Grafiken/Licht/Grafiken-Licht-2.svg';
$brand-light-3                                : '~/public/img/_Icons/_Grafiken/Licht/Grafiken-Licht-3.svg';
$brand-light-4                                : '~/public/img/_Icons/_Grafiken/Licht/Grafiken-Licht-4.svg';
$brand-light-5                                : '~/public/img/_Icons/_Grafiken/Licht/Grafiken-Licht-5.svg';

// Brand/Ton
$brand-sound-1                                : '~/public/img/_Icons/_Grafiken/Ton/Grafiken-Ton-1.svg';
$brand-sound-2                                : '~/public/img/_Icons/_Grafiken/Ton/Grafiken-Ton-2.svg';
$brand-sound-3                                : '~/public/img/_Icons/_Grafiken/Ton/Grafiken-Ton-3.svg';
$brand-sound-4                                : '~/public/img/_Icons/_Grafiken/Ton/Grafiken-Ton-4.svg';
$brand-sound-5                                : '~/public/img/_Icons/_Grafiken/Ton/Grafiken-Ton-5.svg';

// Brand/Crown
$brand-crown-up                               : '~/public/img/_Icons/Brand-Crown.svg';
$brand-crown-right                            : '~/public/img/_Icons/Brand-Crown-Right.svg';

// Icons
$icon-bookmark                                : '~/public/img/_Icons/Bookmark.svg';
$icon-brand-crown-right                       : '~/public/img/_Icons/Brand-Crown-Right.svg';
$icon-brand-crown                             : '~/public/img/_Icons/Brand-Crown.svg';
$icon-call                                    : '~/public/img/_Icons/Call.svg';
$icon-chevron-down                            : '~/public/img/_Icons/Chevron-Down.svg';
$icon-chevron-left                            : '~/public/img/_Icons/Chevron-Left.svg';
$icon-chevron-right                           : '~/public/img/_Icons/Chevron-Right.svg';
$icon-chevron-up                              : '~/public/img/_Icons/Chevron-Up.svg';
$icon-raute                                   : '~/public/img/_Icons/Raute.svg';
$icon-email                                   : '~/public/img/_Icons/Email.svg';
$icon-schnuppe                                : '~/public/img/_Icons/Schnuppe.svg';
$icon-star-half                               : '~/public/img/_Icons/Star-Half.svg';
$icon-brand-star                              : '~/public/img/_Icons/Star.svg';
$icon-arrow-right                             : '~/public/img/_Icons/arrow-right.svg';

// Icons/Social-Media
$icon-social-media-facebook                   : '~/public/img/_Icons/_Social-Media/Social-Media-Facebook.svg';
$icon-social-media-instagram                  : '~/public/img/_Icons/_Social-Media/Social-Media-Instagram.svg';
$icon-social-media-youtube                    : '~/public/img/_Icons/_Social-Media/Social-Media-Youtube.svg';

// Hero/Video-Mask
$hero-video-mask                               :'~/public/img/_Masks/video_mask.svg';

// Quotes
$quotes                                        :'~/public/img/_Grafiken/Zitat-Grafik-1.svg';

// ** Background ** //
$background-why                                :'~/public/img/_Background/warum-darum.png';

// ** Phone Mask ** //
$mask-phone                                    :'~/public/img/_Masks/phone.png';




// ** Buttons ** //

//-- Spacing
$button-default-padding-x                     : 24px;
$button-default-padding-y                     : 8px;

//-- Icon
$button-default-icon                          : '~/public/img/_Icons/Schnuppe.svg';
$button-default-icon-width                    : 16px;
$button-default-icon-height                   : 16px;
$button-default-icon-spacing-right            : 4px;

//-- Transition
$button-transiton                             : 0.3s linear;

//-- Border
$button-default-border-width                  : 1px;

//-- Color
$button-primary-dark-bg                       : $primary-black;
$button-primary-dark-color                    : $primary-white;
$button-primary-dark-border-color             : $primary-black;
$button-primary-dark-icon-color               : $primary-red;

$button-primary-light-bg                      : $primary-white;
$button-primary-light-color                   : $primary-black;
$button-primary-light-border-color            : $primary-white;
$button-primary-light-icon-color              : $primary-red;

$button-primary-red-bg                        : $primary-red;
$button-primary-red-color                     : $primary-white;
$button-primary-red-border-color              : $primary-red;
$button-primary-red-icon-color                : $primary-white;

$button-secondary-dark-bg                     : transparent;
$button-secondary-dark-border-color           : $primary-black;
$button-secondary-dark-color                  : $primary-black;
$button-secondary-dark-icon-color             : $primary-red;

$button-secondary-light-bg                    : transparent;
$button-secondary-light-border-color          : $primary-white;
$button-secondary-light-color                 : $primary-white;
$button-secondary-light-icon-color             : $primary-red;

$button-secondary-red-bg                     : transparent;
$button-secondary-red-border-color           : $primary-red;
$button-secondary-red-color                  : $primary-red;
$button-secondary-red-icon-color             : $primary-white;


//** Links **//

$link-arrow-width                             : 16px;
$link-arrow-height                            : 16px;
$link-arrow-gap                               : 2px;
$link-arrow-gap-hover                         : 8px;

$link-transiton                               : 0.3s linear;

//** Cards **//
$card-padding                                 : 32px;
$card-title-margin-bottom                     : 8px;
$card-headline-margin-bottom                  : 24px;

//** Shadows **//
// $shadow-sm                                    : 0px 2px 6px 1px rgba(0,0,0,0.2); 
// $shadow-md                                    : 0px 2px 6px 1px rgba(0,0,0,0.2); 
// $shadow-lg                                    : 0px 2px 6px 1px rgba(0,0,0,0.2); 