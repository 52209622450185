.team {
    &__intro {
        @include add-section-spacing('bottom', 'md');

        .headline .headline__content {
            text-align: center;
            @include generate-display-styles('large', 'extrabold');
        }

        .text-block {
            text-align: center;
            @include generate-body-styles('large', 'regular');
        }
    }

    &__list {
        display: grid;
        grid-template-rows: 1fr;

        &__item {
            display: grid;
            align-items: center;

            .about {
                &__jobs {
                    @include generate-display-styles('xxsmall', 'medium');
                    text-transform: uppercase;
                }

                &__name {
                    @include generate-display-styles('small', 'extrabold');
                }

                &__text {
                    margin-top: 1rem;
                    @include generate-body-styles('medium', 'regular');
                }
            }

            &:not(:first-child) {
                margin-top: -36px;
            }

            &:nth-child(even) {
                grid-template-columns: minmax(350px, 1fr) auto;

                .image {
                    grid-column: 2;
                    grid-row: 1;
                    justify-self: flex-end;
                }

                .about {
                    grid-column: 1;
                    grid-row: 1;
                    @include rfs(3rem, padding-right);
                }
            }

            &:nth-child(odd) {
                grid-template-columns: auto minmax(350px, 1fr);

                .image {
                    grid-column: 1;
                    grid-row: 1;
                }

                .about {
                    grid-column: 2;
                    grid-row: 1;
                    @include rfs(3rem, padding-left);
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__list {
            &__item {
                &:nth-child(odd), &:nth-child(even) {
                    grid-template-columns: auto auto;
                    gap: 1rem;

                    .about {
                        padding-inline: 0;
                        padding-block: 4rem;
                    }

                    .image {
                        height: 100%;

                        img {
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__intro {
            .text-block {
                text-align: left;
            }           
        }

        &__list {
            gap: 2rem;

            &__item {
                &:not(:first-child) {
                    margin-top: 0;
                }

                &:nth-child(even), &:nth-child(odd) {
                    grid-template-columns: 1fr;
                    grid-template-rows: min-content;

                    .image {
                        grid-row: 1;
                        grid-column: 1;
                        justify-self: center;
                    }

                    .about {
                        grid-row: 2;
                        grid-column: 1;
                        padding-block: 0;
                    }
                }
            }
        }
    }
}