.header {
    &--sticky {
        position: sticky;
        top: 0;
        z-index: 1337;
    }

    .container-fluid {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    @include media-breakpoint-down(md) {
        .container-fluid {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}