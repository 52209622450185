.project-list {
    display: grid;
    @include rfs(1.5rem, gap);
    grid-template-columns: repeat(2, 1fr);

    &__header {
        @include rfs(4rem, padding-block);
        @include headline(xl, $primary-white);

        .headline {
            &__content {
                margin-bottom: 0;
            }
        }
    }

    &__item {
        box-shadow: 0px 48px 8px -48px rgba(0, 0, 0, 0.06), 0px 48px 80px 0px rgba(17, 12, 46, 0.12);
        background-color: $primary-white;

        img {
            width: 100%;
            object-fit: cover;
        }

        &__content {    
            padding: map-get($map: $spacers, $key: 3);

            .headline {
                &__title {
                    @include generate-body-styles('medium', 'regular');
                }

                &__content {
                    @include generate-display-styles('medium', 'extrabold');
                }

                grid-template: 1 / -1;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
    }
}