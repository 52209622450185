.section--thanks {
    .headline, .text-block {
        text-align: center;
    }

    .text-block {
        max-width: 45ch;
        margin-inline: auto;
        @include rfs(2.5rem, margin-bottom);
    }

    @include headline(xl);

    .thanks {
        &__button {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-inline: auto;
            width: max-content;
            justify-content: center;
            background-color: $primary-white;
            @include rfs(2.5rem, padding-inline);
            @include rfs(1rem, padding-block);
            position: relative;
            gap: 1rem;

            &::after {
                @include add-icon(
                    $icon: $icon-star-half,
                    $pseudo: true,
                    $color: $primary-white, 
                    $x: -50%,
                    $y: -50%,
                    $position: absolute,
                    $top: 0,
                    $left: 50%,
                    $height: 32px,
                    $widht: 32px,
                );    

                transform: translate(-50%, -100%) rotate(90deg);
            }   

            &-label {
                @include generate-body-styles('medium', 'bold');
                color: $primary-black;
                width: 35ch;
            }

            &-cta {
                padding: $button-default-padding-y $button-default-padding-x;
                @include generate-label-styles(medium, bold);
                background-color: $primary-red;
                color: $primary-white;
                white-space: nowrap;

                img {
                    filter: brightness(0) invert(1);
                }

                &::before {
                    display: none;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .thanks {
            &__button {
                width: min-content;
                
                &-label {
                    text-align: center;
                }
            }
        }       
    }
}