// TODO: add actual styling

.project-two-images {
    display: grid;
    flex-wrap: wrap;
    gap: $spacer;
    grid-template-columns: 1fr 1fr;

    @include headline(md);

    .headline {
        text-align: center;
        grid-column: 1 / -1;
    }

    .image {
        
    }
}