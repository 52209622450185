.home-hero {    
    &__outro {
        width: clamp(80%, 60vw, $breakpoint-md);
        height: 100vh;
        height: 100svh;
        overflow: hidden;
        margin-inline: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        position: relative;

        .logo img {
            height: $hero-outro-logo-height;
            margin-bottom: 2.5rem;
            max-width: 100%;
        }

        @include headline(xl, $primary-white, $star: true);


        .scroll-down {
            opacity: 0;
            bottom: 0;
            transform: translateY(-50px);
            position: absolute;
        }

        .logo {
            opacity: 0;
            transform: scale(2) translateY(-100%);
        }
        
        .headline {
            opacity: 0;
            transform: translateY(100%);
        }

    }
}