@charset "UTF-8";
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #FFFFFF;
  --bs-gray: #10002F;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #F8F8F9;
  --bs-gray-200: #E5E5EA;
  --bs-gray-300: #B2B3BF;
  --bs-gray-400: #7F8095;
  --bs-gray-500: #4C4D6B;
  --bs-gray-600: #10002F;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #10002F;
  --bs-secondary: #5E05A8;
  --bs-purple: #5E05A8;
  --bs-dark-purple: #23003E;
  --bs-red: #FF006E;
  --bs-gray: #7F8095;
  --bs-pink: #FC00C4;
  --bs-rose: #FFBFFF;
  --bs-blue: #00EDD9;
  --bs-green: #57FF99;
  --bs-yellow: #D9FF00;
  --bs-white: #FFFFFF;
  --bs-primary-rgb: 16, 0, 47;
  --bs-secondary-rgb: 94, 5, 168;
  --bs-purple-rgb: 94, 5, 168;
  --bs-dark-purple-rgb: 35, 0, 62;
  --bs-red-rgb: 255, 0, 110;
  --bs-gray-rgb: 127, 128, 149;
  --bs-pink-rgb: 252, 0, 196;
  --bs-rose-rgb: 255, 191, 255;
  --bs-blue-rgb: 0, 237, 217;
  --bs-green-rgb: 87, 255, 153;
  --bs-yellow-rgb: 217, 255, 0;
  --bs-white-rgb: 255, 255, 255;
  --bs-primary-text-emphasis: rgb(6.4, 0, 18.8);
  --bs-secondary-text-emphasis: rgb(37.6, 2, 67.2);
  --bs-success-text-emphasis: rgb(10, 54, 33.6);
  --bs-info-text-emphasis: rgb(5.2, 80.8, 96);
  --bs-warning-text-emphasis: rgb(34.8, 102, 61.2);
  --bs-danger-text-emphasis: #66002c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: rgb(207.2, 204, 213.4);
  --bs-secondary-bg-subtle: rgb(222.8, 205, 237.6);
  --bs-success-bg-subtle: rgb(209, 231, 220.8);
  --bs-info-bg-subtle: rgb(206.6, 244.4, 252);
  --bs-warning-bg-subtle: rgb(221.4, 255, 234.6);
  --bs-danger-bg-subtle: #ffcce2;
  --bs-light-bg-subtle: rgb(251.5, 251.5, 252);
  --bs-dark-bg-subtle: #7F8095;
  --bs-primary-border-subtle: rgb(159.4, 153, 171.8);
  --bs-secondary-border-subtle: rgb(190.6, 155, 220.2);
  --bs-success-border-subtle: rgb(163, 207, 186.6);
  --bs-info-border-subtle: rgb(158.2, 233.8, 249);
  --bs-warning-border-subtle: rgb(187.8, 255, 214.2);
  --bs-danger-border-subtle: #ff99c5;
  --bs-light-border-subtle: #E5E5EA;
  --bs-dark-border-subtle: #4C4D6B;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Gilory", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.75;
  --bs-body-color: #23003E;
  --bs-body-color-rgb: 35, 0, 62;
  --bs-body-bg: #10002F;
  --bs-body-bg-rgb: 16, 0, 47;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(35, 0, 62, 0.75);
  --bs-secondary-color-rgb: 35, 0, 62;
  --bs-secondary-bg: #E5E5EA;
  --bs-secondary-bg-rgb: 229, 229, 234;
  --bs-tertiary-color: rgba(35, 0, 62, 0.5);
  --bs-tertiary-color-rgb: 35, 0, 62;
  --bs-tertiary-bg: #F8F8F9;
  --bs-tertiary-bg-rgb: 248, 248, 249;
  --bs-heading-color: inherit;
  --bs-link-color: #23003E;
  --bs-link-color-rgb: 35, 0, 62;
  --bs-link-decoration: underline;
  --bs-link-hover-color: rgb(28, 0, 49.6);
  --bs-link-hover-color-rgb: 28, 0, 50;
  --bs-code-color: #d63384;
  --bs-highlight-color: #23003E;
  --bs-highlight-bg: rgb(255, 242.6, 205.4);
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #B2B3BF;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0;
  --bs-border-radius-sm: 1.25rem;
  --bs-border-radius-lg: 2.5rem;
  --bs-border-radius-xl: 5rem;
  --bs-border-radius-xxl: 10rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(16, 0, 47, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #FF006E;
  --bs-form-invalid-border-color: #FF006E;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #B2B3BF;
  --bs-body-color-rgb: 178, 179, 191;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #FFFFFF;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(178, 179, 191, 0.75);
  --bs-secondary-color-rgb: 178, 179, 191;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(178, 179, 191, 0.5);
  --bs-tertiary-color-rgb: 178, 179, 191;
  --bs-tertiary-bg: rgb(42.5, 47.5, 52.5);
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: rgb(111.6, 102, 130.2);
  --bs-secondary-text-emphasis: rgb(158.4, 105, 202.8);
  --bs-success-text-emphasis: rgb(117, 183, 152.4);
  --bs-info-text-emphasis: rgb(109.8, 223.2, 246);
  --bs-warning-text-emphasis: rgb(154.2, 255, 193.8);
  --bs-danger-text-emphasis: #ff66a8;
  --bs-light-text-emphasis: #F8F8F9;
  --bs-dark-text-emphasis: #B2B3BF;
  --bs-primary-bg-subtle: rgb(3.2, 0, 9.4);
  --bs-secondary-bg-subtle: rgb(18.8, 1, 33.6);
  --bs-success-bg-subtle: rgb(5, 27, 16.8);
  --bs-info-bg-subtle: rgb(2.6, 40.4, 48);
  --bs-warning-bg-subtle: rgb(17.4, 51, 30.6);
  --bs-danger-bg-subtle: #330016;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: rgb(9.6, 0, 28.2);
  --bs-secondary-border-subtle: rgb(56.4, 3, 100.8);
  --bs-success-border-subtle: rgb(15, 81, 50.4);
  --bs-info-border-subtle: rgb(7.8, 121.2, 144);
  --bs-warning-border-subtle: rgb(52.2, 153, 91.8);
  --bs-danger-border-subtle: #990042;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: rgb(111.6, 102, 130.2);
  --bs-link-hover-color: rgb(140.28, 132.6, 155.16);
  --bs-link-color-rgb: 112, 102, 130;
  --bs-link-hover-color-rgb: 140, 133, 155;
  --bs-code-color: rgb(230.4, 132.6, 181.2);
  --bs-highlight-color: #B2B3BF;
  --bs-highlight-bg: rgb(102, 77.2, 2.8);
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: rgb(117, 183, 152.4);
  --bs-form-valid-border-color: rgb(117, 183, 152.4);
  --bs-form-invalid-color: rgb(234, 133.8, 143.4);
  --bs-form-invalid-border-color: rgb(234, 133.8, 143.4);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.109375rem + 1.875vw);
}
@media (min-width: 1400px) {
  h1, .h1 {
    font-size: 2.75rem;
  }
}

h2, .h2 {
  font-size: calc(1.078125rem + 1.3392857143vw);
}
@media (min-width: 1400px) {
  h2, .h2 {
    font-size: 2.25rem;
  }
}

h3, .h3 {
  font-size: calc(1.0625rem + 1.0714285714vw);
}
@media (min-width: 1400px) {
  h3, .h3 {
    font-size: 2rem;
  }
}

h4, .h4 {
  font-size: calc(1.046875rem + 0.8035714286vw);
}
@media (min-width: 1400px) {
  h4, .h4 {
    font-size: 1.75rem;
  }
}

h5, .h5 {
  font-size: calc(1.03125rem + 0.5357142857vw);
}
@media (min-width: 1400px) {
  h5, .h5 {
    font-size: 1.5rem;
  }
}

h6, .h6 {
  font-size: calc(1.015625rem + 0.2678571429vw);
}
@media (min-width: 1400px) {
  h6, .h6 {
    font-size: 1.25rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 1.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.03125rem + 0.5357142857vw);
  line-height: inherit;
}
@media (min-width: 1400px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 300;
}
@media (min-width: 1400px) {
  .lead {
    font-size: 1.25rem;
  }
}

.display-1 {
  font-size: calc(1.25rem + 4.2857142857vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1400px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.21875rem + 3.75vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1400px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.1875rem + 3.2142857143vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1400px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.15625rem + 2.6785714286vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1400px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.125rem + 2.1428571429vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1400px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.09375rem + 1.6071428571vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1400px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.015625rem + 0.2678571429vw);
}
@media (min-width: 1400px) {
  .blockquote {
    font-size: 1.25rem;
  }
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #10002F;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 1200px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1440px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1152px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 992px;
  --bs-breakpoint-lg: 1200px;
  --bs-breakpoint-xl: 1440px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.grid {
  display: grid;
  grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
  grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
  gap: var(--bs-gap, 1.5rem);
}
.grid .g-col-1 {
  grid-column: auto/span 1;
}
.grid .g-col-2 {
  grid-column: auto/span 2;
}
.grid .g-col-3 {
  grid-column: auto/span 3;
}
.grid .g-col-4 {
  grid-column: auto/span 4;
}
.grid .g-col-5 {
  grid-column: auto/span 5;
}
.grid .g-col-6 {
  grid-column: auto/span 6;
}
.grid .g-col-7 {
  grid-column: auto/span 7;
}
.grid .g-col-8 {
  grid-column: auto/span 8;
}
.grid .g-col-9 {
  grid-column: auto/span 9;
}
.grid .g-col-10 {
  grid-column: auto/span 10;
}
.grid .g-col-11 {
  grid-column: auto/span 11;
}
.grid .g-col-12 {
  grid-column: auto/span 12;
}
.grid .g-start-1 {
  grid-column-start: 1;
}
.grid .g-start-2 {
  grid-column-start: 2;
}
.grid .g-start-3 {
  grid-column-start: 3;
}
.grid .g-start-4 {
  grid-column-start: 4;
}
.grid .g-start-5 {
  grid-column-start: 5;
}
.grid .g-start-6 {
  grid-column-start: 6;
}
.grid .g-start-7 {
  grid-column-start: 7;
}
.grid .g-start-8 {
  grid-column-start: 8;
}
.grid .g-start-9 {
  grid-column-start: 9;
}
.grid .g-start-10 {
  grid-column-start: 10;
}
.grid .g-start-11 {
  grid-column-start: 11;
}
@media (min-width: 576px) {
  .grid .g-col-sm-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-sm-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-sm-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-sm-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-sm-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-sm-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-sm-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-sm-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-sm-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-sm-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-sm-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-sm-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-sm-1 {
    grid-column-start: 1;
  }
  .grid .g-start-sm-2 {
    grid-column-start: 2;
  }
  .grid .g-start-sm-3 {
    grid-column-start: 3;
  }
  .grid .g-start-sm-4 {
    grid-column-start: 4;
  }
  .grid .g-start-sm-5 {
    grid-column-start: 5;
  }
  .grid .g-start-sm-6 {
    grid-column-start: 6;
  }
  .grid .g-start-sm-7 {
    grid-column-start: 7;
  }
  .grid .g-start-sm-8 {
    grid-column-start: 8;
  }
  .grid .g-start-sm-9 {
    grid-column-start: 9;
  }
  .grid .g-start-sm-10 {
    grid-column-start: 10;
  }
  .grid .g-start-sm-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 992px) {
  .grid .g-col-md-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-md-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-md-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-md-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-md-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-md-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-md-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-md-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-md-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-md-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-md-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-md-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-md-1 {
    grid-column-start: 1;
  }
  .grid .g-start-md-2 {
    grid-column-start: 2;
  }
  .grid .g-start-md-3 {
    grid-column-start: 3;
  }
  .grid .g-start-md-4 {
    grid-column-start: 4;
  }
  .grid .g-start-md-5 {
    grid-column-start: 5;
  }
  .grid .g-start-md-6 {
    grid-column-start: 6;
  }
  .grid .g-start-md-7 {
    grid-column-start: 7;
  }
  .grid .g-start-md-8 {
    grid-column-start: 8;
  }
  .grid .g-start-md-9 {
    grid-column-start: 9;
  }
  .grid .g-start-md-10 {
    grid-column-start: 10;
  }
  .grid .g-start-md-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1200px) {
  .grid .g-col-lg-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-lg-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-lg-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-lg-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-lg-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-lg-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-lg-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-lg-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-lg-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-lg-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-lg-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-lg-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-lg-1 {
    grid-column-start: 1;
  }
  .grid .g-start-lg-2 {
    grid-column-start: 2;
  }
  .grid .g-start-lg-3 {
    grid-column-start: 3;
  }
  .grid .g-start-lg-4 {
    grid-column-start: 4;
  }
  .grid .g-start-lg-5 {
    grid-column-start: 5;
  }
  .grid .g-start-lg-6 {
    grid-column-start: 6;
  }
  .grid .g-start-lg-7 {
    grid-column-start: 7;
  }
  .grid .g-start-lg-8 {
    grid-column-start: 8;
  }
  .grid .g-start-lg-9 {
    grid-column-start: 9;
  }
  .grid .g-start-lg-10 {
    grid-column-start: 10;
  }
  .grid .g-start-lg-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1440px) {
  .grid .g-col-xl-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-xl-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-xl-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-xl-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-xl-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-xl-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-xl-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-xl-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-xl-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-xl-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-xl-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-xl-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-xl-1 {
    grid-column-start: 1;
  }
  .grid .g-start-xl-2 {
    grid-column-start: 2;
  }
  .grid .g-start-xl-3 {
    grid-column-start: 3;
  }
  .grid .g-start-xl-4 {
    grid-column-start: 4;
  }
  .grid .g-start-xl-5 {
    grid-column-start: 5;
  }
  .grid .g-start-xl-6 {
    grid-column-start: 6;
  }
  .grid .g-start-xl-7 {
    grid-column-start: 7;
  }
  .grid .g-start-xl-8 {
    grid-column-start: 8;
  }
  .grid .g-start-xl-9 {
    grid-column-start: 9;
  }
  .grid .g-start-xl-10 {
    grid-column-start: 10;
  }
  .grid .g-start-xl-11 {
    grid-column-start: 11;
  }
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 6rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 6rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 12rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 12rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 6rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 12rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 992px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 6rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 6rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 12rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 1200px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 6rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 12rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 1440px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 6rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 12rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 12rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: rgb(207.2, 204, 213.4);
  --bs-table-border-color: rgb(165.76, 163.2, 170.72);
  --bs-table-striped-bg: rgb(196.84, 193.8, 202.73);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(186.48, 183.6, 192.06);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(191.66, 188.7, 197.395);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: rgb(222.8, 205, 237.6);
  --bs-table-border-color: rgb(178.24, 164, 190.08);
  --bs-table-striped-bg: rgb(211.66, 194.75, 225.72);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(200.52, 184.5, 213.84);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(206.09, 189.625, 219.78);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: rgb(209, 231, 220.8);
  --bs-table-border-color: rgb(167.2, 184.8, 176.64);
  --bs-table-striped-bg: rgb(198.55, 219.45, 209.76);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(188.1, 207.9, 198.72);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(193.325, 213.675, 204.24);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: rgb(206.6, 244.4, 252);
  --bs-table-border-color: rgb(165.28, 195.52, 201.6);
  --bs-table-striped-bg: rgb(196.27, 232.18, 239.4);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(185.94, 219.96, 226.8);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(191.105, 226.07, 233.1);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: rgb(221.4, 255, 234.6);
  --bs-table-border-color: rgb(177.12, 204, 187.68);
  --bs-table-striped-bg: rgb(210.33, 242.25, 222.87);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(199.26, 229.5, 211.14);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(204.795, 235.875, 217.005);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #ffcce2;
  --bs-table-border-color: rgb(204, 163.2, 180.8);
  --bs-table-striped-bg: rgb(242.25, 193.8, 214.7);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(229.5, 183.6, 203.4);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(235.875, 188.7, 209.05);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #F8F8F9;
  --bs-table-border-color: rgb(198.4, 198.4, 199.2);
  --bs-table-striped-bg: rgb(235.6, 235.6, 236.55);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(223.2, 223.2, 224.1);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(229.4, 229.4, 230.325);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #FFFFFF;
  --bs-table-bg: #212529;
  --bs-table-border-color: rgb(77.4, 80.6, 83.8);
  --bs-table-striped-bg: rgb(44.1, 47.9, 51.7);
  --bs-table-striped-color: #FFFFFF;
  --bs-table-active-bg: rgb(55.2, 58.8, 62.4);
  --bs-table-active-color: #FFFFFF;
  --bs-table-hover-bg: rgb(49.65, 53.35, 57.05);
  --bs-table-hover-color: #FFFFFF;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1439.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.75;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: calc(1.015625rem + 0.2678571429vw);
}
@media (min-width: 1400px) {
  .col-form-label-lg {
    font-size: 1.25rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  color: var(--bs-body-color);
  appearance: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: var(--bs-border-width) solid #FFFFFF;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #5E05A8;
  background-color: #FFFFFF;
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(16, 0, 47, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.75em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: #10002F;
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 1.5rem;
  margin: -0.375rem -1.5rem;
  margin-inline-end: 1.5rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.75;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.75em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.75em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: calc(1.015625rem + 0.2678571429vw);
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1400px) {
  .form-control-lg {
    font-size: 1.25rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.75em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.75em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.75em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.75em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.75em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.75em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 4.5rem 0.375rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  color: var(--bs-body-color);
  appearance: none;
  background-color: #FFFFFF;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1.5rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid #FFFFFF;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(16, 0, 47, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1.5rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.015625rem + 0.2678571429vw);
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1400px) {
  .form-select-lg {
    font-size: 1.25rem;
  }
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23B2B3BF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.75rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: #FFFFFF;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.375em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(16, 0, 47, 0.25);
}
.form-check-input:checked {
  background-color: #10002F;
  border-color: #10002F;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #10002F;
  border-color: #10002F;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='transparent'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #10002F, 0 0 0 0.25rem rgba(16, 0, 47, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #10002F, 0 0 0 0.25rem rgba(16, 0, 47, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #10002F;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: rgb(183.3, 178.5, 192.6);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #10002F;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: rgb(183.3, 178.5, 192.6);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1.5rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1.5rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.75rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #FFFFFF;
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #10002F;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid #FFFFFF;
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.015625rem + 0.2678571429vw);
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1400px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    font-size: 1.25rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 6rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.75em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4375em + 0.1875rem) center;
  background-size: calc(0.875em + 0.375rem) calc(0.875em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.75em + 0.75rem);
  background-position: top calc(0.4375em + 0.1875rem) right calc(0.4375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 8.25rem;
  background-position: right 1.5rem center, center right 4.5rem;
  background-size: 16px 12px, calc(0.875em + 0.375rem) calc(0.875em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.75em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.75em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF006E'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF006E' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4375em + 0.1875rem) center;
  background-size: calc(0.875em + 0.375rem) calc(0.875em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.75em + 0.75rem);
  background-position: top calc(0.4375em + 0.1875rem) right calc(0.4375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF006E'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF006E' stroke='none'/%3e%3c/svg%3e");
  padding-right: 8.25rem;
  background-position: right 1.5rem center, center right 4.5rem;
  background-size: 16px 12px, calc(0.875em + 0.375rem) calc(0.875em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.75em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.75;
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #10002F;
  --bs-btn-border-color: #10002F;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: rgb(13.6, 0, 39.95);
  --bs-btn-hover-border-color: rgb(12.8, 0, 37.6);
  --bs-btn-focus-shadow-rgb: 52, 38, 78;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: rgb(12.8, 0, 37.6);
  --bs-btn-active-border-color: rgb(12, 0, 35.25);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #10002F;
  --bs-btn-disabled-border-color: #10002F;
}

.btn-secondary {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #5E05A8;
  --bs-btn-border-color: #5E05A8;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: rgb(79.9, 4.25, 142.8);
  --bs-btn-hover-border-color: rgb(75.2, 4, 134.4);
  --bs-btn-focus-shadow-rgb: 118, 43, 181;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: rgb(75.2, 4, 134.4);
  --bs-btn-active-border-color: rgb(70.5, 3.75, 126);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #5E05A8;
  --bs-btn-disabled-border-color: #5E05A8;
}

.btn-purple {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #5E05A8;
  --bs-btn-border-color: #5E05A8;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: rgb(79.9, 4.25, 142.8);
  --bs-btn-hover-border-color: rgb(75.2, 4, 134.4);
  --bs-btn-focus-shadow-rgb: 118, 43, 181;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: rgb(75.2, 4, 134.4);
  --bs-btn-active-border-color: rgb(70.5, 3.75, 126);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #5E05A8;
  --bs-btn-disabled-border-color: #5E05A8;
}

.btn-dark-purple {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #23003E;
  --bs-btn-border-color: #23003E;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: rgb(29.75, 0, 52.7);
  --bs-btn-hover-border-color: rgb(28, 0, 49.6);
  --bs-btn-focus-shadow-rgb: 68, 38, 91;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: rgb(28, 0, 49.6);
  --bs-btn-active-border-color: rgb(26.25, 0, 46.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #23003E;
  --bs-btn-disabled-border-color: #23003E;
}

.btn-red {
  --bs-btn-color: #000;
  --bs-btn-bg: #FF006E;
  --bs-btn-border-color: #FF006E;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(255, 38.25, 131.75);
  --bs-btn-hover-border-color: rgb(255, 25.5, 124.5);
  --bs-btn-focus-shadow-rgb: 217, 0, 94;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff338b;
  --bs-btn-active-border-color: rgb(255, 25.5, 124.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FF006E;
  --bs-btn-disabled-border-color: #FF006E;
}

.btn-gray {
  --bs-btn-color: #000;
  --bs-btn-bg: #7F8095;
  --bs-btn-border-color: #7F8095;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(146.2, 147.05, 164.9);
  --bs-btn-hover-border-color: rgb(139.8, 140.7, 159.6);
  --bs-btn-focus-shadow-rgb: 108, 109, 127;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(152.6, 153.4, 170.2);
  --bs-btn-active-border-color: rgb(139.8, 140.7, 159.6);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #7F8095;
  --bs-btn-disabled-border-color: #7F8095;
}

.btn-pink {
  --bs-btn-color: #000;
  --bs-btn-bg: #FC00C4;
  --bs-btn-border-color: #FC00C4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(252.45, 38.25, 204.85);
  --bs-btn-hover-border-color: rgb(252.3, 25.5, 201.9);
  --bs-btn-focus-shadow-rgb: 214, 0, 167;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(252.6, 51, 207.8);
  --bs-btn-active-border-color: rgb(252.3, 25.5, 201.9);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FC00C4;
  --bs-btn-disabled-border-color: #FC00C4;
}

.btn-rose {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFBFFF;
  --bs-btn-border-color: #FFBFFF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(255, 200.6, 255);
  --bs-btn-hover-border-color: rgb(255, 197.4, 255);
  --bs-btn-focus-shadow-rgb: 217, 162, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(255, 203.8, 255);
  --bs-btn-active-border-color: rgb(255, 197.4, 255);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFBFFF;
  --bs-btn-disabled-border-color: #FFBFFF;
}

.btn-blue {
  --bs-btn-color: #000;
  --bs-btn-bg: #00EDD9;
  --bs-btn-border-color: #00EDD9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(38.25, 239.7, 222.7);
  --bs-btn-hover-border-color: rgb(25.5, 238.8, 220.8);
  --bs-btn-focus-shadow-rgb: 0, 201, 184;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(51, 240.6, 224.6);
  --bs-btn-active-border-color: rgb(25.5, 238.8, 220.8);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #00EDD9;
  --bs-btn-disabled-border-color: #00EDD9;
}

.btn-green {
  --bs-btn-color: #000;
  --bs-btn-bg: #57FF99;
  --bs-btn-border-color: #57FF99;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(112.2, 255, 168.3);
  --bs-btn-hover-border-color: rgb(103.8, 255, 163.2);
  --bs-btn-focus-shadow-rgb: 74, 217, 130;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(120.6, 255, 173.4);
  --bs-btn-active-border-color: rgb(103.8, 255, 163.2);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #57FF99;
  --bs-btn-disabled-border-color: #57FF99;
}

.btn-yellow {
  --bs-btn-color: #000;
  --bs-btn-bg: #D9FF00;
  --bs-btn-border-color: #D9FF00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(222.7, 255, 38.25);
  --bs-btn-hover-border-color: rgb(220.8, 255, 25.5);
  --bs-btn-focus-shadow-rgb: 184, 217, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(224.6, 255, 51);
  --bs-btn-active-border-color: rgb(220.8, 255, 25.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #D9FF00;
  --bs-btn-disabled-border-color: #D9FF00;
}

.btn-white {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFFFFF;
  --bs-btn-border-color: #FFFFFF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFFFFF;
  --bs-btn-disabled-border-color: #FFFFFF;
}

.btn-outline-primary {
  --bs-btn-color: #10002F;
  --bs-btn-border-color: #10002F;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #10002F;
  --bs-btn-hover-border-color: #10002F;
  --bs-btn-focus-shadow-rgb: 16, 0, 47;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #10002F;
  --bs-btn-active-border-color: #10002F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #10002F;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #10002F;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #5E05A8;
  --bs-btn-border-color: #5E05A8;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #5E05A8;
  --bs-btn-hover-border-color: #5E05A8;
  --bs-btn-focus-shadow-rgb: 94, 5, 168;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #5E05A8;
  --bs-btn-active-border-color: #5E05A8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5E05A8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5E05A8;
  --bs-gradient: none;
}

.btn-outline-purple {
  --bs-btn-color: #5E05A8;
  --bs-btn-border-color: #5E05A8;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #5E05A8;
  --bs-btn-hover-border-color: #5E05A8;
  --bs-btn-focus-shadow-rgb: 94, 5, 168;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #5E05A8;
  --bs-btn-active-border-color: #5E05A8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5E05A8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5E05A8;
  --bs-gradient: none;
}

.btn-outline-dark-purple {
  --bs-btn-color: #23003E;
  --bs-btn-border-color: #23003E;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #23003E;
  --bs-btn-hover-border-color: #23003E;
  --bs-btn-focus-shadow-rgb: 35, 0, 62;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #23003E;
  --bs-btn-active-border-color: #23003E;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #23003E;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #23003E;
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: #FF006E;
  --bs-btn-border-color: #FF006E;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FF006E;
  --bs-btn-hover-border-color: #FF006E;
  --bs-btn-focus-shadow-rgb: 255, 0, 110;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FF006E;
  --bs-btn-active-border-color: #FF006E;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FF006E;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FF006E;
  --bs-gradient: none;
}

.btn-outline-gray {
  --bs-btn-color: #7F8095;
  --bs-btn-border-color: #7F8095;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #7F8095;
  --bs-btn-hover-border-color: #7F8095;
  --bs-btn-focus-shadow-rgb: 127, 128, 149;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #7F8095;
  --bs-btn-active-border-color: #7F8095;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #7F8095;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #7F8095;
  --bs-gradient: none;
}

.btn-outline-pink {
  --bs-btn-color: #FC00C4;
  --bs-btn-border-color: #FC00C4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FC00C4;
  --bs-btn-hover-border-color: #FC00C4;
  --bs-btn-focus-shadow-rgb: 252, 0, 196;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FC00C4;
  --bs-btn-active-border-color: #FC00C4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FC00C4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FC00C4;
  --bs-gradient: none;
}

.btn-outline-rose {
  --bs-btn-color: #FFBFFF;
  --bs-btn-border-color: #FFBFFF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFBFFF;
  --bs-btn-hover-border-color: #FFBFFF;
  --bs-btn-focus-shadow-rgb: 255, 191, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFBFFF;
  --bs-btn-active-border-color: #FFBFFF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFBFFF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFBFFF;
  --bs-gradient: none;
}

.btn-outline-blue {
  --bs-btn-color: #00EDD9;
  --bs-btn-border-color: #00EDD9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #00EDD9;
  --bs-btn-hover-border-color: #00EDD9;
  --bs-btn-focus-shadow-rgb: 0, 237, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #00EDD9;
  --bs-btn-active-border-color: #00EDD9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00EDD9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00EDD9;
  --bs-gradient: none;
}

.btn-outline-green {
  --bs-btn-color: #57FF99;
  --bs-btn-border-color: #57FF99;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #57FF99;
  --bs-btn-hover-border-color: #57FF99;
  --bs-btn-focus-shadow-rgb: 87, 255, 153;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #57FF99;
  --bs-btn-active-border-color: #57FF99;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #57FF99;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #57FF99;
  --bs-gradient: none;
}

.btn-outline-yellow {
  --bs-btn-color: #D9FF00;
  --bs-btn-border-color: #D9FF00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #D9FF00;
  --bs-btn-hover-border-color: #D9FF00;
  --bs-btn-focus-shadow-rgb: 217, 255, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #D9FF00;
  --bs-btn-active-border-color: #D9FF00;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #D9FF00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #D9FF00;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #FFFFFF;
  --bs-btn-border-color: #FFFFFF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFFFFF;
  --bs-btn-hover-border-color: #FFFFFF;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFFFFF;
  --bs-btn-active-border-color: #FFFFFF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFFFFF;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #10002F;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 68, 38, 91;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: calc(1.015625rem + 0.2678571429vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1400px) {
  .btn-lg, .btn-group-lg > .btn {
    --bs-btn-font-size: 1.25rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.9375rem;
  --bs-dropdown-color: #4C4D6B;
  --bs-dropdown-bg: #FFFFFF;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - 0);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #FFFFFF;
  --bs-dropdown-link-active-bg: #10002F;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 16px;
  --bs-dropdown-item-padding-y: 12px;
  --bs-dropdown-header-color: #10002F;
  --bs-dropdown-header-padding-x: 16px;
  --bs-dropdown-header-padding-y: 0;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}
.dropdown-menu > .dropdown-item:first-child,
.dropdown-menu > li:first-child .dropdown-item {
  border-top-left-radius: var(--bs-dropdown-inner-border-radius);
  border-top-right-radius: var(--bs-dropdown-inner-border-radius);
}
.dropdown-menu > .dropdown-item:last-child,
.dropdown-menu > li:last-child .dropdown-item {
  border-bottom-right-radius: var(--bs-dropdown-inner-border-radius);
  border-bottom-left-radius: var(--bs-dropdown-inner-border-radius);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1440px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #B2B3BF;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #B2B3BF;
  --bs-dropdown-link-hover-color: #FFFFFF;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #FFFFFF;
  --bs-dropdown-link-active-bg: #10002F;
  --bs-dropdown-link-disabled-color: #4C4D6B;
  --bs-dropdown-header-color: #4C4D6B;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 1.25rem;
  --bs-nav-link-font-size: 0.9375rem;
  --bs-nav-link-font-weight: 500;
  --bs-nav-link-color: #5E05A8;
  --bs-nav-link-hover-color: #5E05A8;
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(16, 0, 47, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #FFFFFF;
  --bs-nav-pills-link-active-bg: #10002F;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0;
  --bs-navbar-color: #23003E;
  --bs-navbar-hover-color: #10002F;
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: #10002F;
  --bs-navbar-brand-padding-y: 0;
  --bs-navbar-brand-margin-end: 4rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #10002F;
  --bs-navbar-brand-hover-color: #10002F;
  --bs-navbar-nav-link-padding-x: 0;
  --bs-navbar-toggler-padding-y: 0;
  --bs-navbar-toggler-padding-x: 0;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2835, 0, 62, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 1.25rem;
  --bs-nav-link-font-size: 0.9375rem;
  --bs-nav-link-font-weight: 500;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #FFFFFF;
  --bs-navbar-brand-color: #FFFFFF;
  --bs-navbar-brand-hover-color: #FFFFFF;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: inherit;
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: #E5E5EA;
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: inherit;
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-btn-icon: url("~/public/img/_Icons/Chevron-Down.svg");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("~/public/img/_Icons/Chevron-Down.svg");
  --bs-accordion-btn-focus-box-shadow: transaperent;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: inherit;
  --bs-accordion-active-bg: transparent;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb%28111.6, 102, 130.2%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb%28111.6, 102, 130.2%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(16, 0, 47, 0.25);
  --bs-pagination-active-color: #FFFFFF;
  --bs-pagination-active-bg: #10002F;
  --bs-pagination-active-border-color: #10002F;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: calc(1.015625rem + 0.2678571429vw);
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1400px) {
  .pagination-lg {
    --bs-pagination-font-size: 1.25rem;
  }
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #FFFFFF;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-purple {
  --bs-alert-color: var(--bs-purple-text-emphasis);
  --bs-alert-bg: var(--bs-purple-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-border-subtle);
  --bs-alert-link-color: var(--bs-purple-text-emphasis);
}

.alert-dark-purple {
  --bs-alert-color: var(--bs-dark-purple-text-emphasis);
  --bs-alert-bg: var(--bs-dark-purple-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-purple-border-subtle);
  --bs-alert-link-color: var(--bs-dark-purple-text-emphasis);
}

.alert-red {
  --bs-alert-color: var(--bs-red-text-emphasis);
  --bs-alert-bg: var(--bs-red-bg-subtle);
  --bs-alert-border-color: var(--bs-red-border-subtle);
  --bs-alert-link-color: var(--bs-red-text-emphasis);
}

.alert-gray {
  --bs-alert-color: var(--bs-gray-text-emphasis);
  --bs-alert-bg: var(--bs-gray-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-border-subtle);
  --bs-alert-link-color: var(--bs-gray-text-emphasis);
}

.alert-pink {
  --bs-alert-color: var(--bs-pink-text-emphasis);
  --bs-alert-bg: var(--bs-pink-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-border-subtle);
  --bs-alert-link-color: var(--bs-pink-text-emphasis);
}

.alert-rose {
  --bs-alert-color: var(--bs-rose-text-emphasis);
  --bs-alert-bg: var(--bs-rose-bg-subtle);
  --bs-alert-border-color: var(--bs-rose-border-subtle);
  --bs-alert-link-color: var(--bs-rose-text-emphasis);
}

.alert-blue {
  --bs-alert-color: var(--bs-blue-text-emphasis);
  --bs-alert-bg: var(--bs-blue-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-border-subtle);
  --bs-alert-link-color: var(--bs-blue-text-emphasis);
}

.alert-green {
  --bs-alert-color: var(--bs-green-text-emphasis);
  --bs-alert-bg: var(--bs-green-bg-subtle);
  --bs-alert-border-color: var(--bs-green-border-subtle);
  --bs-alert-link-color: var(--bs-green-text-emphasis);
}

.alert-yellow {
  --bs-alert-color: var(--bs-yellow-text-emphasis);
  --bs-alert-bg: var(--bs-yellow-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-text-emphasis);
}

.alert-white {
  --bs-alert-color: var(--bs-white-text-emphasis);
  --bs-alert-bg: var(--bs-white-bg-subtle);
  --bs-alert-border-color: var(--bs-white-border-subtle);
  --bs-alert-link-color: var(--bs-white-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #FFFFFF;
  --bs-progress-bar-bg: #10002F;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #FFFFFF;
  --bs-list-group-active-bg: #10002F;
  --bs-list-group-active-border-color: #10002F;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1440px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-purple {
  --bs-list-group-color: var(--bs-purple-text-emphasis);
  --bs-list-group-bg: var(--bs-purple-bg-subtle);
  --bs-list-group-border-color: var(--bs-purple-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-purple-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-purple-border-subtle);
  --bs-list-group-active-color: var(--bs-purple-bg-subtle);
  --bs-list-group-active-bg: var(--bs-purple-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-purple-text-emphasis);
}

.list-group-item-dark-purple {
  --bs-list-group-color: var(--bs-dark-purple-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-purple-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-purple-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-purple-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-purple-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-purple-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-purple-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-purple-text-emphasis);
}

.list-group-item-red {
  --bs-list-group-color: var(--bs-red-text-emphasis);
  --bs-list-group-bg: var(--bs-red-bg-subtle);
  --bs-list-group-border-color: var(--bs-red-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-red-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-red-border-subtle);
  --bs-list-group-active-color: var(--bs-red-bg-subtle);
  --bs-list-group-active-bg: var(--bs-red-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-red-text-emphasis);
}

.list-group-item-gray {
  --bs-list-group-color: var(--bs-gray-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-text-emphasis);
}

.list-group-item-pink {
  --bs-list-group-color: var(--bs-pink-text-emphasis);
  --bs-list-group-bg: var(--bs-pink-bg-subtle);
  --bs-list-group-border-color: var(--bs-pink-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-pink-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-pink-border-subtle);
  --bs-list-group-active-color: var(--bs-pink-bg-subtle);
  --bs-list-group-active-bg: var(--bs-pink-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-pink-text-emphasis);
}

.list-group-item-rose {
  --bs-list-group-color: var(--bs-rose-text-emphasis);
  --bs-list-group-bg: var(--bs-rose-bg-subtle);
  --bs-list-group-border-color: var(--bs-rose-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-rose-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-rose-border-subtle);
  --bs-list-group-active-color: var(--bs-rose-bg-subtle);
  --bs-list-group-active-bg: var(--bs-rose-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-rose-text-emphasis);
}

.list-group-item-blue {
  --bs-list-group-color: var(--bs-blue-text-emphasis);
  --bs-list-group-bg: var(--bs-blue-bg-subtle);
  --bs-list-group-border-color: var(--bs-blue-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-blue-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-blue-border-subtle);
  --bs-list-group-active-color: var(--bs-blue-bg-subtle);
  --bs-list-group-active-bg: var(--bs-blue-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-blue-text-emphasis);
}

.list-group-item-green {
  --bs-list-group-color: var(--bs-green-text-emphasis);
  --bs-list-group-bg: var(--bs-green-bg-subtle);
  --bs-list-group-border-color: var(--bs-green-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-green-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-green-border-subtle);
  --bs-list-group-active-color: var(--bs-green-bg-subtle);
  --bs-list-group-active-bg: var(--bs-green-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-green-text-emphasis);
}

.list-group-item-yellow {
  --bs-list-group-color: var(--bs-yellow-text-emphasis);
  --bs-list-group-bg: var(--bs-yellow-bg-subtle);
  --bs-list-group-border-color: var(--bs-yellow-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-yellow-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-yellow-border-subtle);
  --bs-list-group-active-color: var(--bs-yellow-bg-subtle);
  --bs-list-group-active-bg: var(--bs-yellow-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-yellow-text-emphasis);
}

.list-group-item-white {
  --bs-list-group-color: var(--bs-white-text-emphasis);
  --bs-list-group-bg: var(--bs-white-bg-subtle);
  --bs-list-group-border-color: var(--bs-white-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-white-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-white-border-subtle);
  --bs-list-group-active-color: var(--bs-white-bg-subtle);
  --bs-list-group-active-bg: var(--bs-white-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-white-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(16, 0, 47, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 3rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.75;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 1200px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1440px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1439.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #FFFFFF;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #FFFFFF;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #FFFFFF;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.75;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1439.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1439.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1439.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1440px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(-0.5 * var(--bs-offcanvas-padding-x)) calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-purple-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark-purple {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-dark-purple-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red {
  color: #000 !important;
  background-color: RGBA(var(--bs-red-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray {
  color: #000 !important;
  background-color: RGBA(var(--bs-gray-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink {
  color: #000 !important;
  background-color: RGBA(var(--bs-pink-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-rose {
  color: #000 !important;
  background-color: RGBA(var(--bs-rose-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue {
  color: #000 !important;
  background-color: RGBA(var(--bs-blue-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green {
  color: #000 !important;
  background-color: RGBA(var(--bs-green-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow {
  color: #000 !important;
  background-color: RGBA(var(--bs-yellow-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #000 !important;
  background-color: RGBA(var(--bs-white-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(13, 0, 38, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(13, 0, 38, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(75, 4, 134, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(75, 4, 134, var(--bs-link-underline-opacity, 1)) !important;
}

.link-purple {
  color: RGBA(var(--bs-purple-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-purple-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-purple:hover, .link-purple:focus {
  color: RGBA(75, 4, 134, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(75, 4, 134, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark-purple {
  color: RGBA(var(--bs-dark-purple-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-purple-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark-purple:hover, .link-dark-purple:focus {
  color: RGBA(28, 0, 50, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(28, 0, 50, var(--bs-link-underline-opacity, 1)) !important;
}

.link-red {
  color: RGBA(var(--bs-red-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-red-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-red:hover, .link-red:focus {
  color: RGBA(255, 51, 139, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 51, 139, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray {
  color: RGBA(var(--bs-gray-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray:hover, .link-gray:focus {
  color: RGBA(153, 153, 170, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(153, 153, 170, var(--bs-link-underline-opacity, 1)) !important;
}

.link-pink {
  color: RGBA(var(--bs-pink-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-pink-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-pink:hover, .link-pink:focus {
  color: RGBA(253, 51, 208, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(253, 51, 208, var(--bs-link-underline-opacity, 1)) !important;
}

.link-rose {
  color: RGBA(var(--bs-rose-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-rose-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-rose:hover, .link-rose:focus {
  color: RGBA(255, 204, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 204, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-blue {
  color: RGBA(var(--bs-blue-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-blue-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-blue:hover, .link-blue:focus {
  color: RGBA(51, 241, 225, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(51, 241, 225, var(--bs-link-underline-opacity, 1)) !important;
}

.link-green {
  color: RGBA(var(--bs-green-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-green-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-green:hover, .link-green:focus {
  color: RGBA(121, 255, 173, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(121, 255, 173, var(--bs-link-underline-opacity, 1)) !important;
}

.link-yellow {
  color: RGBA(var(--bs-yellow-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-yellow-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-yellow:hover, .link-yellow:focus {
  color: RGBA(225, 255, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(225, 255, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-white {
  color: RGBA(var(--bs-white-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-white-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-white:hover, .link-white:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1440px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-purple {
  --bs-focus-ring-color: rgba(var(--bs-purple-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark-purple {
  --bs-focus-ring-color: rgba(var(--bs-dark-purple-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-red {
  --bs-focus-ring-color: rgba(var(--bs-red-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray {
  --bs-focus-ring-color: rgba(var(--bs-gray-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-pink {
  --bs-focus-ring-color: rgba(var(--bs-pink-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-rose {
  --bs-focus-ring-color: rgba(var(--bs-rose-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-blue {
  --bs-focus-ring-color: rgba(var(--bs-blue-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-green {
  --bs-focus-ring-color: rgba(var(--bs-green-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-yellow {
  --bs-focus-ring-color: rgba(var(--bs-yellow-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-white {
  --bs-focus-ring-color: rgba(var(--bs-white-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-purple {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-rgb), var(--bs-border-opacity)) !important;
}

.border-dark-purple {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-purple-rgb), var(--bs-border-opacity)) !important;
}

.border-red {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-rgb), var(--bs-border-opacity)) !important;
}

.border-gray {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-rgb), var(--bs-border-opacity)) !important;
}

.border-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-rose {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-rose-rgb), var(--bs-border-opacity)) !important;
}

.border-blue {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-rgb), var(--bs-border-opacity)) !important;
}

.border-green {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 6rem !important;
}

.m-7 {
  margin: 12rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-7 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-7 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 6rem !important;
}

.mt-7 {
  margin-top: 12rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 6rem !important;
}

.me-7 {
  margin-right: 12rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;
}

.mb-7 {
  margin-bottom: 12rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 6rem !important;
}

.ms-7 {
  margin-left: 12rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -6rem !important;
}

.m-n7 {
  margin: -12rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n6 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n7 {
  margin-right: -12rem !important;
  margin-left: -12rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n7 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -6rem !important;
}

.mt-n7 {
  margin-top: -12rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n6 {
  margin-right: -6rem !important;
}

.me-n7 {
  margin-right: -12rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -6rem !important;
}

.mb-n7 {
  margin-bottom: -12rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n6 {
  margin-left: -6rem !important;
}

.ms-n7 {
  margin-left: -12rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 6rem !important;
}

.p-7 {
  padding: 12rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-7 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-7 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 6rem !important;
}

.pt-7 {
  padding-top: 12rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 6rem !important;
}

.pe-7 {
  padding-right: 12rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 6rem !important;
}

.pb-7 {
  padding-bottom: 12rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 6rem !important;
}

.ps-7 {
  padding-left: 12rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 6rem !important;
}

.gap-7 {
  gap: 12rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-6 {
  row-gap: 6rem !important;
}

.row-gap-7 {
  row-gap: 12rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.column-gap-6 {
  column-gap: 6rem !important;
}

.column-gap-7 {
  column-gap: 12rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.109375rem + 1.875vw) !important;
}

.fs-2 {
  font-size: calc(1.078125rem + 1.3392857143vw) !important;
}

.fs-3 {
  font-size: calc(1.0625rem + 1.0714285714vw) !important;
}

.fs-4 {
  font-size: calc(1.046875rem + 0.8035714286vw) !important;
}

.fs-5 {
  font-size: calc(1.03125rem + 0.5357142857vw) !important;
}

.fs-6 {
  font-size: calc(1.015625rem + 0.2678571429vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.75 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-purple-rgb), var(--bs-text-opacity)) !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-rgb), var(--bs-text-opacity)) !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-rose {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-rose-rgb), var(--bs-text-opacity)) !important;
}

.text-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-green {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-purple {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-purple-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark-purple {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-purple-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-red {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-red-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-pink {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-pink-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-rose {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-rose-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-blue {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-blue-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-green {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-green-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-yellow {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-yellow-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-white {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-rose {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-rose-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 6rem !important;
  }
  .m-sm-7 {
    margin: 12rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-7 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-7 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 6rem !important;
  }
  .mt-sm-7 {
    margin-top: 12rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 6rem !important;
  }
  .me-sm-7 {
    margin-right: 12rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 12rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 6rem !important;
  }
  .ms-sm-7 {
    margin-left: 12rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .m-sm-n6 {
    margin: -6rem !important;
  }
  .m-sm-n7 {
    margin: -12rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n6 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-sm-n7 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n6 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-sm-n7 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mt-sm-n6 {
    margin-top: -6rem !important;
  }
  .mt-sm-n7 {
    margin-top: -12rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .me-sm-n6 {
    margin-right: -6rem !important;
  }
  .me-sm-n7 {
    margin-right: -12rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -12rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .ms-sm-n6 {
    margin-left: -6rem !important;
  }
  .ms-sm-n7 {
    margin-left: -12rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 6rem !important;
  }
  .p-sm-7 {
    padding: 12rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-7 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-7 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 6rem !important;
  }
  .pt-sm-7 {
    padding-top: 12rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 6rem !important;
  }
  .pe-sm-7 {
    padding-right: 12rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 12rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 6rem !important;
  }
  .ps-sm-7 {
    padding-left: 12rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 6rem !important;
  }
  .gap-sm-7 {
    gap: 12rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 6rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 12rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 6rem !important;
  }
  .column-gap-sm-7 {
    column-gap: 12rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 6rem !important;
  }
  .m-md-7 {
    margin: 12rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-7 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-7 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 6rem !important;
  }
  .mt-md-7 {
    margin-top: 12rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 6rem !important;
  }
  .me-md-7 {
    margin-right: 12rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 6rem !important;
  }
  .mb-md-7 {
    margin-bottom: 12rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 6rem !important;
  }
  .ms-md-7 {
    margin-left: 12rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .m-md-n6 {
    margin: -6rem !important;
  }
  .m-md-n7 {
    margin: -12rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n6 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-md-n7 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n6 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-md-n7 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mt-md-n6 {
    margin-top: -6rem !important;
  }
  .mt-md-n7 {
    margin-top: -12rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .me-md-n6 {
    margin-right: -6rem !important;
  }
  .me-md-n7 {
    margin-right: -12rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -12rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .ms-md-n6 {
    margin-left: -6rem !important;
  }
  .ms-md-n7 {
    margin-left: -12rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 6rem !important;
  }
  .p-md-7 {
    padding: 12rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-7 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-7 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 6rem !important;
  }
  .pt-md-7 {
    padding-top: 12rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 6rem !important;
  }
  .pe-md-7 {
    padding-right: 12rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 6rem !important;
  }
  .pb-md-7 {
    padding-bottom: 12rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 6rem !important;
  }
  .ps-md-7 {
    padding-left: 12rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 6rem !important;
  }
  .gap-md-7 {
    gap: 12rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .row-gap-md-6 {
    row-gap: 6rem !important;
  }
  .row-gap-md-7 {
    row-gap: 12rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .column-gap-md-6 {
    column-gap: 6rem !important;
  }
  .column-gap-md-7 {
    column-gap: 12rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 6rem !important;
  }
  .m-lg-7 {
    margin: 12rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-7 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-7 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 6rem !important;
  }
  .mt-lg-7 {
    margin-top: 12rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 6rem !important;
  }
  .me-lg-7 {
    margin-right: 12rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 6rem !important;
  }
  .ms-lg-7 {
    margin-left: 12rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .m-lg-n6 {
    margin: -6rem !important;
  }
  .m-lg-n7 {
    margin: -12rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n6 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-lg-n7 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n6 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-lg-n7 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mt-lg-n6 {
    margin-top: -6rem !important;
  }
  .mt-lg-n7 {
    margin-top: -12rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .me-lg-n6 {
    margin-right: -6rem !important;
  }
  .me-lg-n7 {
    margin-right: -12rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -12rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .ms-lg-n6 {
    margin-left: -6rem !important;
  }
  .ms-lg-n7 {
    margin-left: -12rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 6rem !important;
  }
  .p-lg-7 {
    padding: 12rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-7 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-7 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 6rem !important;
  }
  .pt-lg-7 {
    padding-top: 12rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 6rem !important;
  }
  .pe-lg-7 {
    padding-right: 12rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 12rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 6rem !important;
  }
  .ps-lg-7 {
    padding-left: 12rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 6rem !important;
  }
  .gap-lg-7 {
    gap: 12rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 6rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 12rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 6rem !important;
  }
  .column-gap-lg-7 {
    column-gap: 12rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 6rem !important;
  }
  .m-xl-7 {
    margin: 12rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-7 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-7 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 6rem !important;
  }
  .mt-xl-7 {
    margin-top: 12rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 6rem !important;
  }
  .me-xl-7 {
    margin-right: 12rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 6rem !important;
  }
  .ms-xl-7 {
    margin-left: 12rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .m-xl-n6 {
    margin: -6rem !important;
  }
  .m-xl-n7 {
    margin: -12rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n6 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xl-n7 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n6 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xl-n7 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xl-n6 {
    margin-top: -6rem !important;
  }
  .mt-xl-n7 {
    margin-top: -12rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .me-xl-n6 {
    margin-right: -6rem !important;
  }
  .me-xl-n7 {
    margin-right: -12rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -12rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xl-n6 {
    margin-left: -6rem !important;
  }
  .ms-xl-n7 {
    margin-left: -12rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 6rem !important;
  }
  .p-xl-7 {
    padding: 12rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-7 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-7 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 6rem !important;
  }
  .pt-xl-7 {
    padding-top: 12rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 6rem !important;
  }
  .pe-xl-7 {
    padding-right: 12rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 12rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 6rem !important;
  }
  .ps-xl-7 {
    padding-left: 12rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 6rem !important;
  }
  .gap-xl-7 {
    gap: 12rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 6rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 12rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 6rem !important;
  }
  .column-gap-xl-7 {
    column-gap: 12rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .fs-1 {
    font-size: 2.75rem !important;
  }
  .fs-2 {
    font-size: 2.25rem !important;
  }
  .fs-3 {
    font-size: 2rem !important;
  }
  .fs-4 {
    font-size: 1.75rem !important;
  }
  .fs-5 {
    font-size: 1.5rem !important;
  }
  .fs-6 {
    font-size: 1.25rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  font-feature-settings: "clig" off, "liga" off;
}
body main {
  flex-grow: 1;
}

p:last-child,
p:only-child {
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

.sr-only {
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  white-space: nowrap;
}

@font-face {
  font-display: swap;
  font-family: "BelyDisplay";
  font-style: normal;
  font-weight: 400;
  src: url("../../../../public/fonts/BelyDisplay-Regular.otf") format("opentype");
}
@font-face {
  font-display: swap;
  font-family: "Star";
  font-style: normal;
  font-weight: 300;
  src: url("../../../../public/fonts/KrawallKrone-Star.otf") format("opentype");
}
@font-face {
  font-display: swap;
  font-family: "Gilory";
  font-style: normal;
  font-weight: 700;
  src: url("../../../../public/fonts/Gilroy-Bold.otf") format("opentype");
}
@font-face {
  font-display: swap;
  font-family: "Gilory";
  font-style: normal;
  font-weight: 800;
  src: url("../../../../public/fonts/Gilroy-ExtraBold.otf") format("opentype");
}
@font-face {
  font-display: swap;
  font-family: "Gilory";
  font-style: normal;
  font-weight: 900;
  src: url("../../../../public/fonts/Gilroy-HeavyItalic.otf") format("opentype");
}
@font-face {
  font-display: swap;
  font-family: "Gilory";
  font-style: normal;
  font-weight: 500;
  src: url("../../../../public/fonts/Gilroy-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Gilory";
  font-style: normal;
  font-weight: 400;
  src: url("../../../../public/fonts/Gilroy-Regular.otf") format("opentype");
}
@font-face {
  font-display: swap;
  font-family: "Gilory";
  font-style: normal;
  font-weight: 600;
  src: url("../../../../public/fonts/Gilroy-SemiBold.otf") format("opentype");
}
.accent-hero {
  font-family: "BelyDisplay";
  font-size: calc(1.26953125rem + 4.6205357143vw);
  font-weight: 400;
  line-height: 1.0588235294;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .accent-hero {
    font-size: 5.3125rem;
  }
}

.accent-huge {
  font-family: "BelyDisplay";
  font-size: calc(1.1484375rem + 2.5446428571vw);
  font-weight: 400;
  line-height: 1.2037037037;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .accent-huge {
    font-size: 3.375rem;
  }
}

.accent-large {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .accent-large {
    font-size: 2.75rem;
  }
}

.accent-medium {
  font-family: "BelyDisplay";
  font-size: calc(1.02734375rem + 0.46875vw);
  font-weight: 400;
  line-height: 1.4347826087;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .accent-medium {
    font-size: 1.4375rem;
  }
}

.accent-small {
  font-family: "BelyDisplay";
  font-size: calc(1.00390625rem + 0.0669642857vw);
  font-weight: 400;
  line-height: 1.5294117647;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .accent-small {
    font-size: 1.0625rem;
  }
}

.accent-xsmall {
  font-family: "BelyDisplay";
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5384615385;
  letter-spacing: 0em;
}

.body-large-bold {
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
@media (min-width: 1400px) {
  .body-large-bold {
    font-size: 1.25rem;
  }
}

.body-large-regular {
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
@media (min-width: 1400px) {
  .body-large-regular {
    font-size: 1.25rem;
  }
}

.body-medium-bold {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
}

.body-medium-regular {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
}

.body-small-bold {
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
}

.body-small-regular {
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
}

.display-hero-heavy {
  font-family: "Gilory";
  font-size: calc(1.28125rem + 4.8214285714vw);
  font-weight: 900;
  line-height: 1.0227272727;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .display-hero-heavy {
    font-size: 5.5rem;
  }
}

.display-huge-heavy {
  font-family: "Gilory";
  font-size: calc(1.15625rem + 2.6785714286vw);
  font-weight: 900;
  line-height: 1.1607142857;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .display-huge-heavy {
    font-size: 3.5rem;
  }
}

.display-large-heavy {
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .display-large-heavy {
    font-size: 2.875rem;
  }
}

.display-large-extrabold {
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .display-large-extrabold {
    font-size: 2.25rem;
  }
}

.display-large-semibold {
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 600;
  line-height: 1.3333333333;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .display-large-semibold {
    font-size: 2.25rem;
  }
}

.display-medium-extrabold {
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 800;
  line-height: 1.1666666667;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .display-medium-extrabold {
    font-size: 1.5rem;
  }
}

.display-medium-medium {
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 500;
  line-height: 1.4166666667;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .display-medium-medium {
    font-size: 1.5rem;
  }
}

.display-small-extrabold {
  font-family: "Gilory";
  font-size: calc(1.0078125rem + 0.1339285714vw);
  font-weight: 700;
  line-height: 1.2777777778;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .display-small-extrabold {
    font-size: 1.125rem;
  }
}

.display-small-semibold {
  font-family: "Gilory";
  font-size: calc(1.0078125rem + 0.1339285714vw);
  font-weight: 600;
  line-height: 1.3333333333;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .display-small-semibold {
    font-size: 1.125rem;
  }
}

.display-xsmall-extrabold {
  font-family: "Gilory";
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1.3571428571;
  letter-spacing: 0px;
}

.display-xsmall-regular {
  font-family: "Gilory";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: -0.0926099867px;
}

.display-xxsmall-extrabold {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 800;
  line-height: 1.4666666667;
  letter-spacing: 2px;
}

.display-xxsmall-medium {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
}

.label-large-bold {
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
@media (min-width: 1400px) {
  .label-large-bold {
    font-size: 1.25rem;
  }
}

.label-large-regular {
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
@media (min-width: 1400px) {
  .label-large-regular {
    font-size: 1.25rem;
  }
}

.label-medium-bold {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
}

.label-medium-medium {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.02em;
}

.label-small-bold {
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
}

.label-small-medium {
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
}

.label-xsmall-semibold {
  font-family: "Gilory";
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.02em;
}

.label-xsmall-regular {
  font-family: "Gilory";
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.02em;
}

.link-large-bold {
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
@media (min-width: 1400px) {
  .link-large-bold {
    font-size: 1.25rem;
  }
}

.link-large-regular {
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
@media (min-width: 1400px) {
  .link-large-regular {
    font-size: 1.25rem;
  }
}

.link-medium-bold {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
}

.link-medium-regular {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.02em;
}

.link-small-bold {
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
}

.link-small-regular {
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
}

.button__primary--dark {
  padding: 8px 24px;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  transition: all 0.3s linear;
  text-decoration: none;
  position: relative;
  display: inline-block;
  border: solid 1px;
  background-color: #10002F;
  color: #FFFFFF;
  border-color: #10002F;
}
.button__primary--dark::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Schnuppe.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  padding-right: 4px;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.button__primary--dark:hover {
  padding-left: 32px;
  padding-right: 16px;
}
.button__primary--dark:hover::before {
  opacity: 1;
}
.button__primary--dark::before {
  background-color: #FF006E;
}

.button__primary--light {
  padding: 8px 24px;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  transition: all 0.3s linear;
  text-decoration: none;
  position: relative;
  display: inline-block;
  border: solid 1px;
  background-color: #FFFFFF;
  color: #10002F;
  border-color: #FFFFFF;
}
.button__primary--light::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Schnuppe.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  padding-right: 4px;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.button__primary--light:hover {
  padding-left: 32px;
  padding-right: 16px;
}
.button__primary--light:hover::before {
  opacity: 1;
}
.button__primary--light::before {
  background-color: #FF006E;
}

.button__primary--red {
  padding: 8px 24px;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  transition: all 0.3s linear;
  text-decoration: none;
  position: relative;
  display: inline-block;
  border: solid 1px;
  background-color: #FF006E;
  color: #FFFFFF;
  border-color: #FF006E;
}
.button__primary--red::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Schnuppe.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  padding-right: 4px;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.button__primary--red:hover {
  padding-left: 32px;
  padding-right: 16px;
}
.button__primary--red:hover::before {
  opacity: 1;
}
.button__primary--red::before {
  background-color: #FFFFFF;
}

.button__secondary--dark {
  padding: 8px 24px;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  transition: all 0.3s linear;
  text-decoration: none;
  position: relative;
  display: inline-block;
  border: solid 1px;
  background-color: transparent;
  color: #10002F;
  border-color: #10002F;
}
.button__secondary--dark::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Schnuppe.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  padding-right: 4px;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.button__secondary--dark:hover {
  padding-left: 32px;
  padding-right: 16px;
}
.button__secondary--dark:hover::before {
  opacity: 1;
}
.button__secondary--dark::before {
  background-color: #FF006E;
}

.button__secondary--light {
  padding: 8px 24px;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  transition: all 0.3s linear;
  text-decoration: none;
  position: relative;
  display: inline-block;
  border: solid 1px;
  background-color: transparent;
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.button__secondary--light::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Schnuppe.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  padding-right: 4px;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.button__secondary--light:hover {
  padding-left: 32px;
  padding-right: 16px;
}
.button__secondary--light:hover::before {
  opacity: 1;
}
.button__secondary--light::before {
  background-color: #FF006E;
}

.button__secondary--red {
  padding: 8px 24px;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  transition: all 0.3s linear;
  text-decoration: none;
  position: relative;
  display: inline-block;
  border: solid 1px;
  background-color: transparent;
  color: #FF006E;
  border-color: #FF006E;
}
.button__secondary--red::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Schnuppe.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  padding-right: 4px;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.button__secondary--red:hover {
  padding-left: 32px;
  padding-right: 16px;
}
.button__secondary--red:hover::before {
  opacity: 1;
}
.button__secondary--red::before {
  background-color: #FFFFFF;
}

.link--default {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #5E05A8;
  display: flex;
  align-items: center;
  gap: 2px;
  transition: gap 0.3s linear;
  cursor: pointer;
}
.link--default::after {
  content: "";
  mask-image: url("~/public/img/_Icons/arrow-right.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  width: 16px;
  height: 16px;
  display: inline-block;
}
.link--default:hover {
  gap: 8px;
}

.container__inner {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  max-width: 1232px;
  padding-inline: 2.5rem;
}
@media (max-width: 575.98px) {
  .container__inner {
    padding-inline: 1rem;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
}

.footer {
  padding-top: calc(1.25rem + 4.2857142857vw);
  padding-bottom: calc(1.09375rem + 1.6071428571vw);
}
@media (min-width: 1400px) {
  .footer {
    padding-top: 5rem;
  }
}
@media (min-width: 1400px) {
  .footer {
    padding-bottom: 2.5rem;
  }
}
.footer .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.footer .headline__content {
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .footer .headline__content {
    font-size: 2.875rem;
  }
}
.footer .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .footer .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.footer__headline {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  margin-bottom: 8px;
}
.footer__content {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}
.footer__nav {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
}
.footer__nav-item:last-child .footer__nav-link::after {
  display: none;
}
.footer__nav-link {
  text-decoration: none;
}
.footer__nav-link::after {
  content: "|";
  color: #FFBFFF;
  padding-inline: 4px;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.footer__contact-address {
  margin-bottom: 16px;
}
.footer__contact-phone {
  display: flex;
  align-items: center;
}
.footer__contact-phone a {
  color: #FFFFFF;
}
.footer__contact-phone::before {
  margin-right: 6px;
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Call.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFBFFF;
  transform: translate(0px, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}
.footer__contact-email {
  display: flex;
  align-items: center;
}
.footer__contact-email a {
  color: #FFFFFF;
}
.footer__contact-email::before {
  margin-right: 6px;
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Email.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFBFFF;
  transform: translate(0px, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}
.footer__social-media {
  display: flex;
  gap: 7px;
}
.footer__social-media--button {
  background: #FF006E;
  padding: 4px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.footer__social-media--icon {
  filter: brightness(0) invert(1);
}
@media (max-width: 1199.98px) {
  .footer__social-media {
    display: grid;
    grid-template-columns: repeat(2, min-content);
  }
}
@media (max-width: 1199.98px) {
  .footer__social-media {
    display: flex;
  }
}

.copyright {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #10002F;
  display: flex;
  padding: 12px 0;
  justify-content: center;
}
.copyright__text {
  text-align: center;
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}
.copyright__text, .copyright a {
  color: #FFFFFF;
}

.navbar-nav {
  background-color: #FFFFFF;
  display: flex;
  gap: calc(1.03125rem + 0.5357142857vw);
}
@media (min-width: 1400px) {
  .navbar-nav {
    gap: 1.5rem;
  }
}
.navbar-nav .nav-button {
  display: flex;
  align-items: center;
}
.navbar-nav .dropdown-menu {
  margin-top: 0;
  left: -24px;
}
.navbar-nav .dropdown-toggle {
  transition: none;
}
.navbar-nav .dropdown-toggle::after {
  color: #5E05A8;
}
.navbar-nav .dropdown-item {
  font-weight: 500;
  line-height: 1.6;
  padding-inline: 24px;
  padding-block: 12px;
  color: #4C4D6B;
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: gap 0.3s linear, color 0.3s linear;
}
.navbar-nav .dropdown-item:active {
  background-color: #FFFFFF;
  color: #4C4D6B;
}
.navbar-nav .dropdown-item.primary::before {
  background-color: #10002F;
}
.navbar-nav .dropdown-item.secondary::before {
  background-color: #5E05A8;
}
.navbar-nav .dropdown-item.purple::before {
  background-color: #5E05A8;
}
.navbar-nav .dropdown-item.dark-purple::before {
  background-color: #23003E;
}
.navbar-nav .dropdown-item.red::before {
  background-color: #FF006E;
}
.navbar-nav .dropdown-item.gray::before {
  background-color: #7F8095;
}
.navbar-nav .dropdown-item.pink::before {
  background-color: #FC00C4;
}
.navbar-nav .dropdown-item.rose::before {
  background-color: #FFBFFF;
}
.navbar-nav .dropdown-item.blue::before {
  background-color: #00EDD9;
}
.navbar-nav .dropdown-item.green::before {
  background-color: #57FF99;
}
.navbar-nav .dropdown-item.yellow::before {
  background-color: #D9FF00;
}
.navbar-nav .dropdown-item.white::before {
  background-color: #FFFFFF;
}
.navbar-nav .dropdown-item::before {
  content: "";
  display: inline-block;
  height: 12px;
  width: 12px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(0, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}
.navbar-nav .dropdown-item:hover {
  gap: 10px;
  color: #23003E;
}
@media (max-width: 991.98px) {
  .navbar-nav {
    background-color: transparent;
    gap: 0;
  }
  .navbar-nav .dropdown {
    margin-inline: calc(var(--bs-offcanvas-padding-y) * -1);
  }
  .navbar-nav .dropdown-menu, .navbar-nav .dropdown-toggle {
    padding-inline: var(--bs-offcanvas-padding-y);
  }
  .navbar-nav .dropdown-toggle.show {
    background-color: #FFFFFF;
  }
}

.header > .navbar {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}
.header > .navbar .navbar-buttons {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.header > .navbar .navbar-toggler {
  border: 0;
}
@media (max-width: 991.98px) {
  .header > .navbar {
    padding-block: 0.5rem;
  }
}

.header--sticky {
  position: sticky;
  top: 0;
  z-index: 1337;
}
.header .container-fluid, .header .container-sm, .header .container-md, .header .container-lg, .header .container-xl {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media (max-width: 991.98px) {
  .header .container-fluid, .header .container-sm, .header .container-md, .header .container-lg, .header .container-xl {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.offcanvas {
  --bs-offcanvas-bg: #FFFFFF;
  --bs-offcanvas-width: 100vw;
  --bs-offcanvas-height: 100vh;
  height: 100svh;
  transition: opacity 0.1s ease-in-out;
}
.offcanvas-header {
  justify-content: space-between;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  background-color: #FFFFFF;
  padding: 8px 24px;
}
.offcanvas-header .btn-close {
  margin-right: -2px;
}
@media (max-width: 991.98px) {
  .offcanvas {
    opacity: 0;
  }
  .offcanvas.show {
    opacity: 1;
  }
  .offcanvas-body {
    background-color: #F8F8F9;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-header {
    padding-inline: 1rem;
  }
}

.offcanvas-backdrop {
  display: none;
}

.text-block {
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
@media (min-width: 1400px) {
  .text-block {
    font-size: 1.25rem;
  }
}

textarea.form-control {
  min-height: 7.5rem;
}

.form-control, .form-select {
  padding: 12px;
}

.form-control::placeholder, .form-select::placeholder {
  color: gray;
  font-size: 15px;
}
.form-control:focus, .form-select:focus {
  box-shadow: 0 0 0 0.1rem #5E05A8;
}

.form-select option[value=""] {
  color: #F8F8F9;
  display: none;
}

label[for=dynamic_form1_name],
input#dynamic_form1_name {
  display: none;
}

.col-12:has(input#dynamic_form1_name) {
  margin: 0;
}

.col-12:has(input[type=email].form-control:not([id*=email])) {
  display: none;
  margin: 0;
}

label[for=data-privacy] {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #7F8095;
}

.data-privacy {
  padding-left: 1.5rem;
  position: relative;
}
.data-privacy input[type=checkbox] {
  position: absolute;
  top: 0.5rem;
  left: 0;
}

.submit {
  margin-top: 1rem;
}

.section--default .section__inner {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  max-width: 1232px;
  padding-inline: 2.5rem;
}
@media (max-width: 991.98px) {
  .section--default .section__inner {
    padding-inline: 1rem;
  }
}
.section--fullwidht .section__inner {
  width: 100%;
}
.section--dark {
  background-color: #10002F;
  color: #FFFFFF;
}
.section--light {
  background-color: #FFFFFF;
  color: #23003E;
}
.section--gray {
  background-color: #F8F8F9;
  color: #10002F;
}
.section--purple {
  background-color: #5E05A8;
  color: #FFFFFF;
}
.section--spacing-y {
  padding-block: calc(1.328125rem + 5.625vw);
}
@media (min-width: 1400px) {
  .section--spacing-y {
    padding-block: 6.25rem;
  }
}
.section--spacing-t {
  padding-top: calc(1.328125rem + 5.625vw);
}
@media (min-width: 1400px) {
  .section--spacing-t {
    padding-top: 6.25rem;
  }
}
.section--spacing-b {
  padding-bottom: calc(1.328125rem + 5.625vw);
}
@media (min-width: 1400px) {
  .section--spacing-b {
    padding-bottom: 6.25rem;
  }
}
.section--spacing-none {
  padding-block: 0;
}

.swiper .swiper-slide {
  height: auto;
}
.swiper .swiper-pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.swiper .swiper-pagination-bullet {
  position: relative;
  margin: 0.1rem;
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #7F8095;
  transform: translate(0px, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}
.swiper .swiper-pagination-bullet-active {
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(0px, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}

.accordion-item {
  border-top: solid 1px #E5E5EA;
  border-bottom: solid 1px #E5E5EA;
  border-left: 0;
  border-right: 0;
}
.accordion-item .accordion-button {
  font-family: "Gilory";
  font-size: calc(1.0078125rem + 0.1339285714vw);
  font-weight: 700;
  line-height: 1.2777777778;
  letter-spacing: 0px;
  padding-inline: 0;
}
@media (min-width: 1400px) {
  .accordion-item .accordion-button {
    font-size: 1.125rem;
  }
}
.accordion-item .accordion-button:after {
  background-image: none;
  mask-image: url("~/public/img/_Icons/Chevron-Down.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFBFFF;
}
.accordion-item:last-of-type {
  border-bottom: 0;
}

.headline--primary .headline__title,
.headline--primary .headline__accent {
  color: #10002F;
}
.headline--secondary .headline__title,
.headline--secondary .headline__accent {
  color: #5E05A8;
}
.headline--purple .headline__title,
.headline--purple .headline__accent {
  color: #5E05A8;
}
.headline--dark-purple .headline__title,
.headline--dark-purple .headline__accent {
  color: #23003E;
}
.headline--red .headline__title,
.headline--red .headline__accent {
  color: #FF006E;
}
.headline--gray .headline__title,
.headline--gray .headline__accent {
  color: #7F8095;
}
.headline--pink .headline__title,
.headline--pink .headline__accent {
  color: #FC00C4;
}
.headline--rose .headline__title,
.headline--rose .headline__accent {
  color: #FFBFFF;
}
.headline--blue .headline__title,
.headline--blue .headline__accent {
  color: #00EDD9;
}
.headline--green .headline__title,
.headline--green .headline__accent {
  color: #57FF99;
}
.headline--yellow .headline__title,
.headline--yellow .headline__accent {
  color: #D9FF00;
}
.headline--white .headline__title,
.headline--white .headline__accent {
  color: #FFFFFF;
}

.image--default {
  max-width: 100%;
}

.home-hero__headline-container {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-hero__headline-container .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.home-hero__headline-container .headline__content {
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.28125rem + 4.8214285714vw);
  font-weight: 900;
  line-height: 1.0227272727;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .home-hero__headline-container .headline__content {
    font-size: 5.5rem;
  }
}
.home-hero__headline-container .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.26953125rem + 4.6205357143vw);
  font-weight: 400;
  line-height: 1.0588235294;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .home-hero__headline-container .headline__content .headline__accent {
    font-size: 5.3125rem;
  }
}
.home-hero__headline-container .headline__content::after {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FF006E;
  transform: translate(4px, 9px);
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}

.home-hero__grid-gallery {
  max-width: 1800px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(25, 1fr);
  grid-template-rows: repeat(23, clamp(30px, 3vw, 55px));
}
.home-hero__grid-gallery .image {
  position: relative;
  z-index: 1;
}
.home-hero__grid-gallery .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.5);
}
.home-hero__grid-gallery .image--1 {
  grid-column: 2/span 5;
  grid-row: 1/span 10;
  position: relative;
}
.home-hero__grid-gallery .image--1::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  mask-image: url("~/public/img/_Icons/_Grafiken/Körper&Bewegung/Grafiken-Körper&Bewegung-4.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #00EDD9;
  height: clamp(100px, 15vw, 237px);
  width: clamp(100px, 15vw, 237px);
  transform: rotate(90deg) translate(-8%, 19%);
  z-index: 2;
  aspect-ratio: 1;
}
.home-hero__grid-gallery .image--2 {
  grid-column: 18/span 7;
  grid-row: 1/span 15;
}
.home-hero__grid-gallery .image--2::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  mask-image: url("~/public/img/_Icons/_Grafiken/Licht/Grafiken-Licht-1.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FC00C4;
  height: clamp(100px, 15vw, 217px);
  width: clamp(100px, 15vw, 217px);
  transform: rotate(90deg) translate(20%, 17%);
  z-index: 1;
  aspect-ratio: 1;
}
.home-hero__grid-gallery .image--2::after {
  content: "";
  position: absolute;
  display: block;
  top: auto;
  left: 0;
  right: auto;
  bottom: 0;
  mask-image: url("~/public/img/_Icons/_Grafiken/Körper&Bewegung/Grafiken-Körper&Bewegung-2.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #57FF99;
  height: clamp(100px, 15vw, 267px);
  width: clamp(100px, 15vw, 267px);
  transform: rotate(-180deg) translate(30%, -15%);
  z-index: 1;
  aspect-ratio: 1;
}
.home-hero__grid-gallery .image--3 {
  grid-column: 5/span 17;
  grid-row: 13/span 6;
}
.home-hero__grid-gallery .image--3::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  mask-image: url("~/public/img/_Icons/_Grafiken/Körper&Bewegung/Grafiken-Körper&Bewegung-3.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #D9FF00;
  height: clamp(100px, 15vw, 290px);
  width: clamp(100px, 15vw, 290px);
  transform: rotate(0) translate(34%, -68%);
  z-index: 1;
  aspect-ratio: 1;
}
.home-hero__grid-gallery .image--3::after {
  content: "";
  position: absolute;
  display: block;
  top: auto;
  left: 0;
  right: auto;
  bottom: 0;
  mask-image: url("~/public/img/_Icons/_Grafiken/Ton/Grafiken-Ton-1.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #57FF99;
  height: clamp(100px, 15vw, 303px);
  width: clamp(100px, 15vw, 303px);
  transform: rotate(-90deg) translate(-66%, -88%);
  z-index: -1;
  aspect-ratio: 1;
}
.home-hero__grid-gallery .image--4 {
  grid-column: 23/span 3;
  grid-row: 10/span 13;
}
.home-hero__grid-gallery .grid-gallery__video {
  grid-column: 8/span 11;
  grid-row: 3/span 18;
  z-index: 99;
  position: relative;
}
.home-hero__grid-gallery .grid-gallery__video .scale-container {
  position: absolute;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  min-width: 100%;
  max-width: 100vw;
  max-height: 100vh;
}
.home-hero__grid-gallery .grid-gallery__video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  mask-image: url("~/public/img/_Masks/video_mask.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: center right;
}
@media (max-width: 991.98px) {
  .home-hero .image.image--1 {
    grid-column: 2/span 7;
    grid-row: 1/span 11;
  }
  .home-hero .image.image--3 {
    grid-column: 3/span 19;
  }
  .home-hero .grid-gallery__video {
    grid-column: 6/span 14;
    grid-row: 2/span 20;
  }
}
@media (max-width: 575.98px) {
  .home-hero .image.image--1 {
    grid-row: 3/span 11;
  }
  .home-hero .image.image.image--2 {
    grid-row: 3/span 15;
  }
  .home-hero .image.image--3 {
    grid-row: 16/span 6;
  }
  .home-hero .image.image--4 {
    grid-row: 13/span 13;
  }
  .home-hero .grid-gallery__video {
    grid-column: 3/span 21;
    grid-row: 1/span 20;
  }
}

.home-hero__outro {
  width: clamp(80%, 60vw, 992px);
  height: 100vh;
  height: 100svh;
  overflow: hidden;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
}
.home-hero__outro .logo img {
  height: 112px;
  margin-bottom: 2.5rem;
  max-width: 100%;
}
.home-hero__outro .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.home-hero__outro .headline__content {
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.15625rem + 2.6785714286vw);
  font-weight: 900;
  line-height: 1.1607142857;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .home-hero__outro .headline__content {
    font-size: 3.5rem;
  }
}
.home-hero__outro .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.1484375rem + 2.5446428571vw);
  font-weight: 400;
  line-height: 1.2037037037;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .home-hero__outro .headline__content .headline__accent {
    font-size: 3.375rem;
  }
}
.home-hero__outro .headline__content::after {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FF006E;
  transform: translate(4px, 9px);
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}
.home-hero__outro .scroll-down {
  opacity: 0;
  bottom: 0;
  transform: translateY(-50px);
  position: absolute;
}
.home-hero__outro .logo {
  opacity: 0;
  transform: scale(2) translateY(-100%);
}
.home-hero__outro .headline {
  opacity: 0;
  transform: translateY(100%);
}

.teaser--text-image__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 7.625rem;
}
.teaser--text-image__content {
  color: #FFFFFF;
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  position: relative;
  align-self: center;
  border-top: solid 1px #FC00C4;
  border-bottom: solid 1px #FC00C4;
  position: relative;
}
.teaser--text-image__content::before, .teaser--text-image__content::after {
  background-color: #FC00C4;
}
.teaser--text-image__content::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FC00C4;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: 50%;
  right: auto;
}
.teaser--text-image__content__header .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.teaser--text-image__content__header .headline__content {
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .teaser--text-image__content__header .headline__content {
    font-size: 2.875rem;
  }
}
.teaser--text-image__content__header .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .teaser--text-image__content__header .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.teaser--text-image__content__body {
  margin: 1.5rem 0;
}
@media (max-width: 1199.98px) {
  .teaser--text-image__container {
    column-gap: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .teaser--text-image__container {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
  }
}

.image-sticker__container {
  position: relative;
}
.image-sticker__sticker {
  position: absolute;
}
.image-sticker__sticker--top_left {
  top: 0;
  left: 0;
  transform: translate(-25%, -35%) rotate(-20deg);
}
.image-sticker__sticker--top_right {
  top: 0;
  right: 0;
}
.image-sticker__sticker--center_left {
  top: 50%;
  left: 0;
}
.image-sticker__sticker--center_right {
  top: 50%;
  right: 0;
}
.image-sticker__sticker--bottom_left {
  bottom: 0;
  left: 0;
}
.image-sticker__sticker--bottom_right {
  bottom: 0;
  right: 0;
}
.image-sticker__image {
  object-fit: cover;
  width: 100%;
  max-height: 700px;
}
@media (max-width: 991.98px) {
  .image-sticker__image {
    max-height: 600px;
  }
}
@media (max-width: 575.98px) {
  .image-sticker {
    width: auto;
    max-height: unset;
  }
}

.social-banner {
  max-width: 1200px;
  margin-inline: auto;
  position: relative;
}
.social-banner__container-inner {
  padding-block: calc(1.34375rem + 5.8928571429vw);
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  max-width: 1232px;
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 1400px) {
  .social-banner__container-inner {
    padding-block: 6.5rem;
  }
}
.social-banner__background-graphic {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.social-banner__background-graphic .image, .social-banner__background-graphic img {
  height: 100%;
}
.social-banner__container {
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  display: grid;
  grid-template-columns: auto 3rem auto;
  z-index: 2;
}
.social-banner__background-image {
  grid-column: 1/span 2;
  grid-row: 1;
}
.social-banner__background-image .image, .social-banner__background-image img {
  height: 100%;
}
.social-banner__background-image img {
  object-fit: cover;
}
.social-banner__card {
  background-color: #FFFFFF;
  padding: 3.5rem 2.5rem;
  grid-column: 2/span 2;
  grid-row: 1;
  align-self: center;
  justify-self: flex-end;
  min-width: 24.5rem;
  text-align: center;
  display: grid;
  gap: 1.5rem;
  position: relative;
  margin-block: 3rem;
}
.social-banner__card .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.social-banner__card .headline__content {
  color: #10002F;
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 800;
  line-height: 1.1666666667;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .social-banner__card .headline__content {
    font-size: 1.5rem;
  }
}
.social-banner__card .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.02734375rem + 0.46875vw);
  font-weight: 400;
  line-height: 1.4347826087;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .social-banner__card .headline__content .headline__accent {
    font-size: 1.4375rem;
  }
}
.social-banner__card::after {
  content: "";
  display: inline-block;
  height: 32px;
  width: 32px;
  mask-image: url("~/public/img/_Icons/Star-Half.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFFFFF;
  transform: translate(-100%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 0;
  right: auto;
}
.social-banner__card__icons {
  list-style: none;
  display: flex;
  margin-bottom: 0;
  justify-content: center;
  padding-left: 0;
}
.social-banner__card__icons__item {
  padding-right: 0.5rem;
}
.social-banner__card__icons__item:last-child {
  padding-right: 0;
}
.social-banner__card__icons__item__link {
  background-color: #FC00C4;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  height: 40px;
  width: 40px;
}
.social-banner__card__icons__item__link__icon {
  filter: brightness(0) invert(1);
}
@media (max-width: 991.98px) {
  .social-banner__container-inner {
    padding-block: 0;
  }
  .social-banner__background-image {
    display: none;
  }
  .social-banner__container {
    grid-template-columns: 1fr;
    padding-inline: 0;
  }
  .social-banner__card {
    grid-column: 1;
    min-width: auto;
  }
}

.card {
  background-color: #FFFFFF;
  padding: 32px;
  border: 0;
}
.card__body {
  display: flex;
  flex-direction: column;
}
.card__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #5E05A8;
  margin-bottom: 8px;
}
.card__headline {
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
  margin-bottom: 24px;
}
@media (min-width: 1400px) {
  .card__headline {
    font-size: 2.25rem;
  }
}
.card__link {
  align-self: flex-end;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #5E05A8;
  display: flex;
  align-items: center;
  gap: 2px;
  transition: gap 0.3s linear;
  cursor: pointer;
}
.card__link::after {
  content: "";
  mask-image: url("~/public/img/_Icons/arrow-right.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  width: 16px;
  height: 16px;
  display: inline-block;
}
.card__link:hover {
  gap: 8px;
}

.page-teaser-banner {
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1;
  box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.0784313725) inset;
}
.page-teaser-banner .image {
  grid-column: 1;
  grid-row: 1;
}
.page-teaser-banner .image img {
  width: 100%;
  object-fit: cover;
}
.page-teaser-banner .container {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.page-teaser-banner .container .card {
  min-width: 378px;
  max-width: 440px;
}
.page-teaser-banner .container .card::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: auto;
}
@media (max-width: 991.98px) {
  .page-teaser-banner .container .card {
    min-width: auto;
  }
}

.contact-banner {
  background-color: #FC00C4;
  position: relative;
}
.contact-banner .container {
  position: relative;
  z-index: 2;
  padding-top: 80px;
  padding-bottom: 80px;
}
.contact-banner .container::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: auto;
  right: 0;
  bottom: auto;
  mask-image: url("~/public/img/_Icons/_Grafiken/Licht/Grafiken-Licht-3.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFBFFF;
  height: 100%;
  width: auto;
  transform: rotate(180deg) translate(-25%, 0px);
  z-index: -1;
  aspect-ratio: 1;
}
.contact-banner .headline {
  color: #FFFFFF;
}
.contact-banner .headline .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.contact-banner .headline .headline__content {
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .contact-banner .headline .headline__content {
    font-size: 2.875rem;
  }
}
.contact-banner .headline .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .contact-banner .headline .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.contact-banner .image {
  position: relative;
}
.contact-banner__button {
  --padding-x: 40px;
  --padding-y: 16px;
  --margin-top: 24px;
  margin-top: var(--margin-top);
  padding: var(--padding-y) var(--padding-x);
  display: inline-flex;
  align-items: center;
  gap: var(--padding-x);
  background-color: #FFFFFF;
  position: relative;
}
.contact-banner__button::after {
  content: "";
  display: inline-block;
  height: 24px;
  width: 24px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFFFFF;
  transform: translate(0px, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: var(--padding-x);
  right: auto;
}
.contact-banner__button-label {
  font-family: "Gilory";
  font-size: calc(1.0078125rem + 0.1339285714vw);
  font-weight: 600;
  line-height: 1.3333333333;
  letter-spacing: 0px;
  color: #23003E;
}
@media (min-width: 1400px) {
  .contact-banner__button-label {
    font-size: 1.125rem;
  }
}
@media (max-width: 991.98px) {
  .contact-banner .container::after {
    transform: rotate(-90deg) translate(0, 0px);
    height: 250px;
    width: auto;
    top: auto;
    bottom: 0;
  }
  .contact-banner__button {
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
  }
}

.card-services .headline {
  text-align: center;
}
.card-services .headline .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.card-services .headline .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .card-services .headline .headline__content {
    font-size: 2.875rem;
  }
}
.card-services .headline .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .card-services .headline .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.card-services .headline__content {
  color: #FFFFFF;
  margin-bottom: 32px;
}
.card-services__slider {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  scrollbar-width: none;
  height: 100vh;
  height: 100svh;
  max-height: 100vh;
  max-height: 100svh;
}
.card-services__item {
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-row: 1;
  min-width: 30vw;
  height: 100vh;
  height: 100svh;
  max-height: 100vh;
  max-height: 100svh;
}
.card-services__image {
  grid-column: 1;
  grid-row: 1/-1;
  display: flex;
  grid-template-columns: 1;
  align-items: stretch;
}
.card-services__image .image {
  flex: 1;
}
.card-services__image .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card-services__card {
  grid-column: 1;
  grid-row: 5;
  align-self: flex-end;
  background-color: #FFFFFF;
  margin: 16px;
  padding: 24px;
  height: 200px;
  align-self: stretch;
  position: relative;
  display: flex;
  flex-direction: column;
}
.card-services__card.primary::after {
  background-color: #10002F;
}
.card-services__card.secondary::after {
  background-color: #5E05A8;
}
.card-services__card.purple::after {
  background-color: #5E05A8;
}
.card-services__card.dark-purple::after {
  background-color: #23003E;
}
.card-services__card.red::after {
  background-color: #FF006E;
}
.card-services__card.gray::after {
  background-color: #7F8095;
}
.card-services__card.pink::after {
  background-color: #FC00C4;
}
.card-services__card.rose::after {
  background-color: #FFBFFF;
}
.card-services__card.blue::after {
  background-color: #00EDD9;
}
.card-services__card.green::after {
  background-color: #57FF99;
}
.card-services__card.yellow::after {
  background-color: #D9FF00;
}
.card-services__card.white::after {
  background-color: #FFFFFF;
}
.card-services__card::after {
  content: "";
  display: inline-block;
  height: 24px;
  width: 24px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(0, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: 24px;
  right: auto;
}
.card-services__card-title {
  font-family: "Gilory";
  font-size: calc(1.0078125rem + 0.1339285714vw);
  font-weight: 700;
  line-height: 1.2777777778;
  letter-spacing: 0px;
  color: #23003E;
  margin-bottom: 2px;
}
@media (min-width: 1400px) {
  .card-services__card-title {
    font-size: 1.125rem;
  }
}
.card-services__card-description {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #7F8095;
  max-height: 180px;
  flex: 1;
}
.card-services__card-link {
  display: flex;
  justify-content: flex-end;
}
.card-services__card-link a {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #5E05A8;
  display: flex;
  align-items: center;
  gap: 2px;
  transition: gap 0.3s linear;
  cursor: pointer;
}
.card-services__card-link a::after {
  content: "";
  mask-image: url("~/public/img/_Icons/arrow-right.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  width: 16px;
  height: 16px;
  display: inline-block;
}
.card-services__card-link a:hover {
  gap: 8px;
}
@media (max-width: 991.98px) {
  .card-services__item {
    min-width: 80vw;
  }
}
@media (max-width: 575.98px) {
  .card-services__item {
    min-width: 100vw;
  }
}

.card-services-detail .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.card-services-detail .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .card-services-detail .headline__content {
    font-size: 2.875rem;
  }
}
.card-services-detail .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .card-services-detail .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.card-services-detail .headline {
  margin-bottom: 1.5rem;
}
.card-services-detail .swiper-slide {
  display: flex;
}
.card-services-detail__card {
  grid-column: 1;
  grid-row: 5;
  align-self: flex-end;
  background-color: #FFFFFF;
  margin-top: 16px;
  padding: 24px;
  align-self: stretch;
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.card-services-detail__card.primary::after {
  background-color: #10002F;
}
.card-services-detail__card.secondary::after {
  background-color: #5E05A8;
}
.card-services-detail__card.purple::after {
  background-color: #5E05A8;
}
.card-services-detail__card.dark-purple::after {
  background-color: #23003E;
}
.card-services-detail__card.red::after {
  background-color: #FF006E;
}
.card-services-detail__card.gray::after {
  background-color: #7F8095;
}
.card-services-detail__card.pink::after {
  background-color: #FC00C4;
}
.card-services-detail__card.rose::after {
  background-color: #FFBFFF;
}
.card-services-detail__card.blue::after {
  background-color: #00EDD9;
}
.card-services-detail__card.green::after {
  background-color: #57FF99;
}
.card-services-detail__card.yellow::after {
  background-color: #D9FF00;
}
.card-services-detail__card.white::after {
  background-color: #FFFFFF;
}
.card-services-detail__card::after {
  content: "";
  display: inline-block;
  height: 24px;
  width: 24px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(0, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: 24px;
  right: auto;
}
.card-services-detail__card-title {
  font-family: "Gilory";
  font-size: calc(1.0078125rem + 0.1339285714vw);
  font-weight: 700;
  line-height: 1.2777777778;
  letter-spacing: 0px;
  color: #23003E;
  margin-bottom: 2px;
}
@media (min-width: 1400px) {
  .card-services-detail__card-title {
    font-size: 1.125rem;
  }
}
.card-services-detail__card-description {
  color: #7F8095;
  max-height: 180px;
  flex: 1;
}
.card-services-detail__card-description p {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.card-services-detail__card-link {
  display: flex;
  flex: 1;
  margin-top: 1rem;
  justify-content: flex-end;
  align-items: flex-end;
}
.card-services-detail__card-link a {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #5E05A8;
  display: flex;
  align-items: center;
  gap: 2px;
  transition: gap 0.3s linear;
  cursor: pointer;
}
.card-services-detail__card-link a::after {
  content: "";
  mask-image: url("~/public/img/_Icons/arrow-right.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  width: 16px;
  height: 16px;
  display: inline-block;
}
.card-services-detail__card-link a:hover {
  gap: 8px;
}
@media (max-width: 991.98px) {
  .card-services-detail {
    margin-right: -1rem;
  }
}

.card-usp {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: calc(1.03125rem + 0.5357142857vw);
}
@media (min-width: 1400px) {
  .card-usp {
    gap: 1.5rem;
  }
}
.card-usp__intro {
  grid-column: 1/-1;
  grid-row: 1;
  text-align: center;
  padding-inline: calc(1.1328125rem + 2.2767857143vw);
  padding-bottom: calc(1.1328125rem + 2.2767857143vw);
}
.card-usp__intro .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.card-usp__intro .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .card-usp__intro .headline__content {
    font-size: 2.875rem;
  }
}
.card-usp__intro .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .card-usp__intro .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
@media (min-width: 1400px) {
  .card-usp__intro {
    padding-inline: 3.125rem;
  }
}
@media (min-width: 1400px) {
  .card-usp__intro {
    padding-bottom: 3.125rem;
  }
}
.card-usp__item {
  background-color: #FFFFFF;
  border-top: solid 1px #FF006E;
  position: relative;
  padding: 1rem;
  height: 100%;
}
.card-usp__item .headline__content {
  font-family: "Gilory";
  font-size: calc(1.0078125rem + 0.1339285714vw);
  font-weight: 600;
  line-height: 1.3333333333;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .card-usp__item .headline__content {
    font-size: 1.125rem;
  }
}
.card-usp__item .text-block {
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
}
.card-usp__item::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FF006E;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: 50%;
  right: auto;
}
@media (max-width: 575.98px) {
  .card-usp {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
  }
}

.content-text-with-image {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: calc(1.4140625rem + 7.0982142857vw);
}
@media (min-width: 1400px) {
  .content-text-with-image {
    column-gap: 7.625rem;
  }
}
.content-text-with-image--right .content-text-with-image__content {
  grid-column: 1;
  grid-row: 1;
}
.content-text-with-image--right .content-text-with-image__image {
  grid-column: 2;
  grid-row: 1;
}
.content-text-with-image--left .content-text-with-image__content {
  grid-column: 2;
  grid-row: 1;
}
.content-text-with-image--left .content-text-with-image__image {
  grid-column: 1;
  grid-row: 1;
}
.content-text-with-image__content {
  position: relative;
  align-self: center;
}
.content-text-with-image__content .headline {
  position: relative;
  border-bottom: solid 1px #E5E5EA;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.content-text-with-image__content .headline::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #E5E5EA;
  transform: translate(-50%, 50%);
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  right: auto;
}
.content-text-with-image__content .headline__content {
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .content-text-with-image__content .headline__content {
    font-size: 2.25rem;
  }
}
.content-text-with-image__content .text-block {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.content-text-with-image__content .url {
  margin-top: 1rem;
}
.content-text-with-image__image {
  align-self: center;
  aspect-ratio: 1;
  -webkit-box-shadow: 4px 4px 15px 5px rgba(0, 0, 0, 0.37);
  box-shadow: 4px 4px 15px 5px rgba(0, 0, 0, 0.37);
}
.content-text-with-image__video {
  align-self: center;
  aspect-ratio: 1;
  -webkit-box-shadow: 4px 4px 15px 5px rgba(0, 0, 0, 0.37);
  box-shadow: 4px 4px 15px 5px rgba(0, 0, 0, 0.37);
}
.content-text-with-image__video .video,
.content-text-with-image__video .video video {
  height: 100%;
}
.content-text-with-image__video video {
  object-fit: cover;
}
@media (max-width: 991.98px) {
  .content-text-with-image {
    column-gap: calc(1.15625rem + 2.6785714286vw);
  }
}
@media (max-width: 991.98px) and (min-width: 1400px) {
  .content-text-with-image {
    column-gap: 3.5rem;
  }
}
@media (max-width: 575.98px) {
  .content-text-with-image {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    row-gap: calc(1.15625rem + 2.6785714286vw);
  }
}
@media (max-width: 575.98px) and (min-width: 1400px) {
  .content-text-with-image {
    row-gap: 3.5rem;
  }
}
@media (max-width: 575.98px) {
  .content-text-with-image .sticker {
    display: none;
  }
  .content-text-with-image--right .content-text-with-image__content {
    grid-column: 1;
    grid-row: 2;
  }
  .content-text-with-image--right .content-text-with-image__image {
    grid-column: 1;
    grid-row: 1;
  }
  .content-text-with-image--left .content-text-with-image__content {
    grid-column: 1;
    grid-row: 2;
  }
  .content-text-with-image--left .content-text-with-image__image {
    grid-column: 1;
    grid-row: 1;
  }
}

.content-faq__header {
  text-align: center;
  margin-bottom: 1.5rem;
}
.content-faq__header .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.content-faq__header .headline__content {
  color: inherit;
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 800;
  line-height: 1.1666666667;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .content-faq__header .headline__content {
    font-size: 1.5rem;
  }
}
.content-faq__header .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.02734375rem + 0.46875vw);
  font-weight: 400;
  line-height: 1.4347826087;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .content-faq__header .headline__content .headline__accent {
    font-size: 1.4375rem;
  }
}
.content-faq__header .headline__title {
  color: #5E05A8;
}

.content-seo__header {
  text-align: center;
  grid-column: 1/-1;
  margin-bottom: calc(1.03125rem + 0.5357142857vw);
}
.content-seo__header .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.content-seo__header .headline__content {
  color: inherit;
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 800;
  line-height: 1.1666666667;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .content-seo__header .headline__content {
    font-size: 1.5rem;
  }
}
.content-seo__header .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.02734375rem + 0.46875vw);
  font-weight: 400;
  line-height: 1.4347826087;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .content-seo__header .headline__content .headline__accent {
    font-size: 1.4375rem;
  }
}
@media (min-width: 1400px) {
  .content-seo__header {
    margin-bottom: 1.5rem;
  }
}
.content-seo__body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: calc(1.3125rem + 5.3571428571vw);
  column-gap: calc(1.03125rem + 0.5357142857vw);
}
@media (min-width: 1400px) {
  .content-seo__body {
    row-gap: 6rem;
  }
}
@media (min-width: 1400px) {
  .content-seo__body {
    column-gap: 1.5rem;
  }
}
.content-seo__item {
  border-top: solid 1px #7F8095;
  padding-top: 1rem;
}
.content-seo__item .headline .headline__content {
  font-family: "Gilory";
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1.3571428571;
  letter-spacing: 0px;
}
.content-seo__item .text-block {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.content-seo__item:nth-of-type(3n) {
  text-align: center;
  grid-column: span 2;
  max-width: 80%;
  justify-self: center;
  padding-block: 1.5rem;
  border-top: solid 1px #B2B3BF;
  border-bottom: solid 1px #B2B3BF;
  position: relative;
}
.content-seo__item:nth-of-type(3n)::before, .content-seo__item:nth-of-type(3n)::after {
  background-color: #B2B3BF;
}
.content-seo__item:nth-of-type(3n)::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #B2B3BF;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: 50%;
  right: auto;
}
@media (max-width: 575.98px) {
  .content-seo__body {
    grid-template-columns: 1fr;
  }
  .content-seo__item:nth-of-type(3n) {
    grid-column: 1;
  }
}

.content-usp {
  background-color: #5E05A8;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}
.content-usp__right-bg {
  grid-column: 2;
  grid-row: 1;
  background-image: url("~/public/img/_Background/warum-darum.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.content-usp .container__inner {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  max-width: 1232px;
  padding-inline: 2.5rem;
  grid-column: 1/-1;
  grid-row: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: calc(1.375rem + 6.4285714286vw);
}
@media (min-width: 1400px) {
  .content-usp .container__inner {
    gap: 7rem;
  }
}
.content-usp__why {
  display: flex;
  align-items: center;
}
.content-usp__why .text-block {
  margin-block: 100px;
  padding-block: 3rem;
  border-top: 1px solid #FF006E;
  border-bottom: 1px solid #FF006E;
  color: #FFFFFF;
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
.content-usp__why .text-block .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.content-usp__why .text-block .headline__content {
  color: "medium";
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .content-usp__why .text-block .headline__content {
    font-size: 2.875rem;
  }
}
.content-usp__why .text-block .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .content-usp__why .text-block .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
@media (min-width: 1400px) {
  .content-usp__why .text-block {
    font-size: 1.25rem;
  }
}
.content-usp__therefore {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.content-usp__card {
  color: #5E05A8;
  background-color: #FFFFFF;
  padding: 1.5rem;
  justify-self: flex-end;
  min-width: 24.5rem;
  position: relative;
}
.content-usp__card .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.content-usp__card .headline__content {
  color: "$primary-purple";
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .content-usp__card .headline__content {
    font-size: 2.875rem;
  }
}
.content-usp__card .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .content-usp__card .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.content-usp__card::after {
  content: "";
  display: inline-block;
  height: 32px;
  width: 32px;
  mask-image: url("~/public/img/_Icons/Star-Half.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFFFFF;
  transform: translate(-100%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 0;
  right: auto;
}
.content-usp__card__icons {
  list-style: none;
  display: flex;
  margin-bottom: 0;
  justify-content: center;
  padding-left: 0;
}
.content-usp__card__icons__item {
  padding-right: 0.5rem;
}
.content-usp__card__icons__item:last-child {
  padding-right: 0;
}
.content-usp__card__icons__item__link {
  background-color: #FC00C4;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  height: 40px;
  width: 40px;
}
.content-usp__card__icons__item__link__icon {
  filter: brightness(0) invert(1);
}
@media (max-width: 991.98px) {
  .content-usp {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0;
  }
  .content-usp .container__inner {
    gap: 0;
    grid-row: 1/-1;
    padding-inline: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .content-usp__right-bg {
    grid-row: 2;
    grid-column: 1;
  }
  .content-usp__why {
    grid-row: 1;
  }
  .content-usp__why .text-block {
    margin-block: calc(1.0625rem + 1.0714285714vw);
  }
}
@media (max-width: 991.98px) and (min-width: 1400px) {
  .content-usp__why .text-block {
    margin-block: 2rem;
  }
}
@media (max-width: 991.98px) {
  .content-usp__therefore {
    grid-row: 2;
  }
  .content-usp__card {
    min-width: auto;
  }
}
@media (max-width: 991.98px) {
  .content-usp__card {
    max-width: 420px;
  }
}

.content-method {
  position: relative;
  display: grid;
  gap: calc(1.25rem + 4.2857142857vw);
  grid-template-columns: clamp(300px, 490px, 50%) 3fr;
  border-top: solid 1px #E5E5EA;
  border-bottom: solid 1px #E5E5EA;
  padding-block: calc(1.1328125rem + 2.2767857143vw);
}
@media (min-width: 1400px) {
  .content-method {
    gap: 5rem;
  }
}
@media (min-width: 1400px) {
  .content-method {
    padding-block: 3.125rem;
  }
}
.content-method__headline {
  position: relative;
}
.content-method__headline .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.content-method__headline .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .content-method__headline .headline__content {
    font-size: 2.875rem;
  }
}
.content-method__headline .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .content-method__headline .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.content-method__content p {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
@media (max-width: 575.98px) {
  .content-method {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    gap: 1rem;
  }
  .content-method__headline {
    grid-row: 1;
  }
  .content-method__content {
    grid-row: 2;
  }
}

@keyframes move-words {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
.cta-contact-with-text__header .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.cta-contact-with-text__header .headline__content {
  color: #23003E;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.15625rem + 2.6785714286vw);
  font-weight: 900;
  line-height: 1.1607142857;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .cta-contact-with-text__header .headline__content {
    font-size: 3.5rem;
  }
}
.cta-contact-with-text__header .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.1484375rem + 2.5446428571vw);
  font-weight: 400;
  line-height: 1.2037037037;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .cta-contact-with-text__header .headline__content .headline__accent {
    font-size: 3.375rem;
  }
}
.cta-contact-with-text__header .headline {
  background-color: #D9FF00;
  white-space: nowrap;
}
.cta-contact-with-text__header .headline__content {
  position: relative;
  animation: move-words 20s linear infinite;
  margin: 0;
}
.cta-contact-with-text__inner {
  padding-top: 3rem;
}
.cta-contact-with-text__content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.cta-contact-big {
  background-color: #FC00C4;
  position: relative;
}
.cta-contact-big .container {
  position: relative;
  z-index: 2;
  padding-top: 80px;
  padding-bottom: 80px;
}
.cta-contact-big .container::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: auto;
  right: 0;
  bottom: auto;
  mask-image: url("~/public/img/_Icons/_Grafiken/Licht/Grafiken-Licht-3.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFBFFF;
  height: 100%;
  width: auto;
  transform: rotate(180deg) translate(-25%, 0px);
  z-index: -1;
  aspect-ratio: 1;
}
.cta-contact-big .headline {
  color: #FFFFFF;
}
.cta-contact-big .headline .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.cta-contact-big .headline .headline__content {
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .cta-contact-big .headline .headline__content {
    font-size: 2.875rem;
  }
}
.cta-contact-big .headline .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .cta-contact-big .headline .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.cta-contact-big .image {
  position: relative;
}
.cta-contact-big__button {
  --padding-x: 40px;
  --padding-y: 16px;
  --margin-top: 24px;
  margin-top: var(--margin-top);
  padding: var(--padding-y) var(--padding-x);
  display: inline-flex;
  align-items: center;
  gap: var(--padding-x);
  background-color: #FFFFFF;
  position: relative;
}
.cta-contact-big__button::after {
  content: "";
  display: inline-block;
  height: 24px;
  width: 24px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFFFFF;
  transform: translate(0px, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: var(--padding-x);
  right: auto;
}
.cta-contact-big__button-label {
  font-family: "Gilory";
  font-size: calc(1.0078125rem + 0.1339285714vw);
  font-weight: 600;
  line-height: 1.3333333333;
  letter-spacing: 0px;
  color: #23003E;
}
@media (min-width: 1400px) {
  .cta-contact-big__button-label {
    font-size: 1.125rem;
  }
}

.cta-contact-small {
  background-color: #FF006E;
  position: relative;
  padding-block: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  overflow: hidden;
}
.cta-contact-small .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.cta-contact-small .headline__content {
  color: #FFFFFF;
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 800;
  line-height: 1.1666666667;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .cta-contact-small .headline__content {
    font-size: 1.5rem;
  }
}
.cta-contact-small .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.02734375rem + 0.46875vw);
  font-weight: 400;
  line-height: 1.4347826087;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .cta-contact-small .headline__content .headline__accent {
    font-size: 1.4375rem;
  }
}
.cta-contact-small .headline, .cta-contact-small .url {
  z-index: 2;
}
.cta-contact-small .headline .headline__content {
  margin-bottom: 0;
}
.cta-contact-small::before {
  content: "";
  position: absolute;
  display: block;
  top: auto;
  left: 0;
  right: auto;
  bottom: auto;
  mask-image: url("~/public/img/_Icons/_Grafiken/Ton/Grafiken-Ton-1.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  height: clamp(200px, 25vw, 700px);
  width: auto;
  transform: rotate(90deg) translate(0px, 0px);
  z-index: 1;
  aspect-ratio: 1;
}
.cta-contact-small::after {
  content: "";
  position: absolute;
  display: block;
  top: auto;
  left: auto;
  right: 0;
  bottom: auto;
  mask-image: url("~/public/img/_Icons/_Grafiken/Ton/Grafiken-Ton-1.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  height: clamp(200px, 25vw, 700px);
  width: auto;
  transform: rotate(0) translate(0px, 0px);
  z-index: 1;
  aspect-ratio: 1;
  transform: rotate(90deg) scaleY(-1);
}
@media (max-width: 575.98px) {
  .cta-contact-small {
    flex-direction: column;
  }
  .cta-contact-small::before {
    display: none;
  }
}

.cta-teaser-fullscreen-image {
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1;
  box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.0784313725) inset;
}
.cta-teaser-fullscreen-image .image {
  grid-column: 1;
  grid-row: 1;
}
.cta-teaser-fullscreen-image .image img {
  width: 100%;
  object-fit: cover;
}
.cta-teaser-fullscreen-image .container {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.cta-teaser-fullscreen-image .container .card {
  min-width: 378px;
  max-width: 440px;
}
.cta-teaser-fullscreen-image .container .card::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: auto;
}

.cta-text-with-image {
  display: grid;
  grid-template-columns: minmax(auto, 492.8px) auto;
  gap: calc(1.3125rem + 5.3571428571vw);
  align-items: center;
  margin-left: calc(50vw - 270px + 1.5rem);
  margin-left: calc(50vw - 360px + 1.5rem);
  margin-left: calc(50vw - 480px + 1.5rem);
  margin-left: calc(50vw - 576px + 1.5rem);
}
@media (min-width: 1400px) {
  .cta-text-with-image {
    gap: 6rem;
  }
}
.cta-text-with-image__content {
  display: flex;
  flex-direction: column;
  gap: calc(1.03125rem + 0.5357142857vw);
}
@media (min-width: 1400px) {
  .cta-text-with-image__content {
    gap: 1.5rem;
  }
}
.cta-text-with-image__content .headline__content {
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .cta-text-with-image__content .headline__content {
    font-size: 2.25rem;
  }
}
.cta-text-with-image__content p {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.cta-text-with-image__links {
  display: flex;
  gap: calc(1.03125rem + 0.5357142857vw);
  flex-direction: column;
}
@media (min-width: 1400px) {
  .cta-text-with-image__links {
    gap: 1.5rem;
  }
}
.cta-text-with-image__link {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  position: relative;
  padding: calc(1.01171875rem + 0.2008928571vw);
}
@media (min-width: 1400px) {
  .cta-text-with-image__link {
    padding: 1.1875rem;
  }
}
.cta-text-with-image__link.primary::after {
  background-color: #10002F;
}
.cta-text-with-image__link.secondary::after {
  background-color: #5E05A8;
}
.cta-text-with-image__link.purple::after {
  background-color: #5E05A8;
}
.cta-text-with-image__link.dark-purple::after {
  background-color: #23003E;
}
.cta-text-with-image__link.red::after {
  background-color: #FF006E;
}
.cta-text-with-image__link.gray::after {
  background-color: #7F8095;
}
.cta-text-with-image__link.pink::after {
  background-color: #FC00C4;
}
.cta-text-with-image__link.rose::after {
  background-color: #FFBFFF;
}
.cta-text-with-image__link.blue::after {
  background-color: #00EDD9;
}
.cta-text-with-image__link.green::after {
  background-color: #57FF99;
}
.cta-text-with-image__link.yellow::after {
  background-color: #D9FF00;
}
.cta-text-with-image__link.white::after {
  background-color: #FFFFFF;
}
.cta-text-with-image__link::after {
  content: "";
  display: inline-block;
  height: 24px;
  width: 24px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(0, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: 24px;
  right: auto;
}
.cta-text-with-image__image {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-template-rows: 1fr;
  height: 100%;
}
.cta-text-with-image__image .image {
  grid-row: 1;
  position: relative;
}
.cta-text-with-image__image .image img {
  -webkit-box-shadow: 4px 4px 15px 5px rgba(0, 0, 0, 0.37);
  box-shadow: 4px 4px 15px 5px rgba(0, 0, 0, 0.37);
  object-fit: cover;
  height: 100%;
}
.cta-text-with-image__image .image:first-child {
  grid-column: 1/6;
  align-self: center;
  z-index: 1;
}
.cta-text-with-image__image .image:last-child {
  grid-column: 2/-1;
}
@media (max-width: 1199.98px) {
  .cta-text-with-image {
    margin-left: 0;
    padding-left: 3rem;
    grid-template-columns: minmax(auto, 410.6666666667px) auto;
    gap: calc(1.125rem + 2.1428571429vw);
  }
}
@media (max-width: 1199.98px) and (min-width: 1400px) {
  .cta-text-with-image {
    gap: 3rem;
  }
}
@media (max-width: 991.98px) {
  .cta-text-with-image {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    padding-right: 3rem;
  }
  .cta-text-with-image__content {
    grid-column: 1;
    grid-row: 2;
  }
  .cta-text-with-image__image {
    grid-column: 1;
    grid-row: 1;
  }
  .cta-text-with-image__image img {
    max-width: 100%;
  }
}
@media (max-width: 575.98px) {
  .cta-text-with-image {
    padding-inline: 1rem;
  }
}

.cta-projects {
  display: grid;
  grid-template-columns: 3fr 4fr;
  gap: calc(1.1875rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .cta-projects {
    gap: 4rem;
  }
}
.cta-projects__cta {
  grid-column: 1;
  position: relative;
}
.cta-projects__headline {
  position: sticky;
  top: 70px;
  display: flex;
  flex-direction: column;
  gap: calc(1.03125rem + 0.5357142857vw);
}
@media (min-width: 1400px) {
  .cta-projects__headline {
    gap: 1.5rem;
  }
}
.cta-projects__headline .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.cta-projects__headline .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .cta-projects__headline .headline__content {
    font-size: 2.875rem;
  }
}
.cta-projects__headline .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .cta-projects__headline .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.cta-projects__headline .headline-content {
  margin-bottom: 0;
}
.cta-projects__list {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  gap: calc(1.03125rem + 0.5357142857vw);
}
@media (min-width: 1400px) {
  .cta-projects__list {
    gap: 1.5rem;
  }
}
.cta-projects__item {
  box-shadow: 0px 48px 8px -48px rgba(0, 0, 0, 0.06), 0px 48px 80px 0px rgba(17, 12, 46, 0.12);
  background-color: #FFFFFF;
}
.cta-projects__item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cta-projects__item__categories-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  transform: translateY(calc(-12px - 1rem));
}
.cta-projects__item__category {
  padding-block: 0.125rem;
  padding-inline: 0.375rem;
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
  color: #4C4D6B;
  background-color: #FFFFFF;
}
.cta-projects__item__category--primary {
  border-left: solid 3px #10002F;
}
.cta-projects__item__category--secondary {
  border-left: solid 3px #5E05A8;
}
.cta-projects__item__category--purple {
  border-left: solid 3px #5E05A8;
}
.cta-projects__item__category--dark-purple {
  border-left: solid 3px #23003E;
}
.cta-projects__item__category--red {
  border-left: solid 3px #FF006E;
}
.cta-projects__item__category--gray {
  border-left: solid 3px #7F8095;
}
.cta-projects__item__category--pink {
  border-left: solid 3px #FC00C4;
}
.cta-projects__item__category--rose {
  border-left: solid 3px #FFBFFF;
}
.cta-projects__item__category--blue {
  border-left: solid 3px #00EDD9;
}
.cta-projects__item__category--green {
  border-left: solid 3px #57FF99;
}
.cta-projects__item__category--yellow {
  border-left: solid 3px #D9FF00;
}
.cta-projects__item__category--white {
  border-left: solid 3px #FFFFFF;
}
.cta-projects__item__content {
  padding: 1rem;
}
.cta-projects__item__content .headline {
  grid-template: 1/-1;
}
.cta-projects__item__content .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.cta-projects__item__content .headline__content {
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 800;
  line-height: 1.1666666667;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .cta-projects__item__content .headline__content {
    font-size: 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .cta-projects {
    grid-template-columns: 1fr;
  }
  .cta-projects__cta {
    grid-column: 1;
    grid-row: 1;
  }
  .cta-projects__list {
    grid-column: 1;
    grid-row: 2;
  }
}

.cta-application__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  height: 100%;
}
.cta-application__content .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.cta-application__content .headline__content {
  color: inherit;
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 800;
  line-height: 1.1666666667;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .cta-application__content .headline__content {
    font-size: 1.5rem;
  }
}
.cta-application__content .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.02734375rem + 0.46875vw);
  font-weight: 400;
  line-height: 1.4347826087;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .cta-application__content .headline__content .headline__accent {
    font-size: 1.4375rem;
  }
}
.cta-application__content .text-block {
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
@media (min-width: 1400px) {
  .cta-application__content .text-block {
    font-size: 1.25rem;
  }
}
.cta-application__contact-person__header img {
  width: 100%;
  object-fit: cover;
}
.cta-application__contact-person__body {
  background-color: #FFBFFF;
  padding: 1.5rem;
}
.cta-application__contact-person__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #5E05A8;
}
.cta-application__contact-person__name {
  font-family: "Gilory";
  font-size: calc(1.0078125rem + 0.1339285714vw);
  font-weight: 700;
  line-height: 1.2777777778;
  letter-spacing: 0px;
  margin-bottom: 1rem;
}
@media (min-width: 1400px) {
  .cta-application__contact-person__name {
    font-size: 1.125rem;
  }
}
.cta-application__contact-person__contact.email, .cta-application__contact-person__contact.phone {
  display: flex;
  align-items: center;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.cta-application__contact-person__contact.email::before {
  margin-right: 6px;
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Email.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFFFFF;
  transform: translate(0px, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}
.cta-application__contact-person__contact.phone::before {
  margin-right: 6px;
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Call.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFFFFF;
  transform: translate(0px, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}

.cta-text-with-link {
  padding-block: 100px;
  position: relative;
}
.cta-text-with-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  mask-image: url("~/public/img/_Icons/Schnuppe.svg");
  mask-size: 55px 64px;
  mask-repeat: repeat;
  background-color: #FC00C4;
  background-size: 60px 40px;
  opacity: 0.1;
}
.cta-text-with-link__card {
  background-color: #FFFFFF;
  padding: calc(1.3125rem + 5.3571428571vw);
  position: relative;
  z-index: 2;
}
@media (min-width: 1400px) {
  .cta-text-with-link__card {
    padding: 6rem;
  }
}
.cta-text-with-link__card .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.cta-text-with-link__card .headline__content {
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .cta-text-with-link__card .headline__content {
    font-size: 2.25rem;
  }
}
.cta-text-with-link__card__links {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 1.5rem;
}
.cta-text-with-link__card__links li.primary a::before {
  background-color: #10002F;
}
.cta-text-with-link__card__links li.secondary a::before {
  background-color: #5E05A8;
}
.cta-text-with-link__card__links li.purple a::before {
  background-color: #5E05A8;
}
.cta-text-with-link__card__links li.dark-purple a::before {
  background-color: #23003E;
}
.cta-text-with-link__card__links li.red a::before {
  background-color: #FF006E;
}
.cta-text-with-link__card__links li.gray a::before {
  background-color: #7F8095;
}
.cta-text-with-link__card__links li.pink a::before {
  background-color: #FC00C4;
}
.cta-text-with-link__card__links li.rose a::before {
  background-color: #FFBFFF;
}
.cta-text-with-link__card__links li.blue a::before {
  background-color: #00EDD9;
}
.cta-text-with-link__card__links li.green a::before {
  background-color: #57FF99;
}
.cta-text-with-link__card__links li.yellow a::before {
  background-color: #D9FF00;
}
.cta-text-with-link__card__links li.white a::before {
  background-color: #FFFFFF;
}
.cta-text-with-link__card__links li a {
  display: flex;
  align-items: center;
  gap: 1rem;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
  padding-left: 1.5rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #5E05A8;
  display: flex;
  align-items: center;
  gap: 2px;
  transition: gap 0.3s linear;
  cursor: pointer;
}
.cta-text-with-link__card__links li a::after {
  content: "";
  mask-image: url("~/public/img/_Icons/arrow-right.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  width: 16px;
  height: 16px;
  display: inline-block;
}
.cta-text-with-link__card__links li a:hover {
  gap: 8px;
}
.cta-text-with-link__card__links li a:hover {
  gap: 2px;
}
.cta-text-with-link__card__links li a:before {
  content: "";
  position: absolute;
  display: block;
  top: auto;
  left: 0;
  right: auto;
  bottom: auto;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FF006E;
  height: 20px;
  width: 20px;
  transform: rotate(0) translate(-50%, 0px);
  z-index: 1;
  aspect-ratio: 1;
}
.cta-text-with-link__card .text-block {
  color: #23003E;
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
@media (min-width: 1400px) {
  .cta-text-with-link__card .text-block {
    font-size: 1.25rem;
  }
}
.cta-text-with-link__card .headline__title {
  color: #5E05A8;
}
@media (max-width: 575.98px) {
  .cta-text-with-link__card {
    padding-inline: 24px;
    padding-block: 40px;
  }
}

.media-collage__header {
  text-align: center;
}
.media-collage__header .headline__content {
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-bottom: calc(1.03125rem + 0.5357142857vw);
}
@media (min-width: 1400px) {
  .media-collage__header .headline__content {
    font-size: 2.25rem;
  }
}
@media (min-width: 1400px) {
  .media-collage__header .headline__content {
    margin-bottom: 1.5rem;
  }
}
.media-collage__scroll-container {
  overflow-y: auto;
  overflow-y: hidden;
  scrollbar-width: none;
}
.media-collage__scroll-container::-webkit-scrollbar {
  display: none;
}
.media-collage__grid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: calc(1.03125rem + 0.5357142857vw);
}
@media (min-width: 1400px) {
  .media-collage__grid {
    gap: 1.5rem;
  }
}
.media-collage__column {
  display: flex;
  flex-direction: column;
  gap: calc(1.03125rem + 0.5357142857vw);
  justify-content: center;
}
@media (min-width: 1400px) {
  .media-collage__column {
    gap: 1.5rem;
  }
}
.media-collage__item.media-collage__item--col1.image--1 img, .media-collage__item.media-collage__item--col1.image--1 video {
  aspect-ratio: 270/369;
}
.media-collage__item.media-collage__item--col1.image--2 img, .media-collage__item.media-collage__item--col1.image--2 video {
  aspect-ratio: 270/184;
}
.media-collage__item.media-collage__item--col2.image--1 img, .media-collage__item.media-collage__item--col2.image--1 video {
  aspect-ratio: 564/251;
}
.media-collage__item.media-collage__item--col2.image--2 img, .media-collage__item.media-collage__item--col2.image--2 video {
  aspect-ratio: 564/370;
}
.media-collage__item.media-collage__item--col3.image--1 img, .media-collage__item.media-collage__item--col3.image--1 video {
  aspect-ratio: 270/184;
}
.media-collage__item.media-collage__item--col3.image--2 img, .media-collage__item.media-collage__item--col3.image--2 video {
  aspect-ratio: 270/343;
}
.media-collage__item video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: flex;
}

.media-collage-fullwidth__header {
  margin-bottom: calc(1.09375rem + 1.6071428571vw);
  text-align: center;
}
@media (min-width: 1400px) {
  .media-collage-fullwidth__header {
    margin-bottom: 2.5rem;
  }
}
.media-collage-fullwidth__header .headline__content {
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .media-collage-fullwidth__header .headline__content {
    font-size: 2.25rem;
  }
}
.media-collage-fullwidth__grid {
  display: grid;
  gap: 1rem;
  grid-template-areas: "small_1 medium_1 small_2 small_3 medium_2" "medium_3 medium_1 big_1 big_1 medium_2" "medium_3 small_4 big_1 big_1 small_5";
}
.media-collage-fullwidth__grid .image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.media-collage-fullwidth__grid .image.image--small_1 {
  grid-area: small_1;
}
.media-collage-fullwidth__grid .image.image--small_2 {
  grid-area: small_2;
}
.media-collage-fullwidth__grid .image.image--small_3 {
  grid-area: small_3;
}
.media-collage-fullwidth__grid .image.image--small_4 {
  grid-area: small_4;
}
.media-collage-fullwidth__grid .image.image--small_5 {
  grid-area: small_5;
}
.media-collage-fullwidth__grid .image.image--medium_1 {
  grid-area: medium_1;
}
.media-collage-fullwidth__grid .image.image--medium_2 {
  grid-area: medium_2;
}
.media-collage-fullwidth__grid .image.image--medium_3 {
  grid-area: medium_3;
}
.media-collage-fullwidth__grid .image.image--big {
  grid-area: big_1;
}
@media (max-width: 991.98px) {
  .media-collage-fullwidth__grid {
    grid-template-areas: "small_1 medium_1 small_2" "medium_2 medium_1 medium_3" "medium_2 small_3 medium_3" "small_4 big_1 big_1" "small_5 big_1 big_1";
  }
}
@media (max-width: 575.98px) {
  .media-collage-fullwidth__header {
    text-align: left;
    padding-inline: 1rem;
  }
  .media-collage-fullwidth__grid {
    grid-template-areas: "small_1 medium_2" "medium_1 medium_2" "medium_1 small_2";
  }
  .media-collage-fullwidth__grid .image--small_3, .media-collage-fullwidth__grid .image--small_4, .media-collage-fullwidth__grid .image--small_5, .media-collage-fullwidth__grid .image--medium_3, .media-collage-fullwidth__grid .image--big {
    display: none;
  }
}

.media-comparison-slider {
  position: relative;
  padding-bottom: 100vh;
}
.media-comparison-slider .before, .media-comparison-slider .after {
  width: 100%;
  height: 100%;
}
.media-comparison-slider .before img, .media-comparison-slider .after img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
}
.media-comparison-slider .after {
  position: absolute;
  overflow: hidden;
  top: 0;
  transform: translate(100%, 0px);
}

.media-fullscreen-image img {
  width: 100%;
  object-fit: cover;
}

.media-image-slider {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.media-image-slider .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.media-image-slider .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .media-image-slider .headline__content {
    font-size: 2.875rem;
  }
}
.media-image-slider .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .media-image-slider .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.media-image-slider .swiper {
  overflow: visible;
  cursor: grab;
}
.media-image-slider .swiper::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  transform: translateX(calc(-100% - 6px));
}
.media-image-slider .swiper-slide {
  width: auto;
}
.media-image-slider .swiper-slide img {
  height: 100%;
  object-fit: cover;
  max-height: 400px;
}

.section--dark .media-image-slider .swiper::before {
  background-color: #10002F;
  box-shadow: 1px 0px 1px 5px #10002F;
}

.section--light .media-image-slider .swiper::before {
  background-color: #FFFFFF;
  box-shadow: 1px 0px 1px 5px #FFFFFF;
}

.media-customer-slider {
  --crown-size: 32px;
}
.media-customer-slider .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.media-customer-slider .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .media-customer-slider .headline__content {
    font-size: 2.875rem;
  }
}
.media-customer-slider .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .media-customer-slider .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.media-customer-slider .headline {
  position: relative;
  margin-bottom: 1.5rem;
}
.media-customer-slider .headline__content {
  text-align: center;
}
.media-customer-slider .headline--primary::before {
  content: "";
  display: inline-block;
  height: var(--crown-size);
  width: var(--crown-size);
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #10002F;
  transform: translate(-50%, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: 50%;
  right: auto;
}
.media-customer-slider .headline--secondary::before {
  content: "";
  display: inline-block;
  height: var(--crown-size);
  width: var(--crown-size);
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(-50%, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: 50%;
  right: auto;
}
.media-customer-slider .headline--purple::before {
  content: "";
  display: inline-block;
  height: var(--crown-size);
  width: var(--crown-size);
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(-50%, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: 50%;
  right: auto;
}
.media-customer-slider .headline--dark-purple::before {
  content: "";
  display: inline-block;
  height: var(--crown-size);
  width: var(--crown-size);
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #23003E;
  transform: translate(-50%, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: 50%;
  right: auto;
}
.media-customer-slider .headline--red::before {
  content: "";
  display: inline-block;
  height: var(--crown-size);
  width: var(--crown-size);
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FF006E;
  transform: translate(-50%, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: 50%;
  right: auto;
}
.media-customer-slider .headline--gray::before {
  content: "";
  display: inline-block;
  height: var(--crown-size);
  width: var(--crown-size);
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #7F8095;
  transform: translate(-50%, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: 50%;
  right: auto;
}
.media-customer-slider .headline--pink::before {
  content: "";
  display: inline-block;
  height: var(--crown-size);
  width: var(--crown-size);
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FC00C4;
  transform: translate(-50%, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: 50%;
  right: auto;
}
.media-customer-slider .headline--rose::before {
  content: "";
  display: inline-block;
  height: var(--crown-size);
  width: var(--crown-size);
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFBFFF;
  transform: translate(-50%, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: 50%;
  right: auto;
}
.media-customer-slider .headline--blue::before {
  content: "";
  display: inline-block;
  height: var(--crown-size);
  width: var(--crown-size);
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #00EDD9;
  transform: translate(-50%, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: 50%;
  right: auto;
}
.media-customer-slider .headline--green::before {
  content: "";
  display: inline-block;
  height: var(--crown-size);
  width: var(--crown-size);
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #57FF99;
  transform: translate(-50%, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: 50%;
  right: auto;
}
.media-customer-slider .headline--yellow::before {
  content: "";
  display: inline-block;
  height: var(--crown-size);
  width: var(--crown-size);
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #D9FF00;
  transform: translate(-50%, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: 50%;
  right: auto;
}
.media-customer-slider .headline--white::before {
  content: "";
  display: inline-block;
  height: var(--crown-size);
  width: var(--crown-size);
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFFFFF;
  transform: translate(-50%, 0px);
  position: relative;
  top: auto;
  bottom: auto;
  left: 50%;
  right: auto;
}
.media-customer-slider__slide {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
  cursor: grab;
}
.media-customer-slider__slide-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 6px;
  padding: 10px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
}

.service-intro {
  position: relative;
  display: grid;
  grid-template-columns: clamp(300px, 490px, 50%) 3fr;
}
.service-intro--primary .service-intro__headline {
  border-right: solid 1px #10002F;
}
.service-intro--primary .service-intro__headline::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #10002F;
  transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 0;
}
.service-intro--secondary .service-intro__headline {
  border-right: solid 1px #5E05A8;
}
.service-intro--secondary .service-intro__headline::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 0;
}
.service-intro--purple .service-intro__headline {
  border-right: solid 1px #5E05A8;
}
.service-intro--purple .service-intro__headline::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 0;
}
.service-intro--dark-purple .service-intro__headline {
  border-right: solid 1px #23003E;
}
.service-intro--dark-purple .service-intro__headline::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #23003E;
  transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 0;
}
.service-intro--red .service-intro__headline {
  border-right: solid 1px #FF006E;
}
.service-intro--red .service-intro__headline::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FF006E;
  transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 0;
}
.service-intro--gray .service-intro__headline {
  border-right: solid 1px #7F8095;
}
.service-intro--gray .service-intro__headline::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #7F8095;
  transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 0;
}
.service-intro--pink .service-intro__headline {
  border-right: solid 1px #FC00C4;
}
.service-intro--pink .service-intro__headline::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FC00C4;
  transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 0;
}
.service-intro--rose .service-intro__headline {
  border-right: solid 1px #FFBFFF;
}
.service-intro--rose .service-intro__headline::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFBFFF;
  transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 0;
}
.service-intro--blue .service-intro__headline {
  border-right: solid 1px #00EDD9;
}
.service-intro--blue .service-intro__headline::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #00EDD9;
  transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 0;
}
.service-intro--green .service-intro__headline {
  border-right: solid 1px #57FF99;
}
.service-intro--green .service-intro__headline::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #57FF99;
  transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 0;
}
.service-intro--yellow .service-intro__headline {
  border-right: solid 1px #D9FF00;
}
.service-intro--yellow .service-intro__headline::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #D9FF00;
  transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 0;
}
.service-intro--white .service-intro__headline {
  border-right: solid 1px #FFFFFF;
}
.service-intro--white .service-intro__headline::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFFFFF;
  transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 0;
}
.service-intro__headline {
  position: relative;
  margin-right: 3rem;
  padding-right: 3rem;
}
.service-intro__headline .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.service-intro__headline .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .service-intro__headline .headline__content {
    font-size: 2.875rem;
  }
}
.service-intro__headline .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .service-intro__headline .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.service-intro__content {
  align-self: center;
}
@media (max-width: 575.98px) {
  .service-intro {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
  }
  .service-intro--primary .service-intro__headline {
    border-bottom: solid 1px #10002F;
    border-right: 0;
  }
  .service-intro--primary .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "primary";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .service-intro--secondary .service-intro__headline {
    border-bottom: solid 1px #5E05A8;
    border-right: 0;
  }
  .service-intro--secondary .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "secondary";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .service-intro--purple .service-intro__headline {
    border-bottom: solid 1px #5E05A8;
    border-right: 0;
  }
  .service-intro--purple .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "purple";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .service-intro--dark-purple .service-intro__headline {
    border-bottom: solid 1px #23003E;
    border-right: 0;
  }
  .service-intro--dark-purple .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "dark-purple";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .service-intro--red .service-intro__headline {
    border-bottom: solid 1px #FF006E;
    border-right: 0;
  }
  .service-intro--red .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "red";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .service-intro--gray .service-intro__headline {
    border-bottom: solid 1px #7F8095;
    border-right: 0;
  }
  .service-intro--gray .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "gray";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .service-intro--pink .service-intro__headline {
    border-bottom: solid 1px #FC00C4;
    border-right: 0;
  }
  .service-intro--pink .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "pink";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .service-intro--rose .service-intro__headline {
    border-bottom: solid 1px #FFBFFF;
    border-right: 0;
  }
  .service-intro--rose .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "rose";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .service-intro--blue .service-intro__headline {
    border-bottom: solid 1px #00EDD9;
    border-right: 0;
  }
  .service-intro--blue .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "blue";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .service-intro--green .service-intro__headline {
    border-bottom: solid 1px #57FF99;
    border-right: 0;
  }
  .service-intro--green .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "green";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .service-intro--yellow .service-intro__headline {
    border-bottom: solid 1px #D9FF00;
    border-right: 0;
  }
  .service-intro--yellow .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "yellow";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .service-intro--white .service-intro__headline {
    border-bottom: solid 1px #FFFFFF;
    border-right: 0;
  }
  .service-intro--white .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "white";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .service-intro__headline {
    grid-row: 1;
    margin-right: 0;
    padding-right: 0;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  .service-intro__content {
    grid-row: 2;
  }
}

.service-intro-video .video {
  display: flex;
  position: relative;
}
.service-intro-video .video video {
  width: 100%;
}
.service-intro-video .video.is--playing .video__overlay {
  background-color: rgba(0, 0, 0, 0);
}
.service-intro-video .video.is--playing .video__overlay .video__overlay__button-play {
  top: auto;
  left: auto;
  right: 0;
  bottom: 0;
  transform: translate(0px, 0px) scale(0.2);
  border-style: double;
  border-width: 0px 0 0px 60px;
  opacity: 0;
}
.service-intro-video .video.is--playing:hover .video__overlay .video__overlay__button-play {
  opacity: 1;
}
.service-intro-video .video:hover .video__overlay__button-sound {
  opacity: 1;
}
.service-intro-video .video__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 1;
}
.service-intro-video .video__overlay__button-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  width: 0;
  height: 74px;
  border-style: solid;
  border-width: 37px 0 37px 60px;
  border-color: transparent transparent transparent #E5E5EA;
  cursor: pointer;
  will-change: border-width;
  transition: all 0.5s ease;
  z-index: 2;
}
.service-intro-video .video__overlay__button-sound {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  padding: 1rem;
  z-index: 2;
  color: #FFFFFF;
  opacity: 0;
  transition: all 0.5s ease;
}
.service-intro-video .video__overlay:hover__button-play {
  border-color: transparent transparent transparent #B2B3BF;
}

.service-service-detail-intro__text {
  text-align: center;
  padding-block: 50px;
  border-top: solid 1px #5E05A8;
  border-bottom: solid 1px #5E05A8;
  position: relative;
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
.service-service-detail-intro__text::before, .service-service-detail-intro__text::after {
  background-color: #5E05A8;
}
.service-service-detail-intro__text::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: 50%;
  right: auto;
}
@media (min-width: 1400px) {
  .service-service-detail-intro__text {
    font-size: 1.25rem;
  }
}

.project-testimonial {
  --gap: calc(1.125rem + 2.1428571429vw);
  --left-col: calc(40% - (var(--gap) / 2));
  --right-col: calc(60% - (var(--gap) / 2));
  display: grid;
  grid-template-columns: var(--left-col) var(--right-col);
  gap: var(--gap);
  width: 100%;
}
.project-testimonial .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.project-testimonial .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .project-testimonial .headline__content {
    font-size: 2.875rem;
  }
}
.project-testimonial .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .project-testimonial .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.project-testimonial .headline__content {
  margin-bottom: 0;
}
.project-testimonial .swiper {
  max-width: 100%;
}
.project-testimonial .swiper-slide {
  padding-left: 1rem;
  cursor: grab;
}
.project-testimonial .swiper .swiper-pagination {
  justify-content: flex-start;
  padding-left: 1rem;
}
.project-testimonial .swiper-pagination-bullet {
  background-color: #FFFFFF;
}
.project-testimonial .swiper-pagination-bullet-active {
  background-color: #FF006E;
}
.project-testimonial__card {
  max-width: 100%;
}
.project-testimonial__card__inner {
  background-color: #FFFFFF;
  position: relative;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #23003E;
}
.project-testimonial__card__inner::before {
  content: "";
  background-image: url("~/public/img/_Grafiken/Zitat-Grafik-1.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  height: 35px;
  width: 55px;
  margin-bottom: 1rem;
}
.project-testimonial__card__inner::after {
  content: "";
  display: inline-block;
  height: 32px;
  width: 32px;
  mask-image: url("~/public/img/_Icons/Star-Half.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFFFFF;
  transform: translate(-100%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 0;
  right: auto;
}
.project-testimonial__card__text {
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
  flex: 1;
  padding-bottom: 1rem;
}
.project-testimonial__card__name {
  border-top: solid 1px #E5E5EA;
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
  padding-top: 1rem;
  text-transform: uppercase;
}
@media (max-width: 991.98px) {
  .project-testimonial {
    --gap: 1rem;
    display: flex;
    flex-direction: column;
  }
  .project-testimonial .swiper-slide {
    padding-left: 0;
    padding-top: 1rem;
  }
  .project-testimonial__card__inner::after {
    content: "";
    display: inline-block;
    height: 32px;
    width: 32px;
    mask-image: url("~/public/img/_Icons/Star-Half.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: #FFFFFF;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 0;
    bottom: auto;
    left: 50%;
    right: auto;
    transform: translate(-50%, -100%) rotate(90deg);
  }
}

.project-counter {
  border-top: solid 1px #FFBFFF;
  border-bottom: solid 1px #FFBFFF;
}
.project-counter .headline {
  padding-block: calc(1.125rem + 2.1428571429vw);
  text-align: center;
}
.project-counter .headline .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.project-counter .headline .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .project-counter .headline .headline__content {
    font-size: 2.875rem;
  }
}
.project-counter .headline .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .project-counter .headline .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
@media (min-width: 1400px) {
  .project-counter .headline {
    padding-block: 3rem;
  }
}
.project-counter .headline__content {
  margin-bottom: 0;
}
.project-counter__list {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: calc(1.03125rem + 0.5357142857vw);
  padding-bottom: calc(1.125rem + 2.1428571429vw);
}
@media (min-width: 1400px) {
  .project-counter__list {
    gap: 1.5rem;
  }
}
@media (min-width: 1400px) {
  .project-counter__list {
    padding-bottom: 3rem;
  }
}
.project-counter__list-item {
  padding: calc(1.03125rem + 0.5357142857vw);
  min-width: 10.9375rem;
  max-width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1400px) {
  .project-counter__list-item {
    padding: 1.5rem;
  }
}
.project-counter__list-item .number {
  color: #5E05A8;
  font-family: "BelyDisplay";
  font-size: calc(1.25rem + 4.2857142857vw);
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}
@media (min-width: 1400px) {
  .project-counter__list-item .number {
    font-size: 5rem;
  }
}
.project-counter__list-item .text {
  text-align: center;
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: #10002F;
}
@media (min-width: 1400px) {
  .project-counter__list-item .text {
    font-size: 1.25rem;
  }
}
@media (max-width: 575.98px) {
  .project-counter__list {
    align-items: center;
    flex-direction: column;
  }
}

.project-two-images {
  display: grid;
  flex-wrap: wrap;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
.project-two-images .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.project-two-images .headline__content {
  color: inherit;
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 800;
  line-height: 1.1666666667;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .project-two-images .headline__content {
    font-size: 1.5rem;
  }
}
.project-two-images .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.02734375rem + 0.46875vw);
  font-weight: 400;
  line-height: 1.4347826087;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .project-two-images .headline__content .headline__accent {
    font-size: 1.4375rem;
  }
}
.project-two-images .headline {
  text-align: center;
  grid-column: 1/-1;
}
.project-social-media-stories .text-block {
  text-align: center;
  padding-bottom: calc(1.09375rem + 1.6071428571vw);
  max-width: 60ch;
  margin-inline: auto;
}
.project-social-media-stories .text-block .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.project-social-media-stories .text-block .headline__content {
  color: inherit;
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 800;
  line-height: 1.1666666667;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .project-social-media-stories .text-block .headline__content {
    font-size: 1.5rem;
  }
}
.project-social-media-stories .text-block .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.02734375rem + 0.46875vw);
  font-weight: 400;
  line-height: 1.4347826087;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .project-social-media-stories .text-block .headline__content .headline__accent {
    font-size: 1.4375rem;
  }
}
@media (min-width: 1400px) {
  .project-social-media-stories .text-block {
    padding-bottom: 2.5rem;
  }
}
.project-social-media-stories .text-block p {
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
@media (min-width: 1400px) {
  .project-social-media-stories .text-block p {
    font-size: 1.25rem;
  }
}
.project-social-media-stories .text-block .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
}
.project-social-media-stories .text-block .headline__content {
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .project-social-media-stories .text-block .headline__content {
    font-size: 2.25rem;
  }
}
.project-social-media-stories__medias {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(1.125rem + 2.1428571429vw);
}
@media (min-width: 1400px) {
  .project-social-media-stories__medias {
    gap: 3rem;
  }
}
.project-social-media-stories__medias-item {
  position: relative;
}
.project-social-media-stories__medias-item:nth-child(2) .phone-mask {
  height: calc(3.8203125rem + 48.3482142857vw);
}
@media (min-width: 1400px) {
  .project-social-media-stories__medias-item:nth-child(2) .phone-mask {
    height: 46.125rem;
  }
}
.project-social-media-stories__medias-item .phone-mask {
  position: relative;
  z-index: 1;
  min-height: 550px;
  height: calc(3.5rem + 42.8571428571vw);
}
@media (min-width: 1400px) {
  .project-social-media-stories__medias-item .phone-mask {
    height: 41rem;
  }
}
.project-social-media-stories__medias-item .image, .project-social-media-stories__medias-item .video {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
}
.project-social-media-stories__medias-item .image img, .project-social-media-stories__medias-item .image video, .project-social-media-stories__medias-item .video img, .project-social-media-stories__medias-item .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.project-social-media-stories .scroll-container {
  overflow-y: auto;
  overflow-y: hidden;
  scrollbar-width: none;
}
.project-social-media-stories .scroll-container::-webkit-scrollbar {
  display: none;
}
@media (max-width: 991.98px) {
  .project-social-media-stories__medias {
    justify-content: flex-start;
  }
}

.project-video video {
  aspect-ratio: 16/9;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lp-intro {
  position: relative;
  display: grid;
  grid-template-columns: clamp(300px, 490px, 50%) 3fr;
  padding-block: calc(1.078125rem + 1.3392857143vw);
}
@media (min-width: 1400px) {
  .lp-intro {
    padding-block: 2.25rem;
  }
}
.lp-intro--primary {
  border-top: solid 1px #10002F;
  position: relative;
  border-bottom: solid 1px #10002F;
  position: relative;
}
.lp-intro--primary::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #10002F;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-intro--primary::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #10002F;
  transform: translate(50%, 50%);
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 50%;
}
.lp-intro--secondary {
  border-top: solid 1px #5E05A8;
  position: relative;
  border-bottom: solid 1px #5E05A8;
  position: relative;
}
.lp-intro--secondary::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-intro--secondary::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(50%, 50%);
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 50%;
}
.lp-intro--purple {
  border-top: solid 1px #5E05A8;
  position: relative;
  border-bottom: solid 1px #5E05A8;
  position: relative;
}
.lp-intro--purple::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-intro--purple::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  transform: translate(50%, 50%);
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 50%;
}
.lp-intro--dark-purple {
  border-top: solid 1px #23003E;
  position: relative;
  border-bottom: solid 1px #23003E;
  position: relative;
}
.lp-intro--dark-purple::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #23003E;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-intro--dark-purple::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #23003E;
  transform: translate(50%, 50%);
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 50%;
}
.lp-intro--red {
  border-top: solid 1px #FF006E;
  position: relative;
  border-bottom: solid 1px #FF006E;
  position: relative;
}
.lp-intro--red::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FF006E;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-intro--red::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FF006E;
  transform: translate(50%, 50%);
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 50%;
}
.lp-intro--gray {
  border-top: solid 1px #7F8095;
  position: relative;
  border-bottom: solid 1px #7F8095;
  position: relative;
}
.lp-intro--gray::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #7F8095;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-intro--gray::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #7F8095;
  transform: translate(50%, 50%);
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 50%;
}
.lp-intro--pink {
  border-top: solid 1px #FC00C4;
  position: relative;
  border-bottom: solid 1px #FC00C4;
  position: relative;
}
.lp-intro--pink::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FC00C4;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-intro--pink::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FC00C4;
  transform: translate(50%, 50%);
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 50%;
}
.lp-intro--rose {
  border-top: solid 1px #FFBFFF;
  position: relative;
  border-bottom: solid 1px #FFBFFF;
  position: relative;
}
.lp-intro--rose::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFBFFF;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-intro--rose::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFBFFF;
  transform: translate(50%, 50%);
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 50%;
}
.lp-intro--blue {
  border-top: solid 1px #00EDD9;
  position: relative;
  border-bottom: solid 1px #00EDD9;
  position: relative;
}
.lp-intro--blue::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #00EDD9;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-intro--blue::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #00EDD9;
  transform: translate(50%, 50%);
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 50%;
}
.lp-intro--green {
  border-top: solid 1px #57FF99;
  position: relative;
  border-bottom: solid 1px #57FF99;
  position: relative;
}
.lp-intro--green::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #57FF99;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-intro--green::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #57FF99;
  transform: translate(50%, 50%);
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 50%;
}
.lp-intro--yellow {
  border-top: solid 1px #D9FF00;
  position: relative;
  border-bottom: solid 1px #D9FF00;
  position: relative;
}
.lp-intro--yellow::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #D9FF00;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-intro--yellow::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #D9FF00;
  transform: translate(50%, 50%);
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 50%;
}
.lp-intro--white {
  border-top: solid 1px #FFFFFF;
  position: relative;
  border-bottom: solid 1px #FFFFFF;
  position: relative;
}
.lp-intro--white::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFFFFF;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-intro--white::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFFFFF;
  transform: translate(50%, 50%);
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 50%;
}
.lp-intro__headline {
  position: relative;
  margin-right: 3rem;
  padding-right: 3rem;
}
.lp-intro__headline .headline__content {
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .lp-intro__headline .headline__content {
    font-size: 2.25rem;
  }
}
.lp-intro__content {
  align-self: center;
}
@media (max-width: 575.98px) {
  .lp-intro {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
  }
  .lp-intro--primary .service-intro__headline {
    border-bottom: solid 1px #10002F;
    border-right: 0;
  }
  .lp-intro--primary .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "primary";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .lp-intro--secondary .service-intro__headline {
    border-bottom: solid 1px #5E05A8;
    border-right: 0;
  }
  .lp-intro--secondary .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "secondary";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .lp-intro--purple .service-intro__headline {
    border-bottom: solid 1px #5E05A8;
    border-right: 0;
  }
  .lp-intro--purple .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "purple";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .lp-intro--dark-purple .service-intro__headline {
    border-bottom: solid 1px #23003E;
    border-right: 0;
  }
  .lp-intro--dark-purple .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "dark-purple";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .lp-intro--red .service-intro__headline {
    border-bottom: solid 1px #FF006E;
    border-right: 0;
  }
  .lp-intro--red .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "red";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .lp-intro--gray .service-intro__headline {
    border-bottom: solid 1px #7F8095;
    border-right: 0;
  }
  .lp-intro--gray .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "gray";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .lp-intro--pink .service-intro__headline {
    border-bottom: solid 1px #FC00C4;
    border-right: 0;
  }
  .lp-intro--pink .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "pink";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .lp-intro--rose .service-intro__headline {
    border-bottom: solid 1px #FFBFFF;
    border-right: 0;
  }
  .lp-intro--rose .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "rose";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .lp-intro--blue .service-intro__headline {
    border-bottom: solid 1px #00EDD9;
    border-right: 0;
  }
  .lp-intro--blue .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "blue";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .lp-intro--green .service-intro__headline {
    border-bottom: solid 1px #57FF99;
    border-right: 0;
  }
  .lp-intro--green .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "green";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .lp-intro--yellow .service-intro__headline {
    border-bottom: solid 1px #D9FF00;
    border-right: 0;
  }
  .lp-intro--yellow .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "yellow";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .lp-intro--white .service-intro__headline {
    border-bottom: solid 1px #FFFFFF;
    border-right: 0;
  }
  .lp-intro--white .service-intro__headline::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    mask-image: url("~/public/img/_Icons/Star.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: "white";
    transform: translate(50%, 50%);
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
  }
  .lp-intro__headline {
    grid-row: 1;
    margin-right: 0;
    padding-right: 0;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  .lp-intro__content {
    grid-row: 2;
  }
}

.lp-project {
  display: grid;
  grid-template-columns: minmax(360px, 622px) 1fr;
  gap: calc(1.1875rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .lp-project {
    gap: 4rem;
  }
}
.lp-project__teaser {
  display: flex;
  flex-direction: column;
  gap: calc(1.03125rem + 0.5357142857vw);
}
@media (min-width: 1400px) {
  .lp-project__teaser {
    gap: 1.5rem;
  }
}
.lp-project__image img {
  max-width: 100%;
}
.lp-project__headline .headline__content {
  margin-bottom: 0;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .lp-project__headline .headline__content {
    font-size: 2.875rem;
  }
}
.lp-project__info {
  display: flex;
  flex-wrap: wrap;
  column-gap: 42px;
  row-gap: 8px;
  padding-top: calc(1.078125rem + 1.3392857143vw);
  margin-top: 0.75rem;
  border-top: solid 1px #FFBFFF;
  position: relative;
}
@media (min-width: 1400px) {
  .lp-project__info {
    padding-top: 2.25rem;
  }
}
.lp-project__info::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFBFFF;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-project__info__item {
  background-color: #23003E;
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  min-width: 138px;
  position: relative;
}
.lp-project__info__item::after {
  content: "";
  display: block;
  height: 100%;
  width: auto;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #23003E;
  transform: translate(50%, 0px);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 0;
  aspect-ratio: 1/1;
}
.lp-project__info__item__title {
  text-transform: uppercase;
  color: #FFBFFF;
}
.lp-project__info__item__name {
  color: #FFFFFF;
  font-family: "Gilory";
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1.3571428571;
  letter-spacing: 0px;
}
.lp-project__content strong {
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 800;
  line-height: 1.1666666667;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .lp-project__content strong {
    font-size: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .lp-project {
    grid-template-columns: minmax(343px, 360px) 1fr;
  }
}
@media (max-width: 575.98px) {
  .lp-project {
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
  }
}

.lp-contact__intro {
  padding-bottom: calc(1.171875rem + 2.9464285714vw);
}
@media (min-width: 1400px) {
  .lp-contact__intro {
    padding-bottom: 3.75rem;
  }
}
.lp-contact__intro .headline__content {
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .lp-contact__intro .headline__content {
    font-size: 2.25rem;
  }
}
.lp-contact__intro .text-block {
  text-align: center;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.lp-contact__form {
  border-top: solid 2px #FF006E;
  background-color: #FFFFFF;
  position: relative;
  padding: calc(1.125rem + 2.1428571429vw);
  border-top: solid 1px #FF006E;
  position: relative;
}
@media (min-width: 1400px) {
  .lp-contact__form {
    padding: 3rem;
  }
}
.lp-contact__form::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FF006E;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.lp-contact__form__header {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #E5E5EA;
  gap: calc(1.125rem + 2.1428571429vw);
  padding-bottom: calc(1.0625rem + 1.0714285714vw);
  margin-bottom: calc(1.0625rem + 1.0714285714vw);
}
@media (min-width: 1400px) {
  .lp-contact__form__header {
    gap: 3rem;
  }
}
@media (min-width: 1400px) {
  .lp-contact__form__header {
    padding-bottom: 2rem;
  }
}
@media (min-width: 1400px) {
  .lp-contact__form__header {
    margin-bottom: 2rem;
  }
}
.lp-contact__form__header .lp-contact__person {
  display: flex;
  align-items: center;
  gap: calc(1.03125rem + 0.5357142857vw);
}
@media (min-width: 1400px) {
  .lp-contact__form__header .lp-contact__person {
    gap: 1.5rem;
  }
}
.lp-contact__form__header .lp-contact__person img {
  height: 100px;
  width: 100px;
  object-fit: cover;
}
.lp-contact__form__header .lp-contact__person p {
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 800;
  line-height: 1.1666666667;
  letter-spacing: 0px;
  color: #10002F;
}
@media (min-width: 1400px) {
  .lp-contact__form__header .lp-contact__person p {
    font-size: 1.5rem;
  }
}
.lp-contact__form__header .lp-contact__contact-data--phone a, .lp-contact__form__header .lp-contact__contact-data--email a {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #23003E;
}
.lp-contact__form__header .lp-contact__contact-data--phone a, .lp-contact__form__header .lp-contact__contact-data--email {
  white-space: nowrap;
}
.lp-contact__form__header .lp-contact__contact-data--phone::before {
  margin-right: 6px;
  content: "";
  display: inline-block;
  height: 24px;
  width: 24px;
  mask-image: url("~/public/img/_Icons/Call.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FF006E;
  transform: translate(0px, 0px);
  position: relative;
  top: 6px;
  bottom: auto;
  left: auto;
  right: auto;
}
.lp-contact__form__header .lp-contact__contact-data--email::before {
  margin-right: 6px;
  content: "";
  display: inline-block;
  height: 24px;
  width: 24px;
  mask-image: url("~/public/img/_Icons/Email.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FF006E;
  transform: translate(0px, 0px);
  position: relative;
  top: 6px;
  bottom: auto;
  left: auto;
  right: auto;
}
.lp-contact__form__body form .form-label {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #10002F;
}
.lp-contact__form__body form .form-control {
  background-color: #F8F8F9;
}
.lp-contact__form__body form label[for=data-privacy] {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #272727;
}
.lp-contact__form__body form .submit button:hover {
  border-color: #10002F;
  color: #10002F;
}
@media (max-width: 991.98px) {
  .lp-contact__form__header {
    align-items: flex-start;
    flex-direction: column;
  }
}
@media (max-width: 575.98px) {
  .lp-contact__form {
    margin-inline: -1rem;
  }
}

.lp-about {
  position: relative;
  max-width: 1200px;
  margin-inline: auto;
}
.lp-about::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: auto;
  right: 0;
  bottom: auto;
  mask-image: url("~/public/img/_Icons/_Grafiken/Licht/Grafiken-Licht-3.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #5E05A8;
  height: 100%;
  width: auto;
  transform: rotate(180deg) translate(-17%, 0px);
  z-index: 1;
  aspect-ratio: 1;
}
.lp-about__inner-container {
  padding-block: calc(1.328125rem + 5.625vw);
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  max-width: 1232px;
}
@media (min-width: 1400px) {
  .lp-about__inner-container {
    padding-block: 6.25rem;
  }
}
.lp-about__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-right: calc(1.125rem + 2.1428571429vw);
}
@media (min-width: 1400px) {
  .lp-about__content {
    padding-right: 3rem;
  }
}
.lp-about__content .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.lp-about__content .headline__content {
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
  text-transform: unset;
}
@media (min-width: 1400px) {
  .lp-about__content .headline__content {
    font-size: 2.25rem;
  }
}
.lp-about__content .text-block {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.lp-about__image {
  position: relative;
  z-index: 2;
}
@media (max-width: 991.98px) {
  .lp-about::after {
    transform: rotate(180deg, translateX(0));
  }
  .lp-about__content {
    padding-right: 0;
  }
}
@media (max-width: 575.98px) {
  .lp-about::after {
    height: 70%;
  }
}

.hero--service {
  color: #FFFFFF;
}
.hero--service.hero--primary .hero--service__graphic {
  background-color: #10002F;
}
.hero--service.hero--primary .headline .headline__accent,
.hero--service.hero--primary .headline .headline__title {
  color: #10002F;
}
.hero--service.hero--secondary .hero--service__graphic {
  background-color: #5E05A8;
}
.hero--service.hero--secondary .headline .headline__accent,
.hero--service.hero--secondary .headline .headline__title {
  color: #5E05A8;
}
.hero--service.hero--purple .hero--service__graphic {
  background-color: #5E05A8;
}
.hero--service.hero--purple .headline .headline__accent,
.hero--service.hero--purple .headline .headline__title {
  color: #5E05A8;
}
.hero--service.hero--dark-purple .hero--service__graphic {
  background-color: #23003E;
}
.hero--service.hero--dark-purple .headline .headline__accent,
.hero--service.hero--dark-purple .headline .headline__title {
  color: #23003E;
}
.hero--service.hero--red .hero--service__graphic {
  background-color: #FF006E;
}
.hero--service.hero--red .headline .headline__accent,
.hero--service.hero--red .headline .headline__title {
  color: #FF006E;
}
.hero--service.hero--gray .hero--service__graphic {
  background-color: #7F8095;
}
.hero--service.hero--gray .headline .headline__accent,
.hero--service.hero--gray .headline .headline__title {
  color: #7F8095;
}
.hero--service.hero--pink .hero--service__graphic {
  background-color: #FC00C4;
}
.hero--service.hero--pink .headline .headline__accent,
.hero--service.hero--pink .headline .headline__title {
  color: #FC00C4;
}
.hero--service.hero--rose .hero--service__graphic {
  background-color: #FFBFFF;
}
.hero--service.hero--rose .headline .headline__accent,
.hero--service.hero--rose .headline .headline__title {
  color: #FFBFFF;
}
.hero--service.hero--blue .hero--service__graphic {
  background-color: #00EDD9;
}
.hero--service.hero--blue .headline .headline__accent,
.hero--service.hero--blue .headline .headline__title {
  color: #00EDD9;
}
.hero--service.hero--green .hero--service__graphic {
  background-color: #57FF99;
}
.hero--service.hero--green .headline .headline__accent,
.hero--service.hero--green .headline .headline__title {
  color: #57FF99;
}
.hero--service.hero--yellow .hero--service__graphic {
  background-color: #D9FF00;
}
.hero--service.hero--yellow .headline .headline__accent,
.hero--service.hero--yellow .headline .headline__title {
  color: #D9FF00;
}
.hero--service.hero--white .hero--service__graphic {
  background-color: #FFFFFF;
}
.hero--service.hero--white .headline .headline__accent,
.hero--service.hero--white .headline .headline__title {
  color: #FFFFFF;
}
.hero--service .container_inner {
  position: relative;
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  max-width: 1290px;
}
.hero--service .hero--service__graphic {
  height: 100%;
  transform: rotate(90deg) translateY(10%);
  z-index: -1;
  mask-size: contain;
  mask-repeat: no-repeat;
  aspect-ratio: 1/1;
  position: absolute;
  top: 0;
  left: 0;
}
.hero--service__inner {
  padding-top: 80px;
  padding-bottom: 168px;
  display: grid;
  gap: 3rem;
  grid-template-columns: auto minmax(400px, 1fr);
  grid-template-rows: 1fr;
}
.hero--service__inner__image {
  grid-column: 1;
  grid-row: 1;
  position: relative;
}
.hero--service__inner__image::before, .hero--service__inner__image::after {
  content: "";
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  aspect-ratio: 1/1;
}
.hero--service__inner__image::before {
  height: 70%;
  backdrop-filter: blur(10px);
}
.hero--service__inner__image::after {
  height: 35%;
  backdrop-filter: blur(15px);
}
.hero--service__inner__image img {
  width: clamp(500px, 100%, 742px);
}
.hero--service__inner__content {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hero--service__inner__content .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.hero--service__inner__content .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.15625rem + 2.6785714286vw);
  font-weight: 900;
  line-height: 1.1607142857;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .hero--service__inner__content .headline__content {
    font-size: 3.5rem;
  }
}
.hero--service__inner__content .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.1484375rem + 2.5446428571vw);
  font-weight: 400;
  line-height: 1.2037037037;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .hero--service__inner__content .headline__content .headline__accent {
    font-size: 3.375rem;
  }
}
.hero--service__inner__content .button__wrapper {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
@media (max-width: 1439.98px) {
  .hero--service .hero--service__graphic {
    transform: rotate(90deg) translateY(0px);
  }
}
@media (max-width: 991.98px) {
  .hero--service .hero--service__graphic {
    height: 60%;
  }
  .hero--service__inner {
    grid-template-columns: 1fr;
  }
  .hero--service__inner__content {
    grid-row: 2;
    grid-column: 1;
  }
  .hero--service__inner__image {
    grid-column: 1;
    grid-row: 1;
  }
  .hero--service__inner__image img {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .hero--service .hero--service__graphic {
    height: 60%;
    top: auto;
    bottom: 50px;
    transform: rotate(0deg);
  }
  .hero--service__inner {
    padding-bottom: 106px;
  }
  .hero--service__inner__content {
    grid-row: 1;
  }
  .hero--service__inner__image {
    grid-row: 2;
  }
}

.hero-agentur__headline-container {
  padding-bottom: calc(1.1875rem + 3.2142857143vw);
}
.hero-agentur__headline-container .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.hero-agentur__headline-container .headline__content {
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.15625rem + 2.6785714286vw);
  font-weight: 900;
  line-height: 1.1607142857;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .hero-agentur__headline-container .headline__content {
    font-size: 3.5rem;
  }
}
.hero-agentur__headline-container .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.1484375rem + 2.5446428571vw);
  font-weight: 400;
  line-height: 1.2037037037;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .hero-agentur__headline-container .headline__content .headline__accent {
    font-size: 3.375rem;
  }
}
@media (min-width: 1400px) {
  .hero-agentur__headline-container {
    padding-bottom: 4rem;
  }
}
.hero-agentur__headline-container .headline {
  text-align: center;
}
.hero-agentur__grid-gallery {
  position: relative;
  padding-top: calc(1.1875rem + 3.2142857143vw);
  padding-bottom: calc(1.34375rem + 5.8928571429vw);
  display: grid;
  grid-template-columns: repeat(12, 8.3333333333%);
  grid-template-rows: repeat(12, clamp(30px, 5vw, 54px));
}
@media (min-width: 1400px) {
  .hero-agentur__grid-gallery {
    padding-top: 4rem;
  }
}
@media (min-width: 1400px) {
  .hero-agentur__grid-gallery {
    padding-bottom: 6.5rem;
  }
}
.hero-agentur__grid-gallery .image__main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-agentur__grid-gallery .image__main {
  grid-column: 1/-1;
  grid-row: 1/-1;
}
.hero-agentur__grid-gallery .image-child.image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.hero-agentur__grid-gallery .image-child.image--1 {
  grid-column: 1/span 4;
  grid-row: 2;
}
.hero-agentur__grid-gallery .image-child.image--1 img {
  transform: translateX(-2.3rem);
}
.hero-agentur__grid-gallery .image-child.image--2 {
  grid-column: 8/span 4;
  grid-row: 1;
}
.hero-agentur__grid-gallery .image-child.image--2 img {
  transform: translateY(-3.2rem);
}
.hero-agentur__grid-gallery .image-child.image--3 {
  grid-column: 2/span 4;
  grid-row: 8;
}
.hero-agentur__grid-gallery .image-child.image--3 img {
  transform: translateY(2.9rem);
}
.hero-agentur__grid-gallery .image-child.image--4 {
  grid-column: 10/span 3;
  grid-row: 7;
}
.hero-agentur__grid-gallery .image-child.image--4 img {
  transform: translateX(2rem);
}
.hero-agentur__intro {
  border-top: solid 1px #00EDD9;
  border-bottom: solid 1px #00EDD9;
  position: relative;
  padding-block: calc(1.078125rem + 1.3392857143vw);
}
.hero-agentur__intro::before, .hero-agentur__intro::after {
  background-color: #00EDD9;
}
.hero-agentur__intro::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Brand-Crown.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #00EDD9;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: 50%;
  right: auto;
}
@media (min-width: 1400px) {
  .hero-agentur__intro {
    padding-block: 2.25rem;
  }
}
.hero-agentur__intro .text-block {
  text-align: center;
}
.hero-agentur__intro .text-block .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.hero-agentur__intro .text-block .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .hero-agentur__intro .text-block .headline__content {
    font-size: 2.875rem;
  }
}
.hero-agentur__intro .text-block .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .hero-agentur__intro .text-block .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
@media (max-width: 575.98px) {
  .hero-agentur__headline-container {
    padding-bottom: 0;
  }
  .hero-agentur__headline-container .headline {
    text-align: left;
  }
  .hero-agentur__grid-gallery {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    padding-top: 0;
    gap: 1rem;
  }
  .hero-agentur__grid-gallery .image--1, .hero-agentur__grid-gallery .image--2 {
    display: none;
  }
  .hero-agentur__grid-gallery .image-child.image--3 {
    grid-column: 1;
    grid-row: 2;
  }
  .hero-agentur__grid-gallery .image-child.image--3 img {
    transform: translateY(0);
  }
  .hero-agentur__grid-gallery .image-child.image--4 {
    grid-column: 1;
    grid-row: 3;
  }
  .hero-agentur__grid-gallery .image-child.image--4 img {
    transform: translateX(0);
  }
  .hero-agentur__intro .text-block {
    text-align: left;
  }
}

.hero--service-detail {
  padding-block: 48px;
}
.hero--service-detail .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
}
.hero--service-detail .headline__content {
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
  color: #FFFFFF;
}
@media (min-width: 1400px) {
  .hero--service-detail .headline__content {
    font-size: 2.25rem;
  }
}
.hero--service-detail.hero--primary .container::before {
  background-color: #10002F;
}
.hero--service-detail.hero--primary .headline .headline__accent,
.hero--service-detail.hero--primary .headline .headline__title {
  color: #10002F;
}
.hero--service-detail.hero--secondary .container::before {
  background-color: #5E05A8;
}
.hero--service-detail.hero--secondary .headline .headline__accent,
.hero--service-detail.hero--secondary .headline .headline__title {
  color: #5E05A8;
}
.hero--service-detail.hero--purple .container::before {
  background-color: #5E05A8;
}
.hero--service-detail.hero--purple .headline .headline__accent,
.hero--service-detail.hero--purple .headline .headline__title {
  color: #5E05A8;
}
.hero--service-detail.hero--dark-purple .container::before {
  background-color: #23003E;
}
.hero--service-detail.hero--dark-purple .headline .headline__accent,
.hero--service-detail.hero--dark-purple .headline .headline__title {
  color: #23003E;
}
.hero--service-detail.hero--red .container::before {
  background-color: #FF006E;
}
.hero--service-detail.hero--red .headline .headline__accent,
.hero--service-detail.hero--red .headline .headline__title {
  color: #FF006E;
}
.hero--service-detail.hero--gray .container::before {
  background-color: #7F8095;
}
.hero--service-detail.hero--gray .headline .headline__accent,
.hero--service-detail.hero--gray .headline .headline__title {
  color: #7F8095;
}
.hero--service-detail.hero--pink .container::before {
  background-color: #FC00C4;
}
.hero--service-detail.hero--pink .headline .headline__accent,
.hero--service-detail.hero--pink .headline .headline__title {
  color: #FC00C4;
}
.hero--service-detail.hero--rose .container::before {
  background-color: #FFBFFF;
}
.hero--service-detail.hero--rose .headline .headline__accent,
.hero--service-detail.hero--rose .headline .headline__title {
  color: #FFBFFF;
}
.hero--service-detail.hero--blue .container::before {
  background-color: #00EDD9;
}
.hero--service-detail.hero--blue .headline .headline__accent,
.hero--service-detail.hero--blue .headline .headline__title {
  color: #00EDD9;
}
.hero--service-detail.hero--green .container::before {
  background-color: #57FF99;
}
.hero--service-detail.hero--green .headline .headline__accent,
.hero--service-detail.hero--green .headline .headline__title {
  color: #57FF99;
}
.hero--service-detail.hero--yellow .container::before {
  background-color: #D9FF00;
}
.hero--service-detail.hero--yellow .headline .headline__accent,
.hero--service-detail.hero--yellow .headline .headline__title {
  color: #D9FF00;
}
.hero--service-detail.hero--white .container::before {
  background-color: #FFFFFF;
}
.hero--service-detail.hero--white .headline .headline__accent,
.hero--service-detail.hero--white .headline .headline__title {
  color: #FFFFFF;
}

.project-next {
  display: flex;
  flex-direction: column;
  gap: calc(1.09375rem + 1.6071428571vw);
}
@media (min-width: 1400px) {
  .project-next {
    gap: 2.5rem;
  }
}
.project-next__header-container {
  display: grid;
  grid-template-columns: 1fr max-content;
}
.project-next__headline .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
}
.project-next__headline .headline__content {
  color: #FFFFFF;
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .project-next__headline .headline__content {
    font-size: 2.25rem;
  }
}
.project-next__cta-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.project-next__buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.project-next__buttons-container a {
  white-space: nowrap;
}
.project-next__teaser-text .text-block {
  max-width: 45ch;
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
@media (min-width: 1400px) {
  .project-next__teaser-text .text-block {
    font-size: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  .project-next__header-container {
    grid-template-columns: 1fr;
  }
}

.team__intro {
  padding-bottom: 100px;
}
.team__intro .headline .headline__content {
  text-align: center;
  font-family: "Gilory";
  font-size: calc(1.078125rem + 1.3392857143vw);
  font-weight: 800;
  line-height: 1.1111111111;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .team__intro .headline .headline__content {
    font-size: 2.25rem;
  }
}
.team__intro .text-block {
  text-align: center;
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
@media (min-width: 1400px) {
  .team__intro .text-block {
    font-size: 1.25rem;
  }
}
.team__list {
  display: grid;
  grid-template-rows: 1fr;
}
.team__list__item {
  display: grid;
  align-items: center;
}
.team__list__item .about__jobs {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.team__list__item .about__name {
  font-family: "Gilory";
  font-size: calc(1.0078125rem + 0.1339285714vw);
  font-weight: 700;
  line-height: 1.2777777778;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .team__list__item .about__name {
    font-size: 1.125rem;
  }
}
.team__list__item .about__text {
  margin-top: 1rem;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.team__list__item:not(:first-child) {
  margin-top: -36px;
}
.team__list__item:nth-child(even) {
  grid-template-columns: minmax(350px, 1fr) auto;
}
.team__list__item:nth-child(even) .image {
  grid-column: 2;
  grid-row: 1;
  justify-self: flex-end;
}
.team__list__item:nth-child(even) .about {
  grid-column: 1;
  grid-row: 1;
  padding-right: calc(1.125rem + 2.1428571429vw);
}
@media (min-width: 1400px) {
  .team__list__item:nth-child(even) .about {
    padding-right: 3rem;
  }
}
.team__list__item:nth-child(odd) {
  grid-template-columns: auto minmax(350px, 1fr);
}
.team__list__item:nth-child(odd) .image {
  grid-column: 1;
  grid-row: 1;
}
.team__list__item:nth-child(odd) .about {
  grid-column: 2;
  grid-row: 1;
  padding-left: calc(1.125rem + 2.1428571429vw);
}
@media (min-width: 1400px) {
  .team__list__item:nth-child(odd) .about {
    padding-left: 3rem;
  }
}
@media (max-width: 991.98px) {
  .team__list__item:nth-child(odd), .team__list__item:nth-child(even) {
    grid-template-columns: auto auto;
    gap: 1rem;
  }
  .team__list__item:nth-child(odd) .about, .team__list__item:nth-child(even) .about {
    padding-inline: 0;
    padding-block: 4rem;
  }
  .team__list__item:nth-child(odd) .image, .team__list__item:nth-child(even) .image {
    height: 100%;
  }
  .team__list__item:nth-child(odd) .image img, .team__list__item:nth-child(even) .image img {
    height: 100%;
    object-fit: cover;
  }
}
@media (max-width: 575.98px) {
  .team__intro .text-block {
    text-align: left;
  }
  .team__list {
    gap: 2rem;
  }
  .team__list__item:not(:first-child) {
    margin-top: 0;
  }
  .team__list__item:nth-child(even), .team__list__item:nth-child(odd) {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
  }
  .team__list__item:nth-child(even) .image, .team__list__item:nth-child(odd) .image {
    grid-row: 1;
    grid-column: 1;
    justify-self: center;
  }
  .team__list__item:nth-child(even) .about, .team__list__item:nth-child(odd) .about {
    grid-row: 2;
    grid-column: 1;
    padding-block: 0;
  }
}

.portfolio__hero .categories {
  display: flex;
  gap: 4px;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}
.portfolio__hero .categories__item {
  background-color: #FFFFFF;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  color: #7F8095;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.portfolio__hero .categories__item--primary {
  border-left: solid 5px #10002F;
}
.portfolio__hero .categories__item--secondary {
  border-left: solid 5px #5E05A8;
}
.portfolio__hero .categories__item--purple {
  border-left: solid 5px #5E05A8;
}
.portfolio__hero .categories__item--dark-purple {
  border-left: solid 5px #23003E;
}
.portfolio__hero .categories__item--red {
  border-left: solid 5px #FF006E;
}
.portfolio__hero .categories__item--gray {
  border-left: solid 5px #7F8095;
}
.portfolio__hero .categories__item--pink {
  border-left: solid 5px #FC00C4;
}
.portfolio__hero .categories__item--rose {
  border-left: solid 5px #FFBFFF;
}
.portfolio__hero .categories__item--blue {
  border-left: solid 5px #00EDD9;
}
.portfolio__hero .categories__item--green {
  border-left: solid 5px #57FF99;
}
.portfolio__hero .categories__item--yellow {
  border-left: solid 5px #D9FF00;
}
.portfolio__hero .categories__item--white {
  border-left: solid 5px #FFFFFF;
}
.portfolio__hero__media img {
  width: 100%;
  object-fit: cover;
}
.portfolio__hero__teaser {
  padding-top: calc(1.09375rem + 1.6071428571vw);
  padding-bottom: calc(1.328125rem + 5.625vw);
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 1.5rem;
  column-gap: 6rem;
}
@media (min-width: 1400px) {
  .portfolio__hero__teaser {
    padding-top: 2.5rem;
  }
}
@media (min-width: 1400px) {
  .portfolio__hero__teaser {
    padding-bottom: 6.25rem;
  }
}
.portfolio__hero__teaser .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.portfolio__hero__teaser .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .portfolio__hero__teaser .headline__content {
    font-size: 2.875rem;
  }
}
.portfolio__hero__teaser .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .portfolio__hero__teaser .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.portfolio__hero__teaser__content {
  display: flex;
  flex-direction: column;
}
.portfolio__hero__teaser .headline .headline__content {
  margin-bottom: 0;
}
.portfolio__hero__teaser .headline, .portfolio__hero__teaser .categories, .portfolio__hero__teaser .text-block {
  grid-column: 1;
}
.portfolio__hero__teaser .text-block {
  position: relative;
  padding-top: calc(1.078125rem + 1.3392857143vw);
  margin-top: calc(1.078125rem + 1.3392857143vw);
  border-top: solid 1px #FFBFFF;
  position: relative;
}
@media (min-width: 1400px) {
  .portfolio__hero__teaser .text-block {
    padding-top: 2.25rem;
  }
}
@media (min-width: 1400px) {
  .portfolio__hero__teaser .text-block {
    margin-top: 2.25rem;
  }
}
.portfolio__hero__teaser .text-block::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  mask-image: url("~/public/img/_Icons/Star.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFBFFF;
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 50%;
}
.portfolio__hero__teaser .info {
  grid-row: 1/-1;
  grid-column: 2;
  overflow: hidden;
}
.portfolio__hero__teaser .info__item {
  background-color: #23003E;
  position: relative;
  padding-left: 60px;
  padding-block: 0.5rem;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
}
.portfolio__hero__teaser .info__item__title {
  text-transform: uppercase;
  color: #FFBFFF;
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
}
.portfolio__hero__teaser .info__item::before {
  content: "";
  height: calc(100% + 0.5rem);
  width: auto;
  border-radius: 50rem;
  background-color: #10002F;
  position: absolute;
  left: 0;
  transform: translate(-50%, -55%);
  top: 0;
  aspect-ratio: 1/1;
}
.portfolio__hero__teaser .info__item:last-of-type::after {
  content: "";
  height: calc(100% + 0.5rem);
  width: auto;
  border-radius: 50rem;
  background-color: #10002F;
  position: absolute;
  left: 0;
  transform: translate(-50%, 45%);
  top: 0;
  aspect-ratio: 1/1;
}
@media (max-width: 991.98px) {
  .portfolio__hero__teaser {
    grid-template-columns: 1fr;
  }
  .portfolio__hero__teaser .info {
    display: flex;
    flex-wrap: wrap;
    grid-column: 1;
    grid-row: 2;
  }
  .portfolio__hero__teaser .info__item {
    padding-right: 60px;
    padding-left: 30px;
    flex: unset;
    overflow: hidden;
  }
  .portfolio__hero__teaser .info__item::before {
    left: auto;
    right: 0;
    transform: translate(50%, -55%);
  }
  .portfolio__hero__teaser .info__item::after {
    content: "";
    height: calc(100% + 0.5rem);
    width: auto;
    border-radius: 50rem;
    background-color: #10002F;
    position: absolute;
    left: auto;
    right: 0;
    transform: translate(50%, 45%);
    top: 0;
    aspect-ratio: 1/1;
  }
  .portfolio__hero__teaser .info__item:last-of-type::after {
    left: auto;
    right: 0;
    transform: translate(50%, 45%);
  }
}

.project-list {
  display: grid;
  gap: calc(1.03125rem + 0.5357142857vw);
  grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 1400px) {
  .project-list {
    gap: 1.5rem;
  }
}
.project-list__header {
  padding-block: calc(1.1875rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .project-list__header {
    padding-block: 4rem;
  }
}
.project-list__header .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.project-list__header .headline__content {
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.15625rem + 2.6785714286vw);
  font-weight: 900;
  line-height: 1.1607142857;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .project-list__header .headline__content {
    font-size: 3.5rem;
  }
}
.project-list__header .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.1484375rem + 2.5446428571vw);
  font-weight: 400;
  line-height: 1.2037037037;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .project-list__header .headline__content .headline__accent {
    font-size: 3.375rem;
  }
}
.project-list__header .headline__content {
  margin-bottom: 0;
}
.project-list__item {
  box-shadow: 0px 48px 8px -48px rgba(0, 0, 0, 0.06), 0px 48px 80px 0px rgba(17, 12, 46, 0.12);
  background-color: #FFFFFF;
}
.project-list__item img {
  width: 100%;
  object-fit: cover;
}
.project-list__item__content {
  padding: 1rem;
}
.project-list__item__content .headline {
  grid-template: 1/-1;
}
.project-list__item__content .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.project-list__item__content .headline__content {
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 800;
  line-height: 1.1666666667;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .project-list__item__content .headline__content {
    font-size: 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .project-list {
    grid-template-columns: 1fr;
  }
}

.cta-project {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: calc(1.1875rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .cta-project {
    gap: 4rem;
  }
}
.cta-project__cta {
  grid-column: 1;
  display: flex;
  flex-direction: column;
  gap: calc(1.03125rem + 0.5357142857vw);
  align-self: center;
}
@media (min-width: 1400px) {
  .cta-project__cta {
    gap: 1.5rem;
  }
}
.cta-project__cta .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.cta-project__cta .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .cta-project__cta .headline__content {
    font-size: 2.875rem;
  }
}
.cta-project__cta .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .cta-project__cta .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.cta-project__cta .headline-content {
  margin-bottom: 0;
}
.cta-project__cta__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.cta-project__item {
  grid-column: 2;
  box-shadow: 0px 48px 8px -48px rgba(0, 0, 0, 0.06), 0px 48px 80px 0px rgba(17, 12, 46, 0.12);
  background-color: #FFFFFF;
}
.cta-project__item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cta-project__item__categories-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  transform: translateY(calc(-12px - 1rem));
}
.cta-project__item__category {
  padding: 2px 6px;
  font-family: "Gilory";
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.5384615385;
  letter-spacing: 0.02em;
  color: #4C4D6B;
  background-color: #FFFFFF;
}
.cta-project__item__category--primary {
  border-left: solid 3px #10002F;
}
.cta-project__item__category--secondary {
  border-left: solid 3px #5E05A8;
}
.cta-project__item__category--purple {
  border-left: solid 3px #5E05A8;
}
.cta-project__item__category--dark-purple {
  border-left: solid 3px #23003E;
}
.cta-project__item__category--red {
  border-left: solid 3px #FF006E;
}
.cta-project__item__category--gray {
  border-left: solid 3px #7F8095;
}
.cta-project__item__category--pink {
  border-left: solid 3px #FC00C4;
}
.cta-project__item__category--rose {
  border-left: solid 3px #FFBFFF;
}
.cta-project__item__category--blue {
  border-left: solid 3px #00EDD9;
}
.cta-project__item__category--green {
  border-left: solid 3px #57FF99;
}
.cta-project__item__category--yellow {
  border-left: solid 3px #D9FF00;
}
.cta-project__item__category--white {
  border-left: solid 3px #FFFFFF;
}
.cta-project__item__content {
  padding: 1rem;
}
.cta-project__item__content .headline {
  grid-template: 1/-1;
}
.cta-project__item__content .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.cta-project__item__content .headline__content {
  font-family: "Gilory";
  font-size: calc(1.03125rem + 0.5357142857vw);
  font-weight: 800;
  line-height: 1.1666666667;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .cta-project__item__content .headline__content {
    font-size: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .cta-project {
    grid-template-columns: 1fr;
  }
  .cta-project__item {
    grid-column: 1;
    grid-row: 2;
  }
}

.project-credits .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.project-credits .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .project-credits .headline__content {
    font-size: 2.875rem;
  }
}
.project-credits .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .project-credits .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.project-credits .headline {
  text-align: center;
}
.project-credits .headline__content {
  margin-bottom: 32px;
}
.project-credits__list {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.project-credits__list-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}
.project-credits__list-item--job {
  color: #FFBFFF;
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
  justify-self: flex-end;
}
@media (min-width: 1400px) {
  .project-credits__list-item--job {
    font-size: 1.25rem;
  }
}
.project-credits__list-item--name {
  justify-self: flex-start;
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
@media (min-width: 1400px) {
  .project-credits__list-item--name {
    font-size: 1.25rem;
  }
}

.section--contact .section--wrapper {
  position: relative;
  padding-block: calc(1.328125rem + 5.625vw);
  max-width: 1200px;
  margin-inline: auto;
}
@media (min-width: 1400px) {
  .section--contact .section--wrapper {
    padding-block: 6.25rem;
  }
}
.section--contact .section--wrapper::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: auto;
  right: 0;
  bottom: auto;
  mask-image: url("~/public/img/_Icons/_Grafiken/Ton/Grafiken-Ton-5.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #57FF99;
  height: 500px;
  width: 500px;
  transform: rotate(180deg) translate(-22%, 0px);
  z-index: 1;
  aspect-ratio: 1;
}
.section--contact .section--wrapper .section__inner {
  position: relative;
  z-index: 2;
}
@media (max-width: 1199.98px) {
  .section--contact .section--wrapper::after {
    top: auto;
    bottom: 0;
    transform: rotate(-90deg) translate(0px, 0px);
  }
}
@media (max-width: 575.98px) {
  .section--contact .section--wrapper {
    padding-bottom: 0;
  }
  .section--contact .section--wrapper::after {
    display: none;
  }
}

.contact {
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 650px);
  align-items: center;
  gap: calc(1.03125rem + 0.5357142857vw);
}
@media (min-width: 1400px) {
  .contact {
    gap: 1.5rem;
  }
}
.contact .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.contact .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.1171875rem + 2.0089285714vw);
  font-weight: 900;
  line-height: 1.1739130435;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .contact .headline__content {
    font-size: 2.875rem;
  }
}
.contact .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.109375rem + 1.875vw);
  font-weight: 400;
  line-height: 1.2272727273;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .contact .headline__content .headline__accent {
    font-size: 2.75rem;
  }
}
.contact__content {
  display: flex;
  flex-direction: column;
  gap: calc(1.09375rem + 1.6071428571vw);
}
@media (min-width: 1400px) {
  .contact__content {
    gap: 2.5rem;
  }
}
.contact__content__contact--phone a, .contact__content__contact--email a {
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}
@media (min-width: 1400px) {
  .contact__content__contact--phone a, .contact__content__contact--email a {
    font-size: 1.25rem;
  }
}
.contact__content__contact--phone::before {
  margin-right: 6px;
  content: "";
  display: inline-block;
  height: 24px;
  width: 24px;
  mask-image: url("~/public/img/_Icons/Call.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #57FF99;
  transform: translate(0px, 0px);
  position: relative;
  top: 6px;
  bottom: auto;
  left: auto;
  right: auto;
}
.contact__content__contact--email::before {
  margin-right: 6px;
  content: "";
  display: inline-block;
  height: 24px;
  width: 24px;
  mask-image: url("~/public/img/_Icons/Email.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #57FF99;
  transform: translate(0px, 0px);
  position: relative;
  top: 6px;
  bottom: auto;
  left: auto;
  right: auto;
}
.contact__content__social-headline {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  max-width: 40ch;
}
.contact__content__social__button {
  background: #FC00C4;
  padding: 4px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.contact__form {
  background-color: #F8F8F9;
  padding: calc(1.0625rem + 1.0714285714vw);
  position: relative;
}
@media (min-width: 1400px) {
  .contact__form {
    padding: 2rem;
  }
}
.contact__form::after {
  content: "";
  display: inline-block;
  height: 32px;
  width: 32px;
  mask-image: url("~/public/img/_Icons/Star-Half.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFFFFF;
  transform: translate(-100%, -50%);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 0;
  right: auto;
}
.contact__form .form-label {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #10002F;
}
.contact__form label[for=data-privacy] {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #7F8095;
}
@media (max-width: 1199.98px) {
  .contact {
    grid-template-columns: 1fr;
  }
  .contact__form::after {
    left: 50%;
    top: 0;
    transform: translate(-50%, -100%) rotate(90deg);
  }
}
@media (max-width: 575.98px) {
  .contact__form {
    margin-inline: -1rem;
  }
}

.section--thanks .headline, .section--thanks .text-block {
  text-align: center;
}
.section--thanks .text-block {
  max-width: 45ch;
  margin-inline: auto;
  margin-bottom: calc(1.09375rem + 1.6071428571vw);
}
@media (min-width: 1400px) {
  .section--thanks .text-block {
    margin-bottom: 2.5rem;
  }
}
.section--thanks .headline__title {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.4666666667;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.section--thanks .headline__content {
  color: inherit;
  text-transform: uppercase;
  font-family: "Gilory";
  font-size: calc(1.15625rem + 2.6785714286vw);
  font-weight: 900;
  line-height: 1.1607142857;
  letter-spacing: 0px;
}
@media (min-width: 1400px) {
  .section--thanks .headline__content {
    font-size: 3.5rem;
  }
}
.section--thanks .headline__content .headline__accent {
  font-family: "BelyDisplay";
  font-size: calc(1.1484375rem + 2.5446428571vw);
  font-weight: 400;
  line-height: 1.2037037037;
  letter-spacing: 0em;
}
@media (min-width: 1400px) {
  .section--thanks .headline__content .headline__accent {
    font-size: 3.375rem;
  }
}
.section--thanks .thanks__button {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-inline: auto;
  width: max-content;
  justify-content: center;
  background-color: #FFFFFF;
  padding-inline: calc(1.09375rem + 1.6071428571vw);
  padding-block: 1rem;
  position: relative;
  gap: 1rem;
}
@media (min-width: 1400px) {
  .section--thanks .thanks__button {
    padding-inline: 2.5rem;
  }
}
.section--thanks .thanks__button::after {
  content: "";
  display: inline-block;
  height: 32px;
  width: 32px;
  mask-image: url("~/public/img/_Icons/Star-Half.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #FFFFFF;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 0;
  bottom: auto;
  left: 50%;
  right: auto;
  transform: translate(-50%, -100%) rotate(90deg);
}
.section--thanks .thanks__button-label {
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #10002F;
  width: 35ch;
}
.section--thanks .thanks__button-cta {
  padding: 8px 24px;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  background-color: #FF006E;
  color: #FFFFFF;
  white-space: nowrap;
}
.section--thanks .thanks__button-cta img {
  filter: brightness(0) invert(1);
}
.section--thanks .thanks__button-cta::before {
  display: none;
}
@media (max-width: 575.98px) {
  .section--thanks .thanks__button {
    width: min-content;
  }
  .section--thanks .thanks__button-label {
    text-align: center;
  }
}

.magazin__info__date {
  color: #7F8095;
  font-family: "Gilory";
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.magazin__headline {
  font-family: "Gilory";
  font-size: calc(1.1015625rem + 1.7410714286vw);
  font-style: normal;
  font-weight: 800;
  line-height: 52px;
  margin-bottom: calc(1.03125rem + 0.5357142857vw);
}
@media (min-width: 1400px) {
  .magazin__headline {
    font-size: 2.625rem;
  }
}
@media (min-width: 1400px) {
  .magazin__headline {
    margin-bottom: 1.5rem;
  }
}
.magazin__teaser-text {
  font-family: "Gilory";
  font-size: calc(1.015625rem + 0.2678571429vw);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
  margin-bottom: calc(1.0625rem + 1.0714285714vw);
}
@media (min-width: 1400px) {
  .magazin__teaser-text {
    font-size: 1.25rem;
  }
}
@media (min-width: 1400px) {
  .magazin__teaser-text {
    margin-bottom: 2rem;
  }
}