.footer {
    @include rfs(80px, padding-top);
    @include rfs(40px, padding-bottom);
    @include headline($size:'lg', $color: $primary-white);

    &__headline {
        @include generate-label-styles('medium', 'bold');
        color: $primary-white;
        margin-bottom: 8px;
    }

    &__content {
        @include generate-label-styles('medium', 'medium');
        color: $primary-white;
    }

    &__nav {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;

        &-item:last-child &-link::after {
            display: none;
        }

        &-link {
            text-decoration: none;
            

            &::after {
                content: '|';
                color: $primary-rose;
                padding-inline: 4px;
                @include generate-label-styles('medium', 'medium');
            }
        }
    }

    &__contact {
        &-address {
            margin-bottom: 16px;
        }
        
        &-phone {
            display: flex;
            align-items: center;

            a {
                color: $primary-white;
            }

            &::before {
                margin-right: 6px;

                @include add-icon(
                    $icon: $icon-call,
                    $color: $primary-rose
                );
            }

        }

        &-email {
            display: flex;
            align-items: center;

            a {
                color: $primary-white;
            }
            
            &::before {
                margin-right: 6px;

                @include add-icon(
                    $icon: $icon-email,
                    $color: $primary-rose
                );
            }
        }

    }


    &__social-media {
        display: flex;
         gap: 7px;

        &--button {
            background: $primary-red;
            padding: 4px;
            width: 32px;
            height: 32px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
           
        }

        &--icon {
            filter: brightness(0) invert(1);
        }
    }

    @include media-breakpoint-down(lg) {
        &__social-media {
            display: grid;
            grid-template-columns: repeat(2, min-content);
        }
    }

    @include media-breakpoint-down(lg) {
        &__social-media {
            display: flex;
        }
    }

}

.copyright {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #10002F;
    display: flex;
    padding: 12px 0;
    justify-content: center;

    &__text {
        text-align: center;
        @include generate-body-styles('small', 'regular');
        margin-bottom: 0;
    }

    &__text, a {
        color: $primary-white;
    }

}