.project-testimonial {
    --gap: #{rfs-fluid-value(3rem)};
    --left-col: calc(40% - (var(--gap) / 2));
    --right-col: calc(60% - (var(--gap) / 2));

    display: grid;
    grid-template-columns: var(--left-col) var(--right-col);
    gap: var(--gap);
    @include headline(lg);
    width: 100%;

    .headline__content {
        margin-bottom: 0;
    }

    .swiper {
        max-width: 100%;
    }

    .swiper-slide {
        padding-left: 1rem;
        cursor: grab;
    }

    .swiper .swiper-pagination {
        justify-content: flex-start;
        padding-left: 1rem;
    }

    .swiper-pagination-bullet {
        background-color: $primary-white;
    }

    .swiper-pagination-bullet-active {
        background-color: $primary-red;
    }

    &__card {
        max-width: 100%;

        &__inner {
            background-color: $primary-white;
            position: relative;
            padding: map-get($map: $spacers, $key: 4);
            display: flex;
            flex-direction: column;
            height: 100%;
            color: $body-color;

            &::before {
                content: '';
                background-image: url($quotes);
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                height: 35px;
                width: 55px;
                margin-bottom: 1rem;
            }

            &::after {
                @include add-icon(
                    $icon: $icon-star-half,
                    $pseudo: true,
                    $color: $primary-white, 
                    $x: -100%,
                    $y: -50%,
                    $position: absolute,
                    $left: 0,
                    $top: 50%,
                    $height: 32px,
                    $widht: 32px,
                );    
            }
        }

        &__text {
            @include generate-body-styles(small, regular);
            flex: 1;
            padding-bottom: map-get($map: $spacers, $key: 3);
        }

        &__name {
            border-top: solid 1px $gray-200;
            @include generate-label-styles('small', 'medium');
            padding-top: map-get($map: $spacers, $key: 3);
            text-transform: uppercase;
        }
    }

    @include media-breakpoint-down(md) {
         --gap: 1rem;
        display: flex;
        flex-direction: column;

        .swiper-slide {
            padding-left: 0;
            padding-top: 1rem;
        }

        &__card {
            &__inner {
                &::after {
                    @include add-icon(
                        $icon: $icon-star-half,
                        $pseudo: true,
                        $color: $primary-white, 
                        $x: -50%,
                        $y: -50%,
                        $position: absolute,
                        $top: 0,
                        $left: 50%,
                        $height: 32px,
                        $widht: 32px,
                    );    

                    transform: translate(-50%, -100%) rotate(90deg);
                }   
            }
        }
    }
}