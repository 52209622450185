.lp-intro {
    position: relative;     
    display: grid;
    grid-template-columns: clamp(300px, 490px, 50%) 3fr;
    @include rfs(36px, padding-block);

    @each $color, $value in $theme-colors {   
        &--#{$color} {            
            @include icon-divider($variant: top, $color: map-get($theme-colors, $color));
            @include icon-divider($variant: bottom, $color: map-get($theme-colors, $color));
        }
    }
    
    &__headline {
        position: relative;
        margin-right: map-get($map: $spacers, $key: 5);
        padding-right: map-get($map: $spacers, $key: 5);

        .headline__content {
            @include generate-display-styles('large', 'extrabold');
        }
    }

    &__content {
        align-self: center;
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        grid-template-rows: min-content;

        @each $color, $value in $theme-colors {   
            &--#{$color} {            
                .service-intro__headline {
                    border-bottom: solid 1px $value;
                    border-right: 0;

                    &::after {
                        @include add-icon(
                            $icon: $icon-brand-star,
                            $pseudo: true,
                            $position: absolute,
                            $bottom: 0,
                            $right: 50%,
                            $color: $color,
                            $height: 16px,
                            $widht: 16px,
                            $x: 50%,
                            $y: 50%
                        );
                    }
                }
            }
        }

        &__headline {
            grid-row: 1;
            margin-right: 0;
            padding-right: 0;
            margin-bottom: map-get($map: $spacers, $key: 3);
            padding-bottom: map-get($map: $spacers, $key: 3);
        }

        &__content {
            grid-row: 2;
        }
    }
}