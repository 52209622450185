.project-next {
    display: flex;
    flex-direction: column;
    @include rfs(40px, gap);

    &__header-container {
        display: grid;
        grid-template-columns: 1fr max-content;
    }

    &__headline {
        .headline {
            &__title {
                @include generate-display-styles(xxsmall, medium);
            }

            &__content {
                color: $primary-white;
                @include generate-display-styles(large, extrabold);
            }
        }
    }

    &__cta-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__buttons-container {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        a {
            white-space: nowrap;
        }
    }

    &__teaser-text {
        .text-block {
            max-width: 45ch;
            @include generate-body-styles(large, regular);
        }
    }

    @include media-breakpoint-down(md) {
        &__header-container {
            grid-template-columns: 1fr;
        }
    }
}