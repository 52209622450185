.text-block {
    @include generate-body-styles(large, regular);
}











