@mixin link(
    $variant: 'link',
    $color: $primary-purple
) {
    @if $variant == 'link' {
        @include generate-label-styles('medium', 'bold');
        text-decoration: none;
        color: $color;
        display: flex;
        align-items: center;
        gap: $link-arrow-gap;
        transition: gap $link-transiton;
        cursor: pointer;

        &::after {
            content: '';
            mask-image: url($icon-arrow-right);
            mask-size: contain;
            mask-repeat: no-repeat;
            background-color: $color;
            width: $link-arrow-width;
            height: $link-arrow-height;
            display: inline-block;
        }

        &:hover {
            gap: $link-arrow-gap-hover;
        }
    }

    @if $variant == 'text-link' {

    }
}