.cta-contact-big {
    background-color: $primary-pink;
    position: relative;

    .container {
        position: relative;
        z-index: 2;
        padding-top: 80px;
        padding-bottom: 80px;

        
        &::after {
           content: '';
            @include add-shape(
                $top: 0,
                $right: 0,
                $image: $brand-light-3,
                $color: $primary-rose,
                $height: 100%,
                $rotate: 180deg,
                $index: -1,
                $x: -25%
            );
        }

    }

    .headline {
        color: $white;
        @include headline('lg', $primary_white);
    }

    .image {
        position: relative;
    }

    &__button {
        --padding-x: 40px;
        --padding-y: 16px;    
        --margin-top: 24px;    

        margin-top: var(--margin-top);
        padding: var(--padding-y) var(--padding-x);
        display: inline-flex;
        align-items: center;
        gap: var(--padding-x);
        background-color: $primary-white;
        position: relative;

         &::after {
            @include add-icon(
                $icon: $icon-brand-star,
                $pseudo: true,
                $color: $primary-white,
                $height: 24px,
                $widht: 24px,
                $y: -50%,
                $position: absolute,
                $top: 0,
                $left: var(--padding-x),
            );
        }
         
        &-label {
            @include generate-display-styles('small', 'semibold');
            color: $primary-dark-purple;
        }
    }
}