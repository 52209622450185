// TODO: add correct styling
.accordion {
    &-item {
        border-top: solid 1px $gray-200;
        border-bottom: solid 1px $gray-200;
        border-left: 0;
        border-right: 0;

        .accordion-button {
            @include generate-display-styles('small', 'extrabold');
            padding-inline: 0;

            &:after {
                background-image: none;
                mask-image: url($icon-chevron-down);
                mask-size: contain;
                mask-repeat: no-repeat;
                background-color: $primary-rose;
            }
        }

        &:last-of-type {
            border-bottom: 0;
        }
    }
}