@mixin create-button(
    $variant: 'primary',
    $color: 'red'
) {
    // Variant Maps
    $variants: map-get($map: $button-variants, $key: $variant);
    $variant-properties: map-get($map: $variants, $key: $color);

    // Default Styles
    padding: $button-default-padding-y $button-default-padding-x;
    @include generate-label-styles(medium, bold);
    transition: all $button-transiton;
    text-decoration: none;
    position: relative;
    display: inline-block;
    border: solid $button-default-border-width;

    // Icon
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: calc($button-default-padding-x / 2);
        transform: translateY(-50%);
        display: inline-block;
        height: $button-default-icon-height;    
        width: $button-default-icon-width;    
        mask-image: url($button-default-icon);
        mask-size: contain;
        mask-repeat: no-repeat;
        padding-right: $button-default-icon-spacing-right;
        opacity: 0;
        transition: opacity $button-transiton;
    }

    // Hover Animation
    &:hover {
        padding-left: calc(($button-default-padding-x / 2) + $button-default-icon-width + $button-default-icon-spacing-right);
        padding-right: calc(($button-default-padding-x / 2) + $button-default-icon-spacing-right);

        &::before {
            opacity: 1;
        }
    }

    // Variant Specifc Styles
    background-color: map-get($map: $variant-properties, $key: background);
    color: map-get($map: $variant-properties, $key: color);
    border-color: map-get($map: $variant-properties, $key: borderColor);
    &::before { background-color: map-get($map: $variant-properties, $key: iconColor); }
}