.social-banner {
    max-width: $breakpoint-lg;
    margin-inline: auto;
    position: relative;

    &__container-inner {
        @include rfs(6.5rem, padding-block);
        @include make-container();
        max-width: $container-inner-max-width;
        display: grid;
        grid-template-columns: 1fr;
    }

    &__background-graphic {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;

        .image, img {
            height: 100%;
        }
    }

    &__container {
        grid-column: 1;
        grid-row: 1;
        align-self: center;
        display: grid;
        grid-template-columns: auto 3rem auto;
        z-index: 2;
    }

    &__background-image {
        grid-column: 1 / span 2;
        grid-row: 1;

        .image, img {
            height: 100%;
        }

        img {
            object-fit: cover;
        }
    }

    &__card {
        background-color: $primary-white;
        padding: 3.5rem 2.5rem;
        grid-column: 2 / span 2;
        grid-row: 1;
        align-self: center;
        justify-self: flex-end;
        min-width: 24.5rem;
        text-align: center;
        display: grid;
        gap: 1.5rem;
        position: relative;
        margin-block: 3rem;

        @include headline('md', $primary-black);

        &::after {
            @include add-icon(
                $icon: $icon-star-half,
                $pseudo: true,
                $color: $primary-white, 
                $x: -100%,
                $y: -50%,
                $position: absolute,
                $left: 0,
                $top: 50%,
                $height: 32px,
                $widht: 32px,
            );    
        }

        &__icons {
            list-style: none;
            display: flex;
            margin-bottom: 0;
            justify-content: center;
            padding-left: 0;

            &__item {
                padding-right: 0.5rem;

                &:last-child {
                    padding-right: 0;
                }

                &__link {
                    background-color: $primary-pink;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.25rem;
                    height: 40px;
                    width: 40px;

                    &__icon {
                        filter: brightness(0) invert(1);
                    }
                }
            }
        }   
    }


    @include media-breakpoint-down(md) {
        &__container-inner {
            padding-block: 0;
        }

        &__background-image {
            display: none;
        }

        &__container {
            grid-template-columns: 1fr;
            padding-inline: 0;
        }

        &__card {
            grid-column: 1;
            min-width: auto;
        }
    }

    // @include media-breakpoint-down(sm) {
    // }
}