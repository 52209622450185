// TODO: add correct styling

@keyframes move-words {
    0% {
        left: 100%;
    }
    100% {
        left: -100%;
    }
}

.cta-contact-with-text {
    &__header {
        @include headline('xl', $primary-dark-purple);

        .headline {
            background-color: $primary-yellow;
            white-space: nowrap;

            &__content {
                position: relative;
                animation: move-words 20s linear infinite;
                margin: 0;
            }
        }
    }

    &__inner {
        padding-top: map-get($map: $spacers, $key: 5);
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: map-get($map: $spacers, $key: 4);
    }
}