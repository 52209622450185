.cta-application {
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.5rem;
        height: 100%;

        @include headline(md);

        .text-block {
            @include generate-body-styles('large', 'regular');
        }
    }


    &__contact-person {
        &__header {
            img {
                width: 100%;
                object-fit: cover;
            }
        }

        &__body {
            background-color: $primary-rose;
            padding: 1.5rem;
        }

        &__title {
            @include generate-label-styles('medium', 'medium');
            color: $primary-purple;
        }

        &__name {
            @include generate-display-styles('small', 'extrabold');
            margin-bottom: 1rem;
        }

        &__contact {
            &.email, &.phone {
                display: flex;
                align-items: center;
                @include generate-label-styles('medium', 'medium');
            }

            &.email {
                &::before {
                    margin-right: 6px;

                    @include add-icon(
                        $icon: $icon-email,
                        $color: $primary-white
                    );
                }
            }

            &.phone {
                &::before {
                    margin-right: 6px;

                    @include add-icon(
                        $icon: $icon-call,
                        $color: $primary-white
                    );                
                }
            }
        }
    }
}