@mixin headline(
    $size: 'md',
    $color: inherit,
    $accent: '',
    $star: false
) {
    .headline {
        &__title {
            @include generate-display-styles(xxsmall, medium);
            text-transform: uppercase;
            margin-bottom: 0.25rem;
        }
        
        @if $accent != '' {
            &__title {
                color: $accent;
            }

            &__accent {
                color: $accent
            }
        } 


        &__content {
            color: $color;
            
            @if $size == 'xxl' {
                text-transform: uppercase;
                @include generate-display-styles(hero, heavy);

                .headline__accent {
                    @include generate-accent-styles(hero);
                }
            } 

            @else if $size == 'xl' {
                text-transform: uppercase;
                @include generate-display-styles(huge, heavy);
                
                .headline__accent {
                    @include generate-accent-styles(huge);
                }
            }

            @else if $size == 'lg' {
                text-transform: uppercase;
                @include generate-display-styles(large, heavy);
                
                .headline__accent {
                    @include generate-accent-styles(large);
                }
            }

            @else if $size == 'md' {
                @include generate-display-styles(medium, extrabold);
                
                .headline__accent {
                    @include generate-accent-styles(medium);
                }
            }

            @if $star == true {
                &::after {
                    @include add-icon(
                        $pseudo: true,
                        $icon: $icon-brand-star,
                        $color: $primary-red,
                        $height: 20px,
                        $widht: 20px,
                        $x: 4px,
                        $y: 9px
                    );
                }
            }
        }
    }     
};