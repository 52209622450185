.media-customer-slider {
    --crown-size: 32px;
    @include headline('lg');

    .headline {
        position: relative;
        margin-bottom: map-get($map: $spacers, $key: 4);

        &__content {
            text-align: center;
        }

        @each $color, $value in $theme-colors {   
            &--#{$color} {            
                &::before{
                    @include add-icon(
                        $icon: $brand-crown-up,
                        $pseudo: true,
                        $color: $value,
                        $height: var(--crown-size),
                        $widht: var(--crown-size),
                        $position: relative,
                        $left: 50%,
                        $x: -50%,
                    );
                }
            }
        }
    }

    &__slide {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        height: 100%;
        cursor: grab;        

        &-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            margin: 6px;
            padding: 10px;
            box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.2);    
            background-color: $primary-white;
        }
    }
}