.lp-about {
    position: relative;
    max-width: $breakpoint-lg;
    margin-inline: auto;
    
    &::after {
        content: '';
        @include add-shape($image: $brand-light-3, $width: auto, $height: 100%, $rotate: 180deg, $x: -17%, $top: 0, $right: 0, $color: $primary-purple);
    }

    &__inner-container {
        // @include add-section-spacing('y', 'md');
        @include rfs(100px, padding-block);
        @include make-container();
        max-width: $container-inner-max-width;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        @include rfs(3rem, padding-right);


        .headline {
            &__title {
                @include generate-display-styles(xxsmall, medium);
                text-transform: uppercase;
            }

            &__content {
                @include generate-display-styles(large, extrabold);
                text-transform: unset;
            }
        }

        .text-block {
            @include generate-body-styles('medium', 'regular');
        }
    }

    &__image {
        position: relative;
        z-index: 2;
    }

    @include media-breakpoint-down(md) {
        &::after {
            transform: rotate(180deg, translateX(0));
        }

        &__content {
            padding-right: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        &::after {
            height: 70%;
        }
    }
}