.card-services-detail {
    @include headline('lg');

    .headline {
        margin-bottom: map-get($map: $spacers, $key: 4);
    }

    .swiper-slide {
        display: flex;
    }

    &__card {
        grid-column: 1;
        grid-row: 5;
        align-self: flex-end;
        background-color: $primary-white;
        margin-top: 16px;
        padding: 24px;
        align-self: stretch;
        position: relative;;
        display: flex;
        flex-grow: 1;
        flex-direction: column;


        // TODO: create mixin/util
        @each $value, $color in $theme-colors {
            &.#{$value}::after {
                background-color: $color;
            }
        }

         &::after {
            @include add-icon(
                $icon: $icon-brand-star,
                $pseudo: true,
                $color: $primary-purple,
                $height: 24px,
                $widht: 24px,
                $x: 0,
                $y: -50%,
                $position: absolute,
                $top: 0,
                $left: 24px
            );
        }

        &-title {
            @include generate-display-styles('small', 'extrabold');
            color: $primary-dark-purple;
            margin-bottom: 2px;
        }

        &-description {
            p { @include generate-body-styles('medium', 'regular'); }
            color: $primary-gray;
            max-height: 180px;
            flex: 1;
        }

        &-link {
            display: flex;
            flex: 1;
            margin-top: map-get($map: $spacers, $key: 3);
            justify-content: flex-end;
            align-items: flex-end;
            a { @include link('link'); }  
        }
    }

    @include media-breakpoint-down(md) {
        margin-right: -1rem;
    }
}