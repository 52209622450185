.image-sticker {
    &__container {
        position: relative;
    }

    &__sticker {
        position: absolute;

        &--top_left {
            top: 0;
            left: 0;
            transform: translate(-25%, -35%) rotate(-20deg);
        }
        &--top_right{
            top: 0;
            right: 0;
        }
        &--center_left {
            top: 50%;
            left: 0;
        }
        &--center_right {
            top: 50%;
            right: 0;
        }
        &--bottom_left {
            bottom: 0;
            left: 0;
        }
        &--bottom_right {
            bottom: 0;
            right: 0;
        }
    }

    &__image {
        object-fit: cover;
        width: 100%;
        max-height: 700px;
    }

    @include media-breakpoint-down(md) {
        &__image {
            max-height: 600px;
        }
    }

    @include media-breakpoint-down(sm) {
        width: auto;
        max-height: unset;
    }
}
