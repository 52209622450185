* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    font-feature-settings: 'clig' off, 'liga' off;

    main {
        flex-grow: 1;
    }
}

p:last-child,
p:only-child {
    margin-bottom: 0;
}

a {
    text-decoration: none;
}
