// Assets
$image-1_shape_1: $brand-motion-4;
$image-2_shape_1: $brand-light-1;
$image-2_shape_2: $brand-motion-2;
$image-3_shape_1: $brand-motion-3;
$image-3_shape_2: $brand-sound-1;

// Sizes
$image-1_shape_1_size: clamp(100px, 15vw, 237px);
$image-2_shape_1_size: clamp(100px, 15vw, 217px);
$image-2_shape_2_size: clamp(100px, 15vw, 267px);
$image-3_shape_1_size: clamp(100px, 15vw, 290px);
$image-3_shape_2_size: clamp(100px, 15vw, 303px);


.home-hero {
    &__grid-gallery {
        max-width: 1800px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(25, 1fr);
        grid-template-rows: repeat(23, clamp(30px, 3vw, 55px));

        .image {
            position: relative; 
            z-index: 1;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.5);
            }

             &--1 {
                grid-column: 2 / span 5;
                grid-row: 1 / span 10;
                position: relative;

                &::after {
                    content: '';
                    @include add-shape(
                        $height:  $image-1_shape_1_size,
                        $width:  $image-1_shape_1_size,
                        $image: $image-1_shape_1, 
                        $index: 2,
                        $top: 0,
                        $left: 0,
                        $color: $primary-blue,
                        $rotate: 90deg,
                        $x: -8%,
                        $y: 19%,
                    );
                }
            }
            
            &--2 {
                grid-column: 18 / span 7;
                grid-row: 1 / span 15;

                &::before {
                    content: '';
                    @include add-shape(
                        $height: $image-2_shape_1_size,
                        $width: $image-2_shape_1_size,
                        $top: 0,
                        $left: 0,
                        $image: $image-2_shape_1,
                        $color: $primary-pink,
                        $rotate: 90deg,
                        $x: 20%,
                        $y: 17%,
                        $index: 1
                    );
                }


                &::after {
                    content: '';
                    @include add-shape(
                        $height: $image-2_shape_2_size,
                        $width: $image-2_shape_2_size,
                        $bottom: 0,
                        $left: 0,
                        $image: $image-2_shape_2,
                        $color: $primary-green,
                        $rotate: -180deg,
                        $x: 30%,
                        $y: -15%
                    );
                }
            }

            &--3 {
                grid-column: 5 / span 17;
                grid-row: 13 / span 6;

                &::before {
                    content: '';
                    @include add-shape(
                        $height: $image-3_shape_1_size,
                        $width: $image-3_shape_1_size,
                        $top: 0,
                        $left: 0,
                        $image: $image-3_shape_1,
                        $color: $primary-yellow,
                        $x: 34%,
                        $y: -68%
                    );
                }

                &::after {
                    content: '';
                    @include add-shape(
                        $height: $image-3_shape_2_size,
                        $width: $image-3_shape_2_size,
                        $bottom: 0,
                        $left: 0,
                        $image: $image-3_shape_2,
                        $color: $primary-green,
                        $rotate: -90deg,
                        $x: -66%,
                        $y: -88%,
                        $index: -1
                    );
                }
            }

            &--4 {
                grid-column: 23 / span 3;
                grid-row: 10 / span 13;
            }
        }

        .grid-gallery__video {
            grid-column: 8 / span 11;
            grid-row: 3 / span 18;
            z-index: 99;
            position: relative;

            .scale-container {
                position: absolute;
                height: 100%;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                min-width: 100%;
                max-width: 100vw;
                max-height: 100vh;
            }

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                mask-image: url($hero-video-mask);
                mask-repeat: no-repeat;
                mask-size: cover;
                mask-position: center right;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .image.image--1 {
            grid-column: 2 / span 7;
            grid-row: 1 / span 11;
        }

        .image.image--3 {
            grid-column: 3 / span 19;
        }

        .grid-gallery__video {
            grid-column: 6 / span 14;
            grid-row: 2 / span 20;
        }
    }

    @include media-breakpoint-down(sm) {
        .image.image--1 {
            grid-row: 3 / span 11;
        }

        .image.image.image--2 {
            grid-row: 3 / span 15;
        }

        .image.image--3 {
            grid-row: 16 / span 6;
        }

        .image.image--4 {
            grid-row: 13 / span 13;
        }

        .grid-gallery__video {
            grid-column: 3 / span 21;
            grid-row: 1 / span 20;
        }
    }
}
