.card {
    // &--excerpt {

    // }

    // &--teaser {

    // }

    // @each $value, $color in $theme-colors {
    //     &.#{$value}::after {
    //         background-color: $color;
    //     }
    // }

    background-color: $primary-white;
    padding: $card-padding;
    border: 0;

    &__body {
        display: flex;
        flex-direction: column;
    }
    
    &__title {
        @include generate-display-styles('xxsmall','medium');
        text-transform: uppercase;
        color: $primary-purple;
        margin-bottom: $card-title-margin-bottom;
    }
     
    &__headline {
        @include generate-display-styles('large', 'extrabold');
        margin-bottom: $card-headline-margin-bottom;
    }

    &__link {
        align-self: flex-end;
        @include link('link');
    }
}