.card-services {
    .headline {
        text-align: center;
        @include headline('lg');

        &__content {
            color: $primary-white;
            margin-bottom: 32px;
        }
    }

    &__slider {
        display: flex;
        flex-wrap: nowrap;
        // grid-template-columns: 1fr;
        // overflow-x: scroll;
        overflow: hidden;
        scrollbar-width: none;
        height: 100vh;
        height: 100svh;
        max-height: 100vh;
        max-height: 100svh;
    }

    &__item {
        display: grid;
        grid-template-rows: repeat(5, 1fr);
        grid-row: 1;
        // width: clamp(250px, 25vw, 750px);
        min-width: 30vw;
        // height: clamp(678px, 100vh, 1500px);
        height: 100vh;
        height: 100svh;
        max-height: 100vh;
        max-height: 100svh;
    }

    &__image {
        grid-column: 1;
        grid-row: 1 / -1;
        display: flex;
        grid-template-columns: 1;
        align-items: stretch;

        .image {
            flex: 1;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    &__card {
        grid-column: 1;
        grid-row: 5;
        align-self: flex-end;
        background-color: $primary-white;
        margin: 16px;
        padding: 24px;
        height: clamp(185px, 200px, 250px);
        align-self: stretch;
        position: relative;;
        display: flex;
        flex-direction: column;


        // TODO: create mixin/util
        @each $value, $color in $theme-colors {
            &.#{$value}::after {
                background-color: $color;
            }
        }

         &::after {
            @include add-icon(
                $icon: $icon-brand-star,
                $pseudo: true,
                $color: $primary-purple,
                $height: 24px,
                $widht: 24px,
                $x: 0,
                $y: -50%,
                $position: absolute,
                $top: 0,
                $left: 24px
            );
        }

        &-title {
            @include generate-display-styles('small', 'extrabold');
            color: $primary-dark-purple;
            margin-bottom: 2px;
        }

        &-description {
            @include generate-body-styles('medium', 'regular');
            color: $primary-gray;
            max-height: 180px;
            flex: 1;
        }

        &-link {
            display: flex;
            justify-content: flex-end;

            a {
                @include link('link');  
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__item {
            min-width: 80vw;
        }
    }

    @include media-breakpoint-down(sm) {
        &__item {
            min-width: 100vw;
        }
    }
}