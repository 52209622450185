.section--contact .section--wrapper {
    position: relative;
    @include rfs(100px, padding-block);
    max-width: $breakpoint-lg;
    margin-inline: auto;

    &::after {
        content: '';
        @include add-shape($image: $brand-sound-5, $width: 500px, $height: 500px, $rotate: 180deg, $x: -22%, $top: 0, $right: 0, $color: $primary-green);
    }

    .section__inner {
        position: relative;
        z-index: 2; 
    }

    @include media-breakpoint-down(lg) {
        &::after {
            top: auto;
            bottom: 0;
            transform: rotate(-90deg) translate(0px, 0px);
        }
    }

    @include media-breakpoint-down(sm) {
        &::after {
            display: none;
        }

        padding-bottom: 0;
    }
}

.contact {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 650px);
    align-items: center;
    @include rfs(24px, gap);

    @include headline(lg);
    

    &__content {
        display: flex;
        flex-direction: column;
        @include rfs(40px, gap);

        &__contact {
            &--phone a, &--email a {
                @include generate-body-styles('large', 'regular');
                color: $primary-white;
            }

            &--phone {
                &::before {
                    margin-right: 6px;

                    @include add-icon(
                        $icon: $icon-call,
                        $color: $primary-green,
                        $top: 6px,
                        $widht: 24px,
                        $height: 24px,
                    );
                }
            }

            &--email {
                &::before {
                    margin-right: 6px;

                @include add-icon(
                    $icon: $icon-email,
                    $color: $primary-green,
                    $top: 6px,
                    $widht: 24px,
                    $height: 24px,
                );
            }
        }

        }

        &__social {
            &-headline {
                @include generate-display-styles('xxsmall', 'medium');
                text-transform: uppercase;
                max-width: 40ch;
            }

            &__button {
                background: $primary-pink;
                padding: 4px;
                width: 32px;
                height: 32px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &__form {
        background-color: $gray-1;
        @include rfs(32px, padding);
        position: relative;

        &::after {
            @include add-icon(
                $icon: $icon-star-half,
                $pseudo: true,
                $color: $primary-white, 
                $x: -100%,
                $y: -50%,
                $position: absolute,
                $left: 0,
                $top: 50%,
                $height: 32px,
                $widht: 32px,
            );    
        }

        .form-label {
            @include generate-label-styles('medium', 'bold');
            color: $primary-black;
        }

        label[for=data-privacy] {
            @include generate-body-styles('medium', 'regular');
            color: $primary-gray;
        }
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;

        &__form {
            &::after {
                left: 50%;
                top: 0;
                transform: translate(-50%, -100%) rotate(90deg);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__form {
            margin-inline: -1rem;
        }
    }
}