.cta-text-with-image {
    display: grid;
    grid-template-columns: minmax(auto, ($container-inner-max-width / 2.5)) auto;
    @include rfs(6rem, gap);
    align-items: center;

    @each $container, $width in $container-max-widths {
        margin-left: calc(50vw - ($width / 2) + ($container-padding-x / 2));
    }

    &__content {
        display: flex;
        flex-direction: column;
        @include rfs(24px, gap);

        .headline__content {
             @include generate-display-styles('large', 'extrabold');
        }

        p {
            @include generate-body-styles('medium', 'regular');
        }
    }

    &__links {
        display: flex;
        @include rfs(24px, gap);
        flex-direction: column;
    }

    &__link {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        position: relative;
        @include rfs(19px, padding);

        // TODO: create mixin/util
        @each $value, $color in $theme-colors {
            &.#{$value}::after {
                background-color: $color;
            }
        }

         &::after {
            @include add-icon(
                $icon: $icon-brand-star,
                $pseudo: true,
                $color: $primary-purple,
                $height: 24px,
                $widht: 24px,
                $x: 0,
                $y: -50%,
                $position: absolute,
                $top: 0,
                $left: 24px
            );
        }
    }

    &__image {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-template-rows: 1fr;
        height: 100%;

        .image {
            grid-row: 1;
            position: relative;

            img {
                -webkit-box-shadow: 4px 4px 15px 5px rgba(0,0,0,0.37); 
                box-shadow: 4px 4px 15px 5px rgba(0,0,0,0.37);
                object-fit: cover;
                height: 100%;
            }

            // Small Image
            &:first-child {
                grid-column: 1 / 6;
                align-self: center;
                z-index: 1;
            }

            // Big Image
            &:last-child {
                grid-column: 2 / -1;;

            }
        }
    }

    @include media-breakpoint-down(lg) {
        margin-left: 0;
        padding-left: $container-padding-x;
        grid-template-columns: minmax(auto, ($container-inner-max-width / 3)) auto;
        @include rfs(3rem, gap);
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-template-rows: min-content;
        padding-right: $container-padding-x;

        &__content {
            grid-column: 1;
            grid-row: 2;
        }

        &__image {
            grid-column: 1;
            grid-row: 1;

            img {
                max-width: 100%;
            }
        }
    }

     @include media-breakpoint-down(sm) {
        padding-inline: 1rem;
     }
}