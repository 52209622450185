.card-usp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include rfs(1.5rem, gap);

    &__intro {
        grid-column: 1 / -1;
        grid-row: 1;
        text-align: center;
        @include headline(lg);
        @include rfs(50px, padding-inline);
        @include rfs(50px, padding-bottom);
    }

    &__item {
        background-color: $primary-white;
        border-top: solid 1px $primary-red;
        position: relative;
        padding: map-get($map: $spacers, $key: 3);
        height: 100%;

        .headline__content {
            @include generate-display-styles('small', 'semibold');
        }

        .text-block {
            @include generate-body-styles('small', 'regular');
        }

        &::after {
            @include add-icon(
                $icon: $icon-brand-star,
                $pseudo: true,
                $position: absolute,
                $top: 0,
                $left: 50%,
                $color: $primary-red,
                $height: 16px,
                $widht: 16px,
                $x: -50%,
                $y: -50%
            );
        }
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        grid-template-rows: min-content;       
    }
}