.media-comparison-slider {
    position: relative;
    padding-bottom: 100vh;

    .before, .after {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            object-fit: cover;
        }
    }

    .after {
        position: absolute;
        overflow: hidden;
        top: 0;
        transform: translate(100%, 0px);
    }
}
