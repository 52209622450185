.swiper {
    .swiper-slide {
        height: auto;
    }
    
    .swiper-pagination {
        display: flex;
        justify-content: center;
        margin-top: map-get($map: $spacers, $key: 3);

        &-bullet {
            position: relative;
            margin: 0.1rem;

            @include add-icon(
                $icon: $icon-brand-star,
                $pseudo: true,
                $color: $primary-gray,
                $height: 10px,
                $widht: 10px,
                $position: relative,
            );

            &-active {
                @include add-icon(
                    $height: 10px,
                    $widht: 10px,
                    $color: $primary-purple,
                );    
            }
        }
    }
}