.media-collage {
    &__header {
        text-align: center;

        .headline__content {
            @include generate-display-styles('large', 'extrabold');
            color: $primary-white;
            @include rfs(24px, margin-bottom);
        }
    }

    &__scroll-container {
        overflow-y: auto;
        overflow-y: hidden;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        @include rfs(24px, gap);
    }

    &__column {
        display: flex;
        flex-direction: column;
        @include rfs(24px, gap);
        justify-content: center;
    }

    &__item {
        &.media-collage__item--col1 {
            &.image--1 {
                img, video {
                    aspect-ratio: 270/369;
                }
            }

            &.image--2 {
                img, video {
                    aspect-ratio: 270/184;
                }
            }
        }

        &.media-collage__item--col2 {
            &.image--1 {
                img, video {
                    aspect-ratio: 564/251;
                }
            }

            &.image--2 {
                img, video {
                    aspect-ratio: 564/370;
                }
            }
        }

        &.media-collage__item--col3 {
            &.image--1 {
                img, video {
                    aspect-ratio: 270/184;
                }
            }

            &.image--2 {
                img, video {
                    aspect-ratio: 270/343;
                }
            }
        }

        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            display: flex;
        }
    }
}