.navbar-nav {
    background-color: $primary-white;
    display: flex;
    @include rfs(1.5rem, gap);

    .nav-button {      
        display: flex;
        align-items: center;
    }
    
    .dropdown-menu {
        margin-top: 0;
        left: 24px * -1;
    }

    .dropdown-toggle {
        transition: none;
        
        &::after {
            color: $primary-purple;
        }
    }

    .dropdown-item {
        font-weight: 500;
        line-height: 1.6;
        padding-inline: 24px;
        padding-block: 12px;
        color: $gray-5;
        position: relative;
        display: flex;
        align-items: center;
        gap: 6px;
        transition: gap 0.3s linear, color 0.3s linear;

        &:active {
            background-color: $primary-white;
            color: $gray-5;
        }

        @each $value, $color in $theme-colors {
            &.#{$value}::before {
                background-color: $color;
            }
        }

         &::before {
            @include add-icon(
                $icon: $icon-brand-star,
                $pseudo: true,
                $color: $primary-purple,
                $height: 12px,
                $widht: 12px,
                $x: 0,
                $position: relative,
            );
        }

        &:hover {
            gap: 10px;
            color: $primary-dark-purple;
        }
    }

    @include media-breakpoint-down(md) {
        background-color: transparent;
        gap: 0;

        .dropdown {
            margin-inline: calc(#{var(--bs-offcanvas-padding-y)} * -1);
        }

        .dropdown-menu, .dropdown-toggle {
            padding-inline: var(--bs-offcanvas-padding-y);
        }

        .dropdown-toggle.show {
            background-color: $primary-white;
        }
    }
}

.header > .navbar {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);

    .navbar-buttons {
        display: flex;
        align-items: center;
        gap: 0.6rem;
    }

    .navbar-toggler {
        border: 0;
    }

    @include media-breakpoint-down(md) {
        @include rfs(8px, padding-block)
    }
}
