@mixin add-shape(
  $top: auto,
  $left: auto,
  $right: auto,
  $bottom: auto,
  $image,
  $color: $primary-red,
  $height: auto,
  $width: auto,
  $rotate: 0,
  $x: 0px,
  $y: 0px,
  $index: 1,
  $repeat: no-repeat,
  $position: absolute,
  $aspect-ratio: 1 / 1
) {
  position: $position;
  display: block;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
  mask-image: url($image);
  mask-size: contain;
  mask-repeat: $repeat;
  background-color: $color;
  height: $height;
  width: $width;
  transform: rotate($rotate) translate($x, $y);
  z-index: $index;
  aspect-ratio: $aspect-ratio;
  @content;
}
