.media-collage-fullwidth {
    &__header {
        @include rfs(40px, margin-bottom);
        text-align: center;

        .headline__content {
            @include generate-display-styles('large', 'extrabold');
        }
    }

    &__grid {
        display: grid;
        gap: $spacer;
        grid-template-areas: 
        "small_1 medium_1 small_2 small_3 medium_2"
        "medium_3 medium_1 big_1 big_1 medium_2"
        "medium_3 small_4 big_1 big_1 small_5"; 

        .image {
            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }

            &.image--small_1 {
                grid-area: small_1;
            }

            &.image--small_2 {
                grid-area: small_2;
            }

            &.image--small_3 {
                grid-area: small_3;
            }

            &.image--small_4 {
                grid-area: small_4;
            }

            &.image--small_5 {
                grid-area: small_5;
            }

            &.image--medium_1 {
                grid-area: medium_1;
            }

            &.image--medium_2 {
                grid-area: medium_2;
            }

            &.image--medium_3 {
                grid-area: medium_3;
            }

            &.image--big {
                grid-area: big_1;                
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__grid {
            grid-template-areas: 
                "small_1 medium_1 small_2"
                "medium_2 medium_1 medium_3"
                "medium_2 small_3 medium_3"
                "small_4 big_1 big_1"
                "small_5 big_1 big_1"; 
        }
    }

    @include media-breakpoint-down(sm) {
        &__header {
            text-align: left;
            padding-inline: 1rem;
        }

        &__grid {
            grid-template-areas: 
                "small_1 medium_2"
                "medium_1 medium_2"
                "medium_1 small_2"; 


            .image--small_3, .image--small_4, .image--small_5, .image--medium_3, .image--big {
                display: none;
            }
        }
    }
}