.portfolio {
    &__hero {
        .categories {
            display: flex;
            gap: 4px;
            margin-bottom: 1rem;
            flex-wrap: wrap;

            &__item {
                background-color: $primary-white;
                padding-inline: map-get($map: $spacers, $key: 2);
                padding-block: map-get($map: $spacers, $key: 1);
                color: $primary-gray;
                @include generate-label-styles(medium, bold);

                @each $value, $color in $theme-colors {
                    &--#{$value} {
                        border-left: solid 5px $color;
                    }
                }

            }
        }

        &__media {
            img {
                width: 100%;
                object-fit: cover
            }
        }

        &__teaser {
            @include rfs(2.5rem, padding-top);
            @include rfs(6.25rem, padding-bottom);
            display: grid;
            grid-template-columns: 1fr auto;
            @include headline(lg);
            row-gap: map-get($map: $spacers, $key: 4);
            column-gap: map-get($map: $spacers, $key: 6);

            &__content {
                display: flex;
                flex-direction: column;
            }

            .headline .headline__content {
                margin-bottom: 0;
            }

            .headline, .categories, .text-block {
                grid-column: 1;
            }

            .text-block {
                position: relative;
                @include rfs(2.25rem, padding-top);
                @include rfs(2.25rem, margin-top);

                @include icon-divider($variant: top, $color: $primary-rose);
            }

            .info {
                grid-row: 1 / -1;
                grid-column: 2;
                overflow: hidden;
                
                &__item {
                    background-color: $primary-dark-purple;
                    position: relative;
                    padding-left: 60px;
                    padding-block: map-get($map: $spacers, $key: 2);
                    padding-right: 30px;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    align-self: stretch;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                    @include generate-label-styles(small, medium);

                    &__title {
                        text-transform: uppercase;
                        color: $primary-rose;
                        @include generate-label-styles(small, medium);
                    }

                    // Circle
                    &::before {
                        content: '';
                        height: calc(100% + 0.5rem);
                        width: auto;
                        border-radius: 50rem;
                        background-color: #10002F;
                        position: absolute;
                        left: 0;
                        transform: translate(-50%, -55%);
                        top: 0;
                        aspect-ratio: 1 / 1;
                    }

                    // Last Cricle
                    &:last-of-type {
                        &::after {
                            content: '';
                            height: calc(100% + 0.5rem);
                            width: auto;
                            border-radius: 50rem;
                            background-color: #10002F;
                            position: absolute;
                            left: 0;
                            transform: translate(-50%, 45%);
                            top: 0;
                            aspect-ratio: 1 / 1;
                        }
                    }

                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__hero {
            &__teaser {
                grid-template-columns: 1fr;

                .info {
                    display: flex;
                    flex-wrap: wrap;
                    grid-column: 1;
                    grid-row: 2;


                    &__item {
                        padding-right: 60px;
                        padding-left: 30px;
                        flex: unset;
                        overflow: hidden;

                        &::before {
                            left: auto;
                            right: 0;
                            transform: translate(50%, -55%);
                        }

                        &::after {
                            content: '';
                            height: calc(100% + 0.5rem);
                            width: auto;
                            border-radius: 50rem;
                            background-color: #10002F;
                            position: absolute;
                            left: auto;
                            right: 0;
                            transform: translate(50%, 45%);
                            top: 0;
                            aspect-ratio: 1 / 1;
                        }
                        
                        &:last-of-type::after {
                            left: auto;
                            right: 0;
                            transform: translate(50%, 45%);
                        }
                    }
                }
            }
        }
    }
}