.offcanvas {
    --bs-offcanvas-bg: #{$primary-white};
    --bs-offcanvas-width: 100vw;
    --bs-offcanvas-height: 100vh;

    height: 100svh;
    transition: opacity 0.1s ease-in-out;

    &-header {
        justify-content: space-between;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        background-color: $primary-white;
        padding: 8px 24px;

        .btn-close {
            margin-right: -2px;
        }
    }

    @include media-breakpoint-down(md) {
        opacity: 0;

        &.show {
            opacity: 1;
        }

        &-body {
            background-color: $gray-1;
        }
    }

    @include media-breakpoint-down(md) {
        &-header {
            padding-inline: 1rem;
        }
    }
}

.offcanvas-backdrop {
     display: none;
}