.media-image-slider {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include headline(lg);

    .swiper {
        overflow: visible;
        cursor: grab;

        &::before {
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            transform: translateX(calc(-100% - 6px));
        }
    }

    .swiper-slide {
        width: auto;

        img {
            height: 100%;
            object-fit: cover;
            max-height: 400px;
        }

    }
}

.section--dark {
    .media-image-slider .swiper::before {
        background-color: $primary-black;
        box-shadow: 1px 0px 1px 5px $primary-black;
    }
}

.section--light {
    .media-image-slider .swiper::before {
        background-color: $primary-white;
        box-shadow: 1px 0px 1px 5px $primary-white;
    }
}