@mixin generate-link-styles($style, $weight) {
    $styleMap: map-get($link-styles, $style);
    $properties: map-get($styleMap, $weight);
    $unitlessLineHeight: strip-unit(map-get($properties, line-height)); 
    $unitlessSize: strip-unit(map-get($properties, size)); 

    font-family: map-get($properties, font);
    @include rfs(map-get($properties, size));
    font-weight: map-get($properties, weight);
    line-height: calc($unitlessLineHeight / $unitlessSize);
    letter-spacing: map-get($properties, letter-spacing);
}