@font-face {
    font-display: swap;
    font-family: 'BelyDisplay';
    font-style: normal;
    font-weight: 400;
    src: url('../../../../public/fonts/BelyDisplay-Regular.otf')
        format('opentype'); 
}

@font-face {
    font-display: swap;
    font-family: 'Star';
    font-style: normal;
    font-weight: 300;
    src: url('../../../../public/fonts/KrawallKrone-Star.otf')
        format('opentype'); 
}

@font-face {
    font-display: swap;
    font-family: 'Gilory';
    font-style: normal;
    font-weight: 700;
    src: url('../../../../public/fonts/Gilroy-Bold.otf')
        format('opentype'); 
}

@font-face {
    font-display: swap;
    font-family: 'Gilory';
    font-style: normal;
    font-weight: 800;
    src: url('../../../../public/fonts/Gilroy-ExtraBold.otf')
        format('opentype'); 
}

@font-face {
    font-display: swap;
    font-family: 'Gilory';
    font-style: normal;
    font-weight: 900;
    src: url('../../../../public/fonts/Gilroy-HeavyItalic.otf')
        format('opentype'); 
}

@font-face {
    font-display: swap;
    font-family: 'Gilory';
    font-style: normal;
    font-weight: 500;
    src: url('../../../../public/fonts/Gilroy-Medium.otf')
        format('opentype'); 
}

@font-face {
    // font-display: swap;
    font-family: 'Gilory';
    font-style: normal;
    font-weight: 400;
    src: url('../../../../public/fonts/Gilroy-Regular.otf')
        format('opentype'); 
}

@font-face {
    font-display: swap;
    font-family: 'Gilory';
    font-style: normal;
    font-weight: 600;
    src: url('../../../../public/fonts/Gilroy-SemiBold.otf')
        format('opentype'); 
}