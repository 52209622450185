.service-intro-video {
    .video {
        display: flex;
        position: relative;

        video {
            width: 100%;
        }

        &.is--playing {
            .video__overlay {
                background-color: rgba(black, 0);
            }

            .video__overlay .video__overlay__button-play {
                top: auto;
                left: auto;
                right: 0;
                bottom: 0;
                transform: translate(0px, 0px) scale(0.2);
                border-style: double;
                border-width: 0px 0 0px 60px;
                opacity: 0;
            }

            &:hover {
                .video__overlay .video__overlay__button-play {
                    opacity: 1;
                }
            }
        }                

        &:hover {
            .video__overlay__button-sound {
                opacity: 1;
            }
        }

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(black, 0.3);
            cursor: pointer;
            z-index: 1;

            &__button-play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                box-sizing: border-box;
                width: 0;
                height: 74px;
                border-style: solid;
                border-width: 37px 0 37px 60px;
                border-color: transparent transparent transparent $gray-2;
                cursor: pointer;
                will-change: border-width;
                transition: all .5s ease;
                z-index: 2;
            }

            &__button-sound {
                position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;
                padding: 1rem;
                z-index: 2;                
                color: $primary-white;
                opacity: 0;
                transition: all .5s ease;
            }

            &:hover {
                &__button-play {
                    border-color: transparent transparent transparent $gray-3;
                }
            }
        }
    }
}