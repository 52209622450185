.project-counter {
    border-top: solid 1px $primary-rose;
    border-bottom: solid 1px $primary-rose;

    .headline {
        @include headline(lg);
        @include rfs(3rem, padding-block);
        text-align: center;

        &__content {
            margin-bottom: 0;
        }
    }

    &__list {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        @include rfs(1.5rem, gap);
        @include rfs(3rem, padding-bottom);

        &-item {
            @include rfs(1.5rem, padding);
            min-width: 10.9375rem;
            max-width: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .number {
                color: $primary-purple;
                font-family: "BelyDisplay";
                @include font-size(5rem);
                font-weight: 400;
                line-height: 1;
                text-transform: uppercase;
            }
            
            .text {
                text-align: center;
                @include generate-body-styles('large', 'regular');
                color: $primary-black;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__list {
            align-items: center;
            flex-direction: column;
        }
    }
}