// TODO: add correct styling
.content-faq {
    &__header {
        text-align: center;
        margin-bottom: map-get($map: $spacers, $key: 4);
        
        @include headline(md);
        
        .headline {
            &__title {
                color: $primary-purple;
            }

            // &__content {
            // }
        }
    }
}