.project-credits {
    @include headline(lg);

    .headline {
        text-align: center;

        &__content {
            margin-bottom: 32px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 6px;

        &-item {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;

            &--job {
                color: $primary-rose;
                @include generate-body-styles('large', 'regular');
                justify-self: flex-end;
            }

            &--name {
                justify-self: flex-start;
                @include generate-body-styles('large', 'bold');
            }
        }
    }
}