.hero--service {
    color: $primary-white;

    @each $value, $color in $theme-colors {
        &.hero--#{$value}{
            .hero--service__graphic {
                background-color: $color;
            }

            .headline .headline__accent,
            .headline .headline__title {
                color: $color;
            }
        }
    }

    .container_inner {
        position: relative;
        @include make-container();
        max-width: 1290px;
    }

    .hero--service__graphic {
        height: 100%;
        transform: rotate(90deg) translateY(10%);
        z-index: -1;
        mask-size: contain;
        mask-repeat: no-repeat;
        aspect-ratio: 1 / 1;
        position: absolute;
        top: 0;
        left: 0;
    }


    &__inner {
        padding-top: 80px;
        padding-bottom: 168px;
        display: grid;
        gap: 3rem;
        grid-template-columns: auto minmax(400px, 1fr);
        grid-template-rows: 1fr;

        &__image {
            grid-column: 1;
            grid-row: 1;
            position: relative;

            &::before, &::after {
                content: '';
                clip-path: polygon(0 0, 0% 100%, 100% 100%);
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                aspect-ratio: 1 / 1;
            }

            &::before {
                height: 70%;
                backdrop-filter: blur(10px);
            }

            &::after {
                height: 35%;
                backdrop-filter: blur(15px);
            }


            img {
                width: clamp(500px, 100% ,742px);
            }
        }

        &__content {
            grid-column: 2;
            grid-row: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include headline('xl');

            .button__wrapper {
                margin-top: map-get($map: $spacers, $key: 3);
                display: flex;
                flex-wrap: wrap;
                gap: 0.5rem;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        .hero--service__graphic {
            transform: rotate(90deg) translateY(0px);
        }
    }

    @include media-breakpoint-down(md) {
        .hero--service__graphic {
            height: 60%;
        }

        &__inner {
            grid-template-columns: 1fr;
            
            &__content {
                grid-row: 2;
                grid-column: 1;
            }

            &__image {
                grid-column: 1;
                grid-row: 1;

                img {
                    width: 100%;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .hero--service__graphic {
                height: 60%;
                top: auto;
                bottom: 50px;
                transform: rotate(0deg);
            }

            &__inner {
                padding-bottom: 106px;

                &__content {
                    grid-row: 1;
                }

                &__image {
                    grid-row: 2;
                }
            }
        }
}