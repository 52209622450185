.project-social-media-stories {
    .text-block {
        text-align: center;
        @include headline(md);
        @include rfs(2.5rem, padding-bottom);
        max-width: 60ch;
        margin-inline: auto;

        p {
            @include generate-body-styles('large', 'regular');
        }

        .headline {
            &__title {
                @include generate-display-styles('xxsmall', 'medium');
            }

            &__content {
                @include generate-display-styles('large', 'extrabold');
            }
        }
    }

    &__medias {
        display: flex;
        justify-content: center;
        align-items: center;
        @include rfs(3rem, gap);

        &-item {
            position: relative;

            &:nth-child(2) {
                .phone-mask {
                    @include rfs(46.125rem, height);
                }
            }

            .phone-mask {
                position: relative;
                z-index: 1;
                min-height: 550px;
                @include rfs(41rem, height);
            }

            .image, .video {
                position: absolute;
                overflow: hidden;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 1rem;

                img, video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .scroll-container {
        overflow-y: auto;
        overflow-y: hidden;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    @include media-breakpoint-down(md) {
        &__medias {
            justify-content: flex-start;
        }
    }
}