.content-usp {
    background-color: $primary-purple;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;

     &__right-bg {
        grid-column: 2;
        grid-row: 1;
        background-image: url($background-why);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .container__inner {
        @include make-container();
        max-width: $container-inner-max-width;
        padding-inline: 2.5rem;
        grid-column: 1 / -1;
        grid-row: 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        @include rfs(7rem, gap);
    }

    &__why {
        display: flex;
        align-items: center;

        .text-block {
            @include rfs(#{$section-spacing-md}, margin-block);
            @include rfs(#{map-get($map: $spacers, $key: 5)}, padding-block);
            border-top: 1px solid $primary-red;
            border-bottom: 1px solid $primary-red;
            color: $primary-white;

            @include headline('lg', 'medium');
            @include generate-body-styles('large', 'regular');
        }
    }

    &__therefore {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
    }

    &__card {
        color: $primary-purple;
        background-color: $primary-white;
        padding: 1.5rem;
        justify-self: flex-end;
        min-width: 24.5rem;
        position: relative;

        @include headline('lg', '$primary-purple');

        &::after {
            @include add-icon(
                $icon: $icon-star-half,
                $pseudo: true,
                $color: $primary-white, 
                $x: -100%,
                $y: -50%,
                $position: absolute,
                $left: 0,
                $top: 50%,
                $height: 32px,
                $widht: 32px,
            );    
        }

        &__icons {
            list-style: none;
            display: flex;
            margin-bottom: 0;
            justify-content: center;
            padding-left: 0;

            &__item {
                padding-right: 0.5rem;

                &:last-child {
                    padding-right: 0;
                }

                &__link {
                    background-color: $primary-pink;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.25rem;
                    height: 40px;
                    width: 40px;

                    &__icon {
                        filter: brightness(0) invert(1);
                    }
                }
            }
        }   
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0;

        .container__inner {
            gap: 0;
            grid-row: 1 / -1;
            padding-inline: 1rem;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
        }

        &__right-bg {
            grid-row: 2;
            grid-column: 1;
        }

        &__why {
            grid-row: 1;

            .text-block {
                @include rfs(2rem, margin-block);
            }
        }

        &__therefore {
            grid-row: 2;
        }

        &__card {
            min-width: auto;        
        }
    }

    @include media-breakpoint-down(md) {
        &__card {
            max-width: 420px;        
        }
    }
}