.headline {
    // &__content {
    // }
    
    @each $color, $value in $theme-colors {   
        &--#{$color} {            
            .headline__title,
            .headline__accent {
                color: $value;
            }
        }
    }
}