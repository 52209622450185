@mixin icon-divider(
    $color: $primary-red,
    $variant: 'top_bottom',
    $icon: $icon-brand-star,
) {
    @if $variant == 'top_bottom' {
        border-top: solid 1px $color;
        border-bottom: solid 1px $color;
        position: relative;

        &::before,
        &::after {
            background-color: $color;
        }

        &::before {
            @include add-icon(
                $icon: $icon,
                $pseudo: true,
                $color: $color,
                $height: 16px,
                $widht: 16px,
                $x: -50%,
                $y: -50%,
                $position: absolute,
                $top: 0,
                $left: 50%
            );
        }
    }


    @else if $variant == 'top'{
        border-top: solid 1px $color;
        position: relative;

        &::after {
            @include add-icon(
                $icon: $icon,
                $pseudo: true,
                $position: absolute,
                $top: 0,
                $right: 50%,
                $color: $color,
                $height: 16px,
                $widht: 16px,
                $x: 50%,
                $y: -50%,
            );
        }

    }

    @else if $variant == 'bottom'{
        border-bottom: solid 1px $color;
        position: relative;

        &::before {
            @include add-icon(
                $icon: $icon,
                $pseudo: true,
                $position: absolute,
                $bottom: 0,
                $right: 50%,
                $color: $color,
                $height: 16px,
                $widht: 16px,
                $x: 50%,
                $y: 50%,
            );
        }

    }

    @else if $variant == 'middle'{
        border-right: solid 1px $color;

        &::after {
            @include add-icon(
                $icon: $icon,
                $pseudo: true,
                $position: absolute,
                $top: 50%,
                $right: 0,
                $color: $color,
                $height: 16px,
                $widht: 16px,
                $x: 50%,
                $y: -50%
            );
        }
    }
};