@mixin add-section-spacing(
    $position: 'y', 
    $size: 'md'
) {
    $section_map: map-get($sections-map, $size);
    @if $position == 'top' {
        padding-top: map-get($section_map, padding);
    }

    @if $position == 'bottom' {
        padding-bottom: map-get($section_map, padding);
    }

    @if $position == 'left' {
        padding-left: map-get($section_map, padding);
    }

    @if $position == 'right' {
        padding-right: map-get($section_map, padding);
    }

    @if $position == 'x' {
        padding-inline: map-get($section_map, padding);
    }

    @if $position == 'y' {
        padding-block: map-get($section_map, padding);
    }
}