.teaser--text-image {
    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 7.625rem; // magic number
    }

    &__content {
        color: $primary_white;
        padding-top: 2.25rem; // magic number
        padding-bottom: 2.25rem; // magic number
        position: relative;
        align-self: center;
        @include icon-divider($primary-pink, top_bottom);

        &__header {
            @include headline(lg, $primary_white);
        }

        &__body {
            margin: 1.5rem 0;
        }
    }

    @include media-breakpoint-down(lg) {
        &__container {
            column-gap: 1.5rem;
        }
    }

    @include media-breakpoint-down(md) {
        &__container {
            grid-template-columns: 1fr;
            row-gap: 2.5rem;
        }
    }

}