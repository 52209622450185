.hero-agentur {
    &__headline-container {
        @include headline($size: xl, $color: $white,);
        @include rfs(4rem, padding-bottom);

        .headline {
            text-align: center;
        }
    }

    &__grid-gallery {
        position: relative;
        @include rfs(4rem, padding-top);
        @include rfs(6.5rem, padding-bottom);
        display: grid;
        grid-template-columns: repeat(12, 8.333333333333333%);
        grid-template-rows: repeat(12, clamp(30px, 5vw, 54px));

        .image__main img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .image__main {
            grid-column: 1 / -1;
            grid-row: 1 / -1;
        }

        .image-child {
            &.image img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }

            &.image--1 {
                grid-column: 1 / span 4;
                grid-row: 2;

                img {
                    transform: translateX(-2.3rem);
                }
            }

            &.image--2 {
                grid-column: 8 / span 4;
                grid-row: 1;

                img {
                    transform: translateY(-3.2rem);
                }
            }

            &.image--3 {
                grid-column: 2 / span 4;
                grid-row: 8;

                img {
                    transform: translateY(2.9rem);
                }
            }

            &.image--4 {
                grid-column: 10 / span 3;
                grid-row: 7;

                img {
                    transform: translateX(2rem);
                }
            }
        }
    }

    &__intro {
        @include icon-divider($primary-blue, top_bottom, $icon-brand-crown);
        @include rfs(2.25rem, padding-block);

        .text-block {
            @include headline(lg);
            text-align: center;
        }
    }

    @include media-breakpoint-down(sm) {
        &__headline-container {
            padding-bottom: 0;

            .headline {
                text-align: left;
            }
        }

        &__grid-gallery {
            grid-template-columns: 1fr;
            grid-template-rows: min-content;
            padding-top: 0;
            gap: 1rem;

            .image--1, .image--2 {
                display: none;
            }

            .image-child.image--3 {
                grid-column: 1;
                grid-row: 2;

                img {
                    transform: translateY(0);
                }
            }

            .image-child.image--4 {
                grid-column: 1;
                grid-row: 3;

                img {
                    transform: translateX(0);
                }
            }
        }

        &__intro {
            .text-block {
                text-align: left;
            }
        }
    }
}