.lp-contact {
    &__intro {
        @include rfs(60px, padding-bottom);

        .headline__content {
            @include generate-display-styles('large', 'extrabold'); 
        }

        .text-block {
            text-align: center;
            @include generate-body-styles('medium', 'regular'); 
        }
    }

    &__form {
        border-top: solid 2px $primary-red;
        background-color: $primary-white;
        position: relative;
        @include rfs(48px, padding);
        @include icon-divider($primary-red, top);

        &__header {
            display: flex;
            align-items: center;
            border-bottom: solid 1px $gray-200;
            @include rfs(3rem, gap);
            @include rfs(2rem, padding-bottom);
            @include rfs(2rem, margin-bottom);

            .lp-contact__person {
                display: flex;
                align-items: center;
                @include rfs(24px, gap);

                img {
                    height: 100px;
                    width: 100px;
                    object-fit: cover;
                }

                p {
                    @include generate-display-styles('medium', 'extrabold');
                    color: $primary-black;
                }
            }

            .lp-contact__contact-data {
                &--phone a, &--email a {
                    @include generate-label-styles('medium', 'bold');
                    color: $primary-dark-purple;
                }

                &--phone a, &--email {
                    white-space: nowrap;
                }


                &--phone {
                    &::before {
                        margin-right: 6px;

                        @include add-icon(
                            $icon: $icon-call,
                            $color: $primary-red,
                            $top: 6px,
                            $widht: 24px,
                            $height: 24px,
                        );
                    }
                }

                    &--email {
                        &::before {
                            margin-right: 6px;

                        @include add-icon(
                            $icon: $icon-email,
                            $color: $primary-red,
                            $top: 6px,
                            $widht: 24px,
                            $height: 24px,
                        );
                    }
                }
            }
        }

        &__body {
            form {
                .form-label {
                    @include generate-label-styles('medium', 'bold');
                    color: $primary-black;
                }

                .form-control {
                    background-color: $gray-1;
                }

                label[for=data-privacy] {
                    @include generate-body-styles('medium', 'regular');
                    color:#272727
                }

                .submit {
                    button:hover {
                        border-color: $primary-black;
                        color: $primary-black;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__form {
            &__header {
                align-items: flex-start;
                flex-direction: column;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__form {
            margin-inline: -1rem;
        }
    }
}