.section {
    //* Layout
    &--default {
        .section__inner {
            @include make-container();
            max-width: $container-inner-max-width;
            padding-inline: 2.5rem;

            @include media-breakpoint-down(md) {
                padding-inline: 1rem;
            }
        }
    }

    &--fullwidht {
        .section__inner {
            width: 100%;
        }
    }

    //* Background Color
    &--dark {
        background-color: $primary-black;
        color: $primary-white;
    }

    &--light {
        background-color: $primary-white;
        color: $primary-dark-purple;
    }

    &--gray {
        background-color: $gray-1;
        color: $primary-black;
    }

    &--purple {
        background-color: $primary-purple;
        color: $primary-white;
    }

    //* Spacing
    &--spacing-y {
        // @include add-section-spacing('y', 'md');
        @include rfs(100px, padding-block);
    }

    &--spacing-t {
        // @include add-section-spacing('top', 'md');
        @include rfs(100px, padding-top);
    }

    &--spacing-b {
        // @include add-section-spacing('bottom', 'md');
        @include rfs(100px, padding-bottom);
    }

    &--spacing-none {
        padding-block: 0;
    }
}