@mixin generate-accent-styles($style) {
    $styleMap: map-get($accent-styles, $style);
    $unitlessLineHeight: strip-unit(map-get($styleMap, line-height)); 
    $unitlessSize: strip-unit(map-get($styleMap, size)); 

    font-family: map-get($styleMap, font);
    @include rfs(map-get($styleMap, size));
    font-weight: map-get($styleMap, weight);
    line-height: calc($unitlessLineHeight / $unitlessSize);
    letter-spacing: map-get($styleMap, letter-spacing);
}