.content-method {
    position: relative;
    display: grid;
    @include rfs(5rem, gap);
    grid-template-columns: clamp(300px, 490px, 50%) 3fr;
    border-top: solid 1px $gray-200;
    border-bottom: solid 1px $gray-200;
    @include rfs(50px, padding-block);

    &__headline {
        position: relative;
        @include headline('lg');
    }

    &__content p {
        @include generate-body-styles('medium', 'regular')
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        grid-template-rows: min-content;
        @include rfs(1rem, gap);

        &__headline {
            grid-row: 1;
        }

        &__content {
            grid-row: 2;
        }

    }
}
